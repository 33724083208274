import React from "react";

const RecipientCard = ({ recipient }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="w-full overflow-hidden shadow-lg rounded-xl bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
      <div className="p-6 sm:p-8">
        <div className="flex flex-wrap items-center justify-between mb-6">
          <div className="w-full mb-4 sm:w-auto sm:mb-0">
            <div className="mb-1 text-sm font-semibold tracking-wide text-indigo-600 uppercase">
              {recipient.recipient_id}
            </div>
            <h2 className="text-3xl font-bold text-gray-900">
              {recipient.name}
            </h2>
          </div>
          <div className="w-full space-x-2 sm:w-auto">
            <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-indigo-800 bg-indigo-100 rounded-full">
              {recipient.gender_label}
            </span>
            <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-red-800 bg-red-100 rounded-full">
              {recipient.bloodgroup}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3">
          <InfoItem
            icon={<CalendarIcon />}
            label="Date of Birth"
            value={formatDate(recipient?.dob)}
          />
          <InfoItem
            icon={<ClockIcon />}
            label="Treating Doctor"
            value={recipient?.treating_doctor}
          />
          <InfoItem
            icon={<PhoneIcon />}
            label="Mobile"
            value={recipient?.primary_mobile}
          />
          <InfoItem
            icon={<ChatIcon />}
            label="WhatsApp"
            value={recipient?.whatsapp_number}
          />
          <InfoItem
            icon={<HomeIcon />}
            label="Address"
            value={recipient?.address}
          />
          <InfoItem
            icon={<LocationIcon />}
            label="Location"
            value={
              [
                recipient?.city?.label,
                recipient?.state?.label,
                recipient?.country?.label,
              ]
                .filter(Boolean)
                .join(", ") || "Not specified"
            }
          />
        </div>

        {recipient.comments && (
          <div className="p-4 mt-6 bg-white rounded-lg shadow-inner bg-opacity-60">
            <h3 className="flex items-center mb-2 text-lg font-semibold text-gray-900">
              <CommentIcon className="w-5 h-5 mr-2" />
              Comments
            </h3>
            <p className="italic text-gray-700">{recipient.comments}</p>
          </div>
        )}

        <div className="flex items-center justify-between pt-6 mt-6 text-sm text-gray-500 border-t border-gray-200">
          <p className="flex items-center">
            <ClockIcon className="w-4 h-4 mr-1" />
            Created: {formatDate(recipient.createdAt)}
          </p>
          <p className="flex items-center">
            <ClockIcon className="w-4 h-4 mr-1" />
            Last Updated: {formatDate(recipient.updatedAt)}
          </p>
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-start p-3 bg-white rounded-lg shadow-sm bg-opacity-60">
    <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-3 bg-indigo-100 rounded-full">
      {icon}
    </div>
    <div>
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="mt-1 text-sm text-gray-900">{value}</p>
    </div>
  </div>
);

// Icon components remain the same as in the previous version
const CalendarIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

const ClockIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const PhoneIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
    />
  </svg>
);

const ChatIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
    />
  </svg>
);

const HomeIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
    />
  </svg>
);

const LocationIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

const CommentIcon = () => (
  <svg
    className="w-5 h-5 text-indigo-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
    />
  </svg>
);

export default RecipientCard;
