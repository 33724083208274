import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Select from "react-select";

const Sensitisation = ({
  handleChange,
  formData,
  handleDateChange,
  postiveNegativeOptions,
  btReasonOptions,
  transfusionTypeOptions,
  pregnancyOutcomeOptions,
  pregnancyStatusOptions,
  transplantOrganOptions,
  transplantReasonOptions,
  transplantOutcomeOptions,
  dropDownChange,
  placeHolderText,
  style,
  isEditable,
}) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Blood Transfusion</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="bloodTransfusion_date"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Transfusion Date
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="bloodTransfusion_date"
              onChange={handleDateChange}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.bloodTransfusion_date &&
                dayjs(formData?.bloodTransfusion_date, "YYYY-MM-DD")
              }
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="bloodTransfusion_reason"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Reason
            </label>
            <Select
              name="bloodTransfusion_reason"
              onChange={dropDownChange}
              options={btReasonOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.bloodTransfusion_reason &&
                btReasonOptions?.find(
                  (option) => option.value === formData?.bloodTransfusion_reason
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="bloodTransfusion_type"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Transfusion Type
            </label>
            <Select
              name="bloodTransfusion_type"
              onChange={dropDownChange}
              options={transfusionTypeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.bloodTransfusion_type &&
                transfusionTypeOptions?.find(
                  (option) => option.value === formData?.bloodTransfusion_type
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="mb-6">
          <label
            htmlFor="bloodTransfusion_notes"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Clinical Notes
          </label>
          <textarea
            id="bloodTransfusion_notes"
            name="bloodTransfusion_notes"
            rows="4"
            className="w-full p-2 border rounded-md"
            onChange={handleChange}
            value={formData?.bloodTransfusion_notes || ""}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">
          Pregnancy Details (if Any)
        </h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="pregnancyHistory_due_date"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Due Date
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="pregnancyHistory_due_date"
              onChange={handleDateChange}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.pregnancyHistory_due_date &&
                dayjs(formData?.pregnancyHistory_due_date, "YYYY-MM-DD")
              }
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="pregnancyHistory_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <Select
              name="pregnancyHistory_status"
              onChange={dropDownChange}
              options={pregnancyStatusOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.pregnancyHistory_status &&
                pregnancyStatusOptions?.find(
                  (option) => option.value === formData?.pregnancyHistory_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="pregnancyHistory_outcome"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Outcome
            </label>
            <Select
              name="pregnancyHistory_outcome"
              onChange={dropDownChange}
              options={pregnancyOutcomeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.pregnancyHistory_outcome &&
                pregnancyOutcomeOptions?.find(
                  (option) =>
                    option.value === formData?.pregnancyHistory_outcome
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="mb-6">
          <label
            htmlFor="pregnancyHistory_notes"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Clinical Notes
          </label>
          <textarea
            id="pregnancyHistory_notes"
            name="pregnancyHistory_notes"
            rows="4"
            className="w-full p-2 border rounded-md"
            onChange={handleChange}
            value={formData?.pregnancyHistory_notes || ""}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Transplant History</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div>
            <label
              htmlFor="transplantHistory_date"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Transplant Date
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="transplantHistory_date"
              onChange={handleDateChange}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.transplantHistory_date &&
                dayjs(formData?.transplantHistory_date, "YYYY-MM-DD")
              }
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="pregnancyHistory_outcome"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Organ
            </label>
            <Select
              name="transplantHistory_organ"
              onChange={dropDownChange}
              options={transplantOrganOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.transplantHistory_organ &&
                transplantOrganOptions?.find(
                  (option) => option.value === formData?.transplantHistory_organ
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="transplantHistory_reason"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Transplant Reason
            </label>
            <Select
              name="transplantHistory_reason"
              onChange={dropDownChange}
              options={transplantReasonOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.transplantHistory_reason &&
                transplantReasonOptions?.find(
                  (option) =>
                    option.value === formData?.transplantHistory_reason
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="transplantHistory_outcome"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Transplant Outcome
            </label>
            <Select
              name="transplantHistory_outcome"
              onChange={dropDownChange}
              options={transplantOutcomeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.transplantHistory_outcome &&
                transplantOutcomeOptions?.find(
                  (option) =>
                    option.value === formData?.transplantHistory_outcome
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="mb-6">
          <label
            htmlFor="transplantHistory_notes"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Clinical Notes
          </label>
          <textarea
            id="transplantHistory_notes"
            name="transplantHistory_notes"
            rows="4"
            className="w-full p-2 border rounded-md"
            onChange={handleChange}
            value={formData?.transplantHistory_notes || ""}
            disabled={!isEditable}
          />
        </div>
      </div>
    </div>
  );
};

export default Sensitisation;
