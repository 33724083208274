import { cregMatchList } from "../../../components/Data/dropdownoptions";

const HLAAntibodyMFI_threshold = 1000;
const MAX_HLAAntibodyMFI_threshold = 10000;

function transformHLAData(inputArray) {
  return inputArray?.map((item) => ({
    allele: item.antigen,
    mfi: Math.round(item.MFI),
  }));
}

function transformHLAAlleles(inputObject) {
  if (!inputObject) {
    return [];
  }

  const alleles = [];

  for (const [key, value] of Object.entries(inputObject)) {
    if (key.includes("Allele") && value !== "") {
      alleles.push({ allele: value });
    }
  }

  // Sort alleles alphabetically
  alleles.sort((a, b) => a.allele.localeCompare(b.allele));

  return alleles;
}

export function performCREGAnalysis(recipientHlaAntibody, donorHlaTyping) {
  const results = [];
  let totalScore = 0;
  let totalPossibleScore = 0;

  // Filter antibodies to only include those above the MFI threshold
  const highMFIAntibodies = recipientHlaAntibody.filter(
    (ab) => ab.mfi > HLAAntibodyMFI_threshold
  );

  // Analyze each high MFI antibody
  highMFIAntibodies.forEach((antibody) => {
    // Find the matching CREG group for this antibody
    const cregMatch = cregMatchList.find((creg) =>
      antibody.allele.startsWith(creg.allele)
    );

    if (cregMatch) {
      // If a CREG match is found, look for matching donor alleles
      const matchingDonorAlleles = donorHlaTyping
        .filter((donorAllele) =>
          // Check if any CREG allele in this group matches the donor allele
          cregMatch.creg.some((cregAllele) =>
            // Compare only the part before the first colon (e.g., "B*07" from "B*07:02")
            donorAllele.allele.startsWith(cregAllele.split(":")[0])
          )
        )
        ?.map((allele) => allele.allele); // Extract just the allele string

      const isHighRisk = antibody.mfi > MAX_HLAAntibodyMFI_threshold;
      let score = isHighRisk
        ? 0
        : (matchingDonorAlleles.length / cregMatch.creg.length) * 100;

      totalScore += score;
      totalPossibleScore += 100;

      results.push({
        antibody: antibody.allele,
        mfi: antibody.mfi,
        cregGroup: cregMatch.creg.join(", "),
        matchingDonorAlleles: matchingDonorAlleles,
        score: score,
        isHighRisk: isHighRisk,
      });
    }
  });

  // Calculate the CREG score
  let cregScore;
  if (totalPossibleScore === 0) {
    // No matches found, set CREG score to 0
    cregScore = 0;
  } else {
    // Matches found, calculate the score
    cregScore = (totalScore / totalPossibleScore) * 100;
  }

  // Calculate the overall match score (inverse of CREG score)
  const overallMatchScore = 100 - cregScore;

  return {
    details: results,
    overallMatchScore: overallMatchScore.toFixed(2),
    cregScore: cregScore.toFixed(2),
    hasHighRiskAntibodies: results.some((result) => result.isHighRisk),
  };
}

export function analyzeCREG(recipientClinical, donorClinical) {
  const recipientHlaAntibody = transformHLAData(recipientClinical?.hlaAntibody);
  const donorHlaTyping = transformHLAAlleles(donorClinical?.hlaTyping);

  if (!recipientHlaAntibody || !donorHlaTyping) {
    return null;
  }

  return performCREGAnalysis(recipientHlaAntibody, donorHlaTyping);
}
