import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Modal from "./Modal";

const ExcelImport = ({ onDataImported, isEditable }) => {
  const [error, setError] = useState(null);
  const [importedData, setImportedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (importedData) {
      const initialSelectedState = importedData.reduce((acc, _, index) => {
        acc[index] = selectAll;
        return acc;
      }, {});
      setSelectedRows(initialSelectedState);
    }
  }, [importedData, selectAll]);

  const categorizeAntigen = (antigen) => {
    if (antigen.startsWith("A")) return { class: "Class I", hlatype: "HLA-A" };
    if (antigen.startsWith("B")) return { class: "Class I", hlatype: "HLA-B" };
    if (antigen.startsWith("C")) return { class: "Class I", hlatype: "HLA-C" };
    if (antigen.startsWith("DR"))
      return { class: "Class II", hlatype: "HLA-DR" };
    if (antigen.startsWith("DQ"))
      return { class: "Class II", hlatype: "HLA-DQ" };
    if (antigen.startsWith("DP"))
      return { class: "Class II", hlatype: "HLA-DP" };
    return { class: "", hlatype: "" };
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headerRowIndex = rawData.findIndex(
          (row) =>
            row[0]?.toString().toLowerCase() === "antigen" &&
            row[1]?.toString().toLowerCase() === "mfi"
        );

        if (headerRowIndex === -1) {
          setError(
            'Header row not found. Please ensure your Excel file has "Antigen" and "MFI" headers.'
          );
          return;
        }

        const data = rawData
          .slice(headerRowIndex + 1)
          .map((row, index) => {
            const antigen = row[0]?.toString().trim() || "";
            const mfi = parseFloat(row[1]);
            const { class: hlaClass, hlatype } = categorizeAntigen(antigen);
            return {
              class: hlaClass,
              hlatype: hlatype,
              antigen: antigen,
              MFI: isNaN(mfi) ? 0 : mfi,
              status: (isNaN(mfi) ? 0 : mfi) > 1000 ? "Positive" : "Negative",
              rowIndex: headerRowIndex + 1 + index,
            };
          })
          .filter((row) => row.antigen !== "" && !isNaN(row.MFI));

        console.log("Processed data:", data);

        if (data.length === 0) {
          setError(
            "No valid data found in the file. Please check your Excel file and try again."
          );
          return;
        }

        setImportedData(data);
        setIsModalOpen(true);
        setError(null);
      } catch (err) {
        setError("Error reading file. Please try again.");
        console.error(err);
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const handleAccept = () => {
    const acceptedData = importedData.filter((_, index) => selectedRows[index]);
    onDataImported(acceptedData);
    setIsModalOpen(false);
  };

  return (
    <div>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        disabled={!isEditable}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="mb-4 text-lg font-semibold">Imported Data</h2>
        <div className="mb-2">
          <button
            className="px-4 py-2 text-gray-800 bg-gray-200 rounded hover:bg-gray-300"
            onClick={handleSelectAll}
          >
            {selectAll ? "Deselect All" : "Select All"}
          </button>
        </div>
        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  Accept
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  Class
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  HLA Type
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  Antigen
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  MFI
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {importedData &&
                importedData.map((row, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedRows[index] || false}
                        onChange={() => handleRowSelection(index)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.class}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.hlatype}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.antigen}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.MFI}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4 space-x-2">
          <button
            className="px-4 py-2 text-gray-800 bg-gray-300 rounded hover:bg-gray-400"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            onClick={handleAccept}
          >
            Accept Selected
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ExcelImport;
