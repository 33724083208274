import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import Select from "react-select";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import { memberTitleOptions } from "../../components/Data/dropdownoptions";

const MyProfileUpdateChild = () => {
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  //   const [userProfile, setUserProfile] = useState();
  const [formData, setFormData] = useState();

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/user/pro/${user?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data.user; // Access consultants array from the response data
        console.log(oldData);

        setFormData(oldData); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);
  const [changePassword, setChangePassword] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      member_title: selectedOption.value,
    });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    if (changePassword) {
      if (!formData.password) {
        return toast.error("Kindly go through the details ");
      }

      if (formData.password !== formData.current_password) {
        return toast.error("Passwords do not match");
      }
    }

    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Profile Updated Successfully.");
        getDataFromServer();
        //setShowUpdateModal(false);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "UpdateConsultant", "/consultant/update");
      });
  };

  return (
    <div className="w-full">
      <h2 className="mb-4 text-2xl font-semibold">Update Consultant</h2>
      <form onSubmit={handleUpdateSubmit} className="space-y-4">
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="md:w-1/4 form-group">
            <label className="block text-gray-700">Title</label>
            <Select
              options={memberTitleOptions}
              onChange={handleSelectChange}
              placeholder="Title"
              className="block w-full mt-1"
              required
              menuPosition={"fixed"}
              value={
                formData?.member_title &&
                memberTitleOptions.find(
                  (m) => m.value === formData?.member_title
                )
              }
            />
          </div>
          <div className="md:w-2/4 form-group">
            <label className="block text-gray-700">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData?.first_name}
              onChange={handleChange}
              placeholder={placeHolderText.first_name || "Enter first name"}
              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="md:w-1/3 form-group">
            <label className="block text-gray-700">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData?.last_name}
              onChange={handleChange}
              placeholder={placeHolderText.last_name || "Enter last name"}
              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="form-group">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              autoComplete="new-password"
              placeholder={placeHolderText.email || "Enter email"}
              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              disabled
            />
          </div>
          <div className="form-group">
            <label className="block text-gray-700">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData?.mobile}
              onChange={handleChange}
              placeholder={placeHolderText.mobile || "Enter mobile number"}
              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
          <div className="form-group">
            <label className="block text-gray-700">
              <input
                type="checkbox"
                checked={changePassword}
                onChange={() => setChangePassword(!changePassword)}
                className="mr-2"
              />
              Change Password
            </label>
          </div>
        </div>
        {changePassword && (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="form-group">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                autoComplete="new-password"
                onChange={handleChange}
                placeholder={placeHolderText.password || "Enter password"}
                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="form-group">
              <label className="block text-gray-700">Current Password</label>
              <input
                type="password"
                name="current_password"
                autoComplete="new-password"
                value={formData.current_password}
                onChange={handleChange}
                placeholder={
                  placeHolderText.current_password || "Enter current password"
                }
                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
          <div className="form-group">
            <label className="block text-gray-700">Organization</label>
            <input
              type="text"
              name="organization"
              value={formData?.organization}
              onChange={handleChange}
              placeholder={placeHolderText.organization || "Enter organization"}
              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4 form-group">
          <button
            type="submit"
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm btn btn-primary hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default MyProfileUpdateChild;
