import React from "react";

const ViewModal = ({ currentRecord }) => {
  const jsonData = currentRecord;
  const displayKeys = ["bloodgroup", "createdAt", "updatedAt"];

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-6 text-2xl font-semibold text-center">
        View Bloodgroup Details
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left text-gray-700">Field</th>
              <th className="px-4 py-2 text-left text-gray-700">Value</th>
            </tr>
          </thead>
          <tbody>
            {displayKeys.map((key, index) => (
              <tr
                key={key}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="px-4 py-3 font-semibold text-gray-700">
                  {formatKey(key)}
                </td>
                <td className="px-4 py-3 text-gray-600">
                  {jsonData[key] || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewModal;
