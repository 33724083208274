import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Select from "react-select";

const GeneralProfile = ({
  handleDateChange,
  formData,
  handleChange,
  dropDownChange,
  postiveNegativeOptions,
  basicDiseaseOptions,
  placeHolderText,
  style,
  isEditable,
}) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Patient Information</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="date_of_entry"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Date of Entry
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="date_of_entry"
              onChange={handleDateChange}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.date_of_entry &&
                dayjs(formData?.date_of_entry, "YYYY-MM-DD")
              }
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Weight (kg)
            </label>
            <input
              type="number"
              id="weight"
              name="weight"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.weight}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Height (meters)
            </label>
            <input
              type="number"
              id="height"
              name="height"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.height}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Blood Pressure</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="blood_pressure_systolic"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Systolic
            </label>
            <input
              type="number"
              id="blood_pressure_systolic"
              name="blood_pressure_systolic"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.blood_pressure_systolic}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="blood_pressure_diastolic"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Diastolic
            </label>
            <input
              type="number"
              id="blood_pressure_diastolic"
              name="blood_pressure_diastolic"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.blood_pressure_diastolic}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">CBC</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
          {["PcV", "WBC", "PLATELETS", "Hemoglobin", "ESR"].map((item) => (
            <div key={item}>
              <label
                htmlFor={`cbc_${item.toLowerCase()}`}
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                {item}
              </label>
              <input
                type="number"
                id={`cbc_${item.toLowerCase()}`}
                name={`cbc_${item.toLowerCase()}`}
                min="0"
                step="0.01"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.[`cbc_${item.toLowerCase()}`] || ""}
                disabled={!isEditable}
              />
            </div>
          ))}
        </div>
      </div>
      {/* Create a textarea named recent_infections */}
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Recent Infections</h2>
        <textarea
          id="recent_infections"
          name="recent_infections"
          rows="4"
          className="w-full p-2 border rounded-md"
          onChange={handleChange}
          value={formData?.recent_infections || ""}
          disabled={!isEditable}
        />
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Chronic Kidney Disease</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="ckd_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Chronic Kidney Disease
            </label>
            <Select
              name="ckd_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.ckd_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.ckd_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="ckd_duration"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Duration (in Years)
            </label>
            <input
              type="number"
              id="ckd_duration"
              name="ckd_duration"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.ckd_duration}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="ckd_basic_disease"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Basic Disease
            </label>
            <Select
              name="ckd_basic_disease"
              onChange={dropDownChange}
              options={basicDiseaseOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.ckd_basic_disease &&
                basicDiseaseOptions?.find(
                  (option) => option.value === formData?.ckd_basic_disease
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Coronary Artery Disease</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="cad_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              CAD
            </label>
            <Select
              name="cad_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.cad_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.cad_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="cad_duration"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Duration (in Years)
            </label>
            <input
              type="number"
              id="cad_duration"
              name="cad_duration"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.cad_duration}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="cad_basic_disease"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Basic Disease
            </label>
            <Select
              name="cad_basic_disease"
              onChange={dropDownChange}
              options={basicDiseaseOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.cad_basic_disease &&
                basicDiseaseOptions?.find(
                  (option) => option.value === formData?.cad_basic_disease
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Chronic Tissue disease</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="ctd_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              CTD
            </label>
            <Select
              name="ctd_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.ctd_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.ctd_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="ctd_duration"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Duration (in Years)
            </label>
            <input
              type="number"
              id="ctd_duration"
              name="ctd_duration"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.ctd_duration}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="ctd_basic_disease"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Basic Disease
            </label>
            <Select
              name="ctd_basic_disease"
              onChange={dropDownChange}
              options={basicDiseaseOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.ctd_basic_disease &&
                basicDiseaseOptions?.find(
                  (option) => option.value === formData?.ctd_basic_disease
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">
          Autosomal Dominant Polycystic Kidney Disease
        </h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="adpkd_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              AD PKD
            </label>
            <Select
              name="adpkd_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.adpkd_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.adpkd_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="adpkd_duration"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Duration (in Years)
            </label>
            <input
              type="number"
              id="adpkd_duration"
              name="adpkd_duration"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.adpkd_duration}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="adpkd_basic_disease"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Basic Disease
            </label>
            <Select
              name="adpkd_basic_disease"
              onChange={dropDownChange}
              options={basicDiseaseOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.adpkd_basic_disease &&
                basicDiseaseOptions?.find(
                  (option) => option.value === formData?.adpkd_basic_disease
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Hypertension</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="hypertension_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Hypertension
            </label>
            <Select
              name="hypertension_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.hypertension_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.hypertension_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="hypertension_duration"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Duration (in Years)
            </label>
            <input
              type="number"
              id="hypertension_duration"
              name="hypertension_duration"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hypertension_duration}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Renal Calculus Discharge</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="rcd_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              RCD
            </label>
            <Select
              name="rcd_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.rcd_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.rcd_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="rcd_duration"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Duration (in Years)
            </label>
            <input
              type="number"
              id="rcd_duration"
              name="rcd_duration"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.rcd_duration}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Vascular Disease</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="vasculetion_exists"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Vascular Disease
            </label>
            <Select
              name="vasculetion_exists"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.vasculetion_exists &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.vasculetion_exists
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="vasculetion_specify"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Specify
            </label>
            <input
              type="text"
              id="vasculetion_specify"
              name="vasculetion_specify"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.vasculetion_specify}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralProfile;
