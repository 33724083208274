import React from "react";
import Select from "react-select";

const LiverProfile = ({
  handleChange,
  formData,
  postiveNegativeOptions,
  HbsAgStatusOptions,
  dropDownChange,
  placeHolderText,
  style,
}) => {
  return (
    <div className="mb-6">
      <h2 className="mb-4 text-xl font-semibold">Liver Profile</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {["SGOT", "SGPT", "TSH", "APTT", "PT", "INR"].map((item) => (
          <div key={item}>
            <label
              htmlFor={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              {item}
            </label>
            <input
              type="number"
              id={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
              name={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
              min="0"
              step="0.01"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={
                formData?.[
                  `${item.toLowerCase().replace(/ /g, "_")}_patient`
                ] ?? ""
              }
            />
          </div>
        ))}
      </div>
      <br />
      <br />
      <hr />
      <br />
      {/* DTPA gfr entry */}
      <div className="mt-3 mb-6">
        <h2 className="mb-4 text-xl font-semibold">DTPA GFR Scan report</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div>
            <label
              htmlFor="dtpa_gfr_left_kidney"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Left Kidney GFR
            </label>
            <input
              type="number"
              id="dtpa_gfr_left_kidney"
              name="dtpa_gfr_left_kidney"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.dtpa_gfr_left_kidney}
            />
          </div>
          {/* dtpa_left_kidney_volume */}
          <div>
            <label
              htmlFor="dtpa_left_kidney_volume"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Left Kidney Volume
            </label>
            <input
              type="number"
              id="dtpa_left_kidney_volume"
              name="dtpa_left_kidney_volume"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.dtpa_left_kidney_volume}
            />
          </div>
          <div>
            <label
              htmlFor="dtpa_gfr_right_kidney"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Right Kidney GFR
            </label>
            <input
              type="number"
              id="dtpa_gfr_right_kidney"
              name="dtpa_gfr_right_kidney"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.dtpa_gfr_right_kidney}
            />
          </div>
          <div>
            <label
              htmlFor="dtpa_right_kidney_volume"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Right Kidney Volume
            </label>
            <input
              type="number"
              id="dtpa_right_kidney_volume"
              name="dtpa_right_kidney_volume"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.dtpa_right_kidney_volume}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="dtpa_gfr_left_kidney_findings"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Left Kidney GFR Findings
            </label>
            <textarea
              id="dtpa_gfr_left_kidney_findings"
              name="dtpa_gfr_left_kidney_findings"
              rows="3"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.dtpa_gfr_left_kidney_findings || ""}
            />
          </div>
          <div>
            <label
              htmlFor="dtpa_gfr_right_kidney_findings"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Right Kidney GFR Findings
            </label>
            <textarea
              id="dtpa_gfr_right_kidney_findings"
              name="dtpa_gfr_right_kidney_findings"
              rows="3"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.dtpa_gfr_right_kidney_findings || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiverProfile;
