import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const UpdateModal = ({
  setShowUpdateModal,
  getDataFromServer,
  currentRecord,
}) => {
  let navigate = useNavigate();

  // Error Logging Service & Sanitize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    ...currentRecord,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/bloodgroup/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Bloodgroup Created Successfully.");
        getDataFromServer();
        setShowUpdateModal(false);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "AddConsultantChild", "/bloodgroup/add");
      });
  };
  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-semibold">Add Bloodgroup</h2>
      <form onSubmit={handleUpdateSubmit} className="space-y-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
          <div className="form-group">
            <label className="block text-gray-700">Bloodgroup</label>
            <input
              type="text"
              name="bloodgroup"
              required
              value={formData.bloodgroup}
              onChange={handleChange}
              placeholder={placeHolderText.organization || "Enter Bloodgroup"}
              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex justify-end mt-3 space-x-4 form-group">
          <button
            type="button"
            className="px-4 py-2 text-white bg-gray-500 rounded-md shadow-sm btn btn-secondary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={() => setShowUpdateModal(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm btn btn-primary hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Bloodgroup
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateModal;
