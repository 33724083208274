import React, { useState, useEffect } from "react";
import axios from "axios";

const HLA_CLASSIFIERS = ["A", "B", "C", "DRB1", "DQB1", "DPB1"];

const HLATypingMatchResultComponent = ({ recipient, donor }) => {
  const [clinicalRecords, setClinicalRecords] = useState([]);
  const [matchResult, setMatchResult] = useState({
    exactMatches: [],
    partialMatches: [],
    matchPercentage: 0,
  });
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const getClinicalRecords = () => {
    // API call to fetch clinical records
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/clinical-pair/`;
    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { recipient_id: recipient, donor_id: donor },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setClinicalRecords(response.data);
        // Calculate match result immediately after setting clinical records
        if (
          response.data?.recipient_clinical &&
          response.data?.donor_clinical
        ) {
          setMatchResult(
            matchHLATyping(
              response.data.recipient_clinical,
              response.data.donor_clinical
            )
          );
        }
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getClinicalRecords();
  }, [recipient, donor]); // Add recipient and donor to the dependency array

  const matchHLATyping = (recipient, donor) => {
    if (!recipient || !donor) {
      return { exactMatches: [], partialMatches: [], matchPercentage: 0 };
    }

    const recipientHLA = recipient.hlaTyping || {};
    const donorHLA = donor.hlaTyping || {};
    const exactMatches = [];
    const partialMatches = [];
    let totalAlleles = 0;

    // Iterate through all unique keys from both recipient and donor
    const allKeys = [
      ...new Set([...Object.keys(recipientHLA), ...Object.keys(donorHLA)]),
    ];

    for (const key of allKeys) {
      if (key === "_id" || HLA_CLASSIFIERS.includes(key)) continue; // Skip the _id field and classifiers

      const recipientAllele = recipientHLA[key];
      const donorAllele = donorHLA[key];

      // Only count alleles that are present in the recipient
      if (recipientAllele) {
        totalAlleles++;

        if (donorAllele) {
          if (recipientAllele === donorAllele) {
            exactMatches.push(key);
          } else {
            // Check for partial match (matching before the first ':')
            const recipientGroup = recipientAllele.split(":")[0];
            const donorGroup = donorAllele.split(":")[0];
            if (recipientGroup === donorGroup) {
              partialMatches.push(key);
            }
          }
        }
      }
    }

    // Calculate match percentage
    const matchScore = exactMatches.length + partialMatches.length * 0.5;
    const matchPercentage =
      totalAlleles > 0 ? (matchScore / totalAlleles) * 100 : 0;

    return {
      exactMatches,
      partialMatches,
      matchPercentage: matchPercentage.toFixed(2),
    };
  };

  const highlightPartialMatch = (recipientValue, donorValue) => {
    if (!recipientValue || !donorValue) return recipientValue || "-";

    const recipientParts = recipientValue.split("*");
    const donorParts = donorValue.split("*");

    if (recipientParts.length === 1 || donorParts.length === 1)
      return recipientValue;

    const recipientSubParts = recipientParts[1].split(":");
    const donorSubParts = donorParts[1].split(":");

    const isPartialMatch = recipientParts[0] === donorParts[0];
    const isFullMatch =
      isPartialMatch && recipientSubParts[0] === donorSubParts[0];

    const matchingPart = recipientSubParts[0];
    const nonMatchingPart = recipientSubParts.slice(1).join(":");

    return (
      <>
        {recipientParts[0]}*
        {isFullMatch ? (
          <span style={styles.fullMatch}>{matchingPart}</span>
        ) : (
          <span style={styles.partialMatchContainer}>
            <span style={styles.partialMatch}>{matchingPart.slice(0, 2)}</span>
            <span style={styles.partialNonMatch}>{matchingPart.slice(2)}</span>
          </span>
        )}
        {nonMatchingPart && (
          <span style={styles.nonMatch}>:{nonMatchingPart}</span>
        )}
      </>
    );
  };

  const renderHLATypingTable = () => {
    if (
      !clinicalRecords?.recipient_clinical?.hlaTyping ||
      !clinicalRecords?.donor_clinical?.hlaTyping
    ) {
      return null;
    }

    const recipientHLA = clinicalRecords.recipient_clinical.hlaTyping;
    const donorHLA = clinicalRecords.donor_clinical.hlaTyping;
    const allKeys = [
      ...new Set([...Object.keys(recipientHLA), ...Object.keys(donorHLA)]),
    ].filter((key) => key !== "_id");

    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Recipient HLA</th>
            <th style={styles.th}>Allele</th>
            <th style={styles.th}>Donor HLA</th>
          </tr>
        </thead>
        <tbody>
          {allKeys.map((key) => {
            const isClassifier = HLA_CLASSIFIERS.includes(key);
            const isExactMatch = matchResult.exactMatches.includes(key);
            const isPartialMatch = matchResult.partialMatches.includes(key);
            const recipientValue = recipientHLA[key] || "-";
            const donorValue = donorHLA[key] || "-";

            return (
              <tr key={key} style={styles.tr}>
                <td style={styles.td}>
                  {isClassifier ? (
                    recipientValue
                  ) : isExactMatch ? (
                    <span style={styles.exactMatch}>{recipientValue}</span>
                  ) : isPartialMatch ? (
                    highlightPartialMatch(recipientValue, donorValue)
                  ) : (
                    recipientValue
                  )}
                </td>
                <td style={styles.td}>
                  <span
                    style={{
                      ...styles.alleleCell,
                      ...(isExactMatch
                        ? styles.exactMatchText
                        : isPartialMatch
                        ? styles.partialMatchText
                        : {}),
                    }}
                  >
                    {key}
                  </span>
                </td>
                <td style={styles.td}>
                  {isClassifier ? (
                    donorValue
                  ) : isExactMatch ? (
                    <span style={styles.exactMatch}>{donorValue}</span>
                  ) : isPartialMatch ? (
                    highlightPartialMatch(donorValue, recipientValue)
                  ) : (
                    donorValue
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderLegend = () => (
    <div style={styles.legend}>
      <h4>Legend:</h4>
      <p>
        <span style={styles.fullMatch}>Full Match</span>
      </p>
      <p>
        <span style={styles.partialMatchContainer}>
          <span style={styles.partialMatch}>Pa</span>
          <span style={styles.partialNonMatch}>rtial</span>
        </span>{" "}
        Partial Match (first two characters match)
      </p>
      <p>
        <span style={styles.nonMatch}>Non-Match</span>
      </p>
    </div>
  );

  return (
    <div className="container p-4 mx-auto">
      <h3>HLA Typing Match Results</h3>
      <div style={styles.subHeader}>
        <p>Recipient: {recipient}</p>
        <p>Donor: {donor}</p>
      </div>
      <div style={styles.matchResult}>
        <h4>Match Result:</h4>
        <p>
          Matching Percentage: <strong>{matchResult.matchPercentage}%</strong>
        </p>
        <p>
          Exact Matches: <strong>{matchResult.exactMatches.join(", ")}</strong>
        </p>
        <p>
          Partial Matches:{" "}
          <strong>{matchResult.partialMatches.join(", ")}</strong>
        </p>
        <p style={styles.note}>
          Note: Matching percentage is calculated by giving full weight to exact
          matches and half weight to partial matches.
        </p>
      </div>
      <div style={styles.tableContainer}>
        <button
          style={styles.toggleButton}
          onClick={() => setIsTableExpanded(!isTableExpanded)}
        >
          {isTableExpanded ? "Hide Details" : "Show Details"}
        </button>
        {isTableExpanded && (
          <>
            {renderHLATypingTable()}
            {/* {renderLegend()} */}
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  },
  tr: {
    backgroundColor: "#fff",
  },
  alleleCell: {
    display: "inline-block",
    padding: "2px 6px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  fullMatch: {
    backgroundColor: "#e6ffe6",
    padding: "2px 4px",
    borderRadius: "3px",
    fontWeight: "bold",
  },
  partialMatchContainer: {
    padding: "2px 4px",
    borderRadius: "3px",
    backgroundColor: "#fff0e6",
  },
  partialMatch: {
    color: "#ff6600",
    fontWeight: "bold",
  },
  partialNonMatch: {
    color: "#000",
    fontWeight: "normal",
  },
  nonMatch: {
    backgroundColor: "#ffe6e6",
    padding: "2px 4px",
    borderRadius: "3px",
  },
  exactMatchText: {
    color: "#008000",
    fontWeight: "bold",
  },
  partialMatchText: {
    color: "#ff8c00",
    fontWeight: "bold",
  },
  matchResult: {
    backgroundColor: "#f0f0f0",
    padding: "15px",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  note: {
    fontSize: "0.9em",
    fontStyle: "italic",
    color: "#666",
  },
  tableContainer: {
    marginTop: "20px",
  },
  toggleButton: {
    padding: "10px 15px",
    backgroundColor: "#3498db",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  legend: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
};

export default HLATypingMatchResultComponent;
