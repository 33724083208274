import React, { useState, useEffect } from "react";
import { FaUser, FaVial, FaDna, FaChartBar } from "react-icons/fa";
import MatchCardComponent, { ArrowIndicator } from "./MatchCardComponent";

import HLATypingMatchResultComponent from "./HLATypingMatchResultComponent";
import SingleAntigenBeadTest from "./SingleAntigenBeadTest";
import CREGAnalysisComponent from "./CREGAnalysisComponent";

import GeminiMatchDonors from "./GeminiMatchDonors";
import ReportHistoryModal from "./ReportHistoryModal";

const Tabs = ({
  currentRecord,
  clinicalRecords,
  qualifiedPairs,
  setCurrentActiveTab,
}) => {
  const [activeTab, setActiveTab] = useState("primary");
  const [expandedSections, setExpandedSections] = useState({});
  const [hlaCompatibilityStatus, setHLACompatibilityStatus] = useState(false);
  const [hlaCompatibilityResult, setHLACompatibilityResult] = useState(false);
  const [sabTestStatus, setSabTestStatus] = useState(false);

  const [sabTestResult, setSABTestResult] = useState();
  const [hlaTypingScore, setHlaTypingScore] = useState();

  const [showResponse, setShowResponse] = useState(false);
  const [aiAnalysisData, setAiAnalysisData] = useState({
    primary: { showResponse: false, aiResponse: "" },
    secondary: { showResponse: false, aiResponse: "" },
  });
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedRecipientCode, setSelectedRecipientCode] = useState(null);
  const [selectedDonorCode, setSelectedDonorCode] = useState(null);

  useEffect(() => {
    setCurrentActiveTab(activeTab);
    setHLACompatibilityStatus(false);
    setHLACompatibilityResult(false);
    // Clear AI analysis data when switching tabs
    setAiAnalysisData((prev) => ({
      ...prev,
      [activeTab]: { showResponse: false, aiResponse: "" },
    }));
  }, [activeTab, setCurrentActiveTab]);

  const getTabStyles = (tabType) => {
    const isActive = activeTab === tabType;
    const isPrimary = tabType === "primary";

    return {
      tabButton: `flex items-center justify-center px-4 py-2 font-semibold rounded-t-lg transition duration-300 ${
        isActive
          ? isPrimary
            ? "bg-blue-500 text-white"
            : "bg-green-500 text-white"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      } w-full sm:w-auto`,
      tabContent: isPrimary
        ? "bg-blue-50 border-t-4 border-blue-500"
        : "bg-green-50 border-t-4 border-green-500",
    };
  };

  const TabButton = ({ id, label, icon }) => {
    const styles = getTabStyles(id);
    return (
      <button className={styles.tabButton} onClick={() => setActiveTab(id)}>
        {icon}
        <span className="hidden ml-2 sm:inline">{label}</span>
        <span className="ml-2 sm:hidden">{label.split(" ")[0]}</span>
      </button>
    );
  };

  const getClinicalRecord = (type, id) => {
    const recordType =
      type === "recipient" ? "recipient_clinicals" : "donor_clinicals";
    return (
      clinicalRecords?.[recordType]?.find((c) => c[`${type}_id`] === id) || {}
    );
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const renderClinicalData = (data, personType) => {
    if (!data) return null;

    const sections = [
      { id: "general", title: "General" },
      { id: "cbc", title: "CBC" },
      { id: "serology", title: "Serology" },
      { id: "renal", title: "Renal Profile" },
      { id: "liver", title: "Liver Profile" },
      { id: "other", title: "Other Tests" },
      { id: "hla", title: "HLA Typing" },
    ];

    // Add HLA Antibodies section only for recipient
    if (personType === "recipient") {
      sections.push({ id: "antibodies", title: "HLA Antibodies" });
    }

    return (
      <div className="p-6 mt-4 bg-white rounded-lg shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Clinical Data</h3>
        {sections.map((section) => (
          <div key={section.id} className="mb-4">
            <button
              className="flex items-center justify-between w-full px-4 py-2 text-left bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none"
              onClick={() => toggleSection(`${personType}-${section.id}`)}
            >
              <span className="font-semibold">{section.title}</span>
              <span className="text-xl">
                {expandedSections[`${personType}-${section.id}`] ? "−" : "+"}
              </span>
            </button>
            {expandedSections[`${personType}-${section.id}`] && (
              <div className="p-4 mt-2 rounded-lg bg-gray-50">
                {renderSectionContent(section.id, data, personType)}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSectionContent = (sectionId, data, personType) => {
    switch (sectionId) {
      case "general":
        return (
          <>
            <p>
              <strong>Weight:</strong> {data.weight} kg
            </p>
            <p>
              <strong>Height:</strong> {data.height} m
            </p>
            <p>
              <strong>Blood Pressure:</strong> {data.blood_pressure?.systolic}/
              {data.blood_pressure?.diastolic} mmHg
            </p>
            <p>
              <strong>Date of Entry:</strong>{" "}
              {new Date(data.date_of_entry).toLocaleDateString()}
            </p>
          </>
        );
      case "cbc":
        return (
          <>
            <p>
              <strong>WBC:</strong> {data.cbc?.wbc || "N/A"} x10^9/L
            </p>
            <p>
              <strong>RBC:</strong> {data.cbc?.rbc || "N/A"}
            </p>
            <p>
              <strong>PCV:</strong> {data.cbc?.pcv || "N/A"}
            </p>
            <p>
              <strong>Platelets:</strong> {data.cbc?.platelets || "N/A"} x10^9/L
            </p>
            <p>
              <strong>Hemoglobin:</strong> {data.cbc?.hemoglobin || "N/A"} g/dL
            </p>
            <p>
              <strong>ESR:</strong> {data.esr || "N/A"} mm/hr
            </p>
          </>
        );
      case "serology":
        return (
          <>
            <p>
              <strong>HbsAg:</strong> {data.HbsAg_patient || "N/A"}
            </p>
            <p>
              <strong>CMV IgG:</strong> {data.cmv_igg_patient || "N/A"}
            </p>
            <p>
              <strong>CMV IgM:</strong> {data.cmv_igm_patient || "N/A"}
            </p>
            <p>
              <strong>EBV IgG:</strong> {data.ebv_igg_patient || "N/A"}
            </p>
            <p>
              <strong>EBV IgM:</strong> {data.ebv_igm_patient || "N/A"}
            </p>
            <p>
              <strong>Anti-HCV Status:</strong>{" "}
              {data.antiHCVStatus?.anti_hcv_status || "N/A"}
            </p>
            <p>
              <strong>HCV Viral Load:</strong>{" "}
              {data.antiHCVStatus?.hcv_viral_load || "N/A"}
            </p>
            <p>
              <strong>HCV Genotype:</strong>{" "}
              {data.antiHCVStatus?.genotype || "N/A"}
            </p>
            <p>
              <strong>HIV Status:</strong> {data.hivStatus?.hiv_status || "N/A"}
            </p>
          </>
        );
      case "renal":
        return (
          <>
            <p>
              <strong>Creatinine:</strong>{" "}
              {data.renal_profile?.creatinine || "N/A"} mg/dL
            </p>
            <p>
              <strong>Albumin:</strong> {data.renal_profile?.albumin || "N/A"}{" "}
              g/dL
            </p>
            <p>
              <strong>Calcium:</strong> {data.renal_profile?.calcium || "N/A"}{" "}
              mg/dL
            </p>
            <p>
              <strong>Phosphorus:</strong>{" "}
              {data.renal_profile?.phosphorus || "N/A"} mg/dL
            </p>
            <p>
              <strong>Potassium:</strong>{" "}
              {data.renal_profile?.potassium || "N/A"} mEq/L
            </p>
            <p>
              <strong>Sodium:</strong> {data.renal_profile?.sodium || "N/A"}{" "}
              mEq/L
            </p>
            <p>
              <strong>Glucose:</strong> {data.renal_profile?.glucose || "N/A"}{" "}
              mg/dL
            </p>
            <p>
              <strong>HbA1c:</strong> {data.renal_profile?.HbA1c || "N/A"} %
            </p>
            <p>
              <strong>Carbon Dioxide:</strong>{" "}
              {data.renal_profile?.carbon_dioxide || "N/A"} mEq/L
            </p>
          </>
        );
      case "liver":
        return (
          <>
            <p>
              <strong>SGOT:</strong> {data.sgot_patient || "N/A"} U/L
            </p>
            <p>
              <strong>SGPT:</strong> {data.sgpt_patient || "N/A"} U/L
            </p>
            <p>
              <strong>ALT Levels:</strong>{" "}
              {data.antiHCVStatus?.alt_levels || "N/A"}
            </p>
            <p>
              <strong>Fibrosis Stage:</strong>{" "}
              {data.antiHCVStatus?.fibrosis_stage || "N/A"}
            </p>
          </>
        );
      case "other":
        return (
          <>
            <p>
              <strong>TSH:</strong> {data.tsh_patient || "N/A"} mIU/L
            </p>
            <p>
              <strong>APTT:</strong> {data.APTT_patient || "N/A"} seconds
            </p>
            <p>
              <strong>PT:</strong> {data.pt_patient || "N/A"} seconds
            </p>
            <p>
              <strong>INR:</strong> {data.inr_patient || "N/A"}
            </p>
            <p>
              <strong>Urine C&S:</strong> {data.urine_cs || "N/A"}
            </p>
            <p>
              <strong>Recent Infections:</strong>{" "}
              {data.recent_infections || "N/A"}
            </p>
            {personType === "donor" && (
              <>
                <p>
                  <strong>DTPA GFR Left Kidney:</strong>{" "}
                  {data.dtpa_gfr_left_kidney || "N/A"}
                </p>
                <p>
                  <strong>DTPA GFR Left Kidney Findings:</strong>{" "}
                  {data.dtpa_gfr_left_kidney_findings || "N/A"}
                </p>
                <p>
                  <strong>DTPA GFR Right Kidney:</strong>{" "}
                  {data.dtpa_gfr_right_kidney || "N/A"}
                </p>
                <p>
                  <strong>DTPA GFR Right Kidney Findings:</strong>{" "}
                  {data.dtpa_gfr_right_kidney_findings || "N/A"}
                </p>
              </>
            )}
          </>
        );
      case "hla":
        return (
          data.hlaTyping && (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              <p>
                <strong>HLA-A:</strong> {data.hlaTyping.HLA_A_Allele_1},{" "}
                {data.hlaTyping.HLA_A_Allele_2}
              </p>
              <p>
                <strong>HLA-B:</strong> {data.hlaTyping.HLA_B_Allele_1},{" "}
                {data.hlaTyping.HLA_B_Allele_2}
              </p>
              <p>
                <strong>HLA-C:</strong> {data.hlaTyping.HLA_C_Allele_1},{" "}
                {data.hlaTyping.HLA_C_Allele_2}
              </p>
              <p>
                <strong>HLA-DRB1:</strong> {data.hlaTyping.HLA_DRB1_Allele_1},{" "}
                {data.hlaTyping.HLA_DRB1_Allele_2}
              </p>
              <p>
                <strong>HLA-DQB1:</strong> {data.hlaTyping.HLA_DQB1_Allele_1},{" "}
                {data.hlaTyping.HLA_DQB1_Allele_2}
              </p>
              <p>
                <strong>HLA-DPB1:</strong> {data.hlaTyping.HLA_DPB1_Allele_1},{" "}
                {data.hlaTyping.HLA_DPB1_Allele_2}
              </p>
            </div>
          )
        );
      case "antibodies":
        return (
          data?.hlaAntibody && (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {data.hlaAntibody.map((antibody, index) => (
                <div
                  key={antibody._id || index}
                  className="p-2 bg-gray-100 rounded-md"
                >
                  <p>
                    <strong>Class:</strong> {antibody.class}
                  </p>
                  <p>
                    <strong>HLA Type:</strong> {antibody.hlatype}
                  </p>
                  <p>
                    <strong>Antigen:</strong> {antibody.antigen}
                  </p>
                  <p>
                    <strong>MFI:</strong> {antibody.MFI.toFixed(2)}
                  </p>
                  <p>
                    <strong>Status:</strong> {antibody.status}
                  </p>
                </div>
              ))}
            </div>
          )
        );
      default:
        return null;
    }
  };

  const renderTab = (recipientType, donorType) => {
    const recipient = currentRecord[`${recipientType}Recipient`];
    const donor = currentRecord[`${donorType}Donor`];
    const recipientClinicals = getClinicalRecord(
      "recipient",
      qualifiedPairs[`${recipientType}_recipient_id`]
    );
    const donorClinicals = getClinicalRecord(
      "donor",
      qualifiedPairs[`${donorType}_donor_id`]
    );
    const styles = getTabStyles(recipientType);

    return (
      <div className={`space-y-6 p-6 rounded-b-lg ${styles.tabContent}`}>
        <div className="flex flex-col items-center justify-between gap-4 mb-6 md:flex-row">
          <MatchCardComponent
            title={`${
              recipientType.charAt(0).toUpperCase() + recipientType.slice(1)
            } Recipient`}
            person={recipient}
            code={recipient.recipient_code}
            consultant={recipient.consultant_id}
            bgColor={
              recipientType === "primary" ? "bg-blue-100" : "bg-green-100"
            }
            textColor={
              recipientType === "primary" ? "text-blue-800" : "text-green-800"
            }
          />
          <ArrowIndicator />
          <MatchCardComponent
            title={`${
              donorType.charAt(0).toUpperCase() + donorType.slice(1)
            } Donor`}
            person={donor}
            code={donor.donor_code}
            consultant={donor.consultant_id}
            bgColor={donorType === "primary" ? "bg-blue-100" : "bg-green-100"}
            textColor={
              donorType === "primary" ? "text-blue-800" : "text-green-800"
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-6">
          <TabSection
            title="Single Antigen Bead Test"
            icon={<FaVial className="text-2xl text-green-500" />}
            fullWidth={true}
          >
            <div className="overflow-x-auto">
              <SingleAntigenBeadTest
                recipient={qualifiedPairs[`${recipientType}_recipient_id`]}
                donor={qualifiedPairs[`${donorType}_donor_id`]}
                setSABTestResult={setSABTestResult}
              />
            </div>
          </TabSection>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <TabSection
              title="HLA Match Details"
              icon={<FaDna className="text-2xl text-blue-500" />}
            >
              <HLATypingMatchResultComponent
                recipient={qualifiedPairs[`${recipientType}_recipient_id`]}
                donor={qualifiedPairs[`${donorType}_donor_id`]}
                setHlaTypingScore={setHlaTypingScore}
              />
            </TabSection>

            <TabSection
              title="CREG Analysis"
              icon={<FaChartBar className="text-2xl text-purple-500" />}
            >
              <CREGAnalysisComponent
                recipient={qualifiedPairs[`${recipientType}_recipient_id`]}
                donor={qualifiedPairs[`${donorType}_donor_id`]}
              />
            </TabSection>
          </div>
        </div>

        <div className="mt-3">
          <GeminiMatchDonors
            recipient={recipient}
            donor={donor}
            recipientClinicals={recipientClinicals}
            donorClinicals={donorClinicals}
            setShowResponse={(showResponse) =>
              setAiAnalysisData((prev) => ({
                ...prev,
                [recipientType]: { ...prev[recipientType], showResponse },
              }))
            }
            showResponse={aiAnalysisData[recipientType].showResponse}
            aiResponse={aiAnalysisData[recipientType].aiResponse}
            tabId={recipientType}
            setAiAnalysisData={setAiAnalysisData}
            activeTab={activeTab}
            setShowHistoryModal={() => {
              setSelectedRecipientCode(recipient.recipient_code);
              setSelectedDonorCode(donor.donor_code);
              setShowHistoryModal(true);
            }}
          />
          <ReportHistoryModal
            show={showHistoryModal}
            onClose={() => setShowHistoryModal(false)}
            recipientCode={recipient?.recipient_code}
            donorCode={donor.donor_code}
            recipientClinicals={recipientClinicals}
            donorClinicals={donorClinicals}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {renderClinicalData(recipientClinicals, "recipient")}
          {renderClinicalData(donorClinicals, "donor")}
        </div>
      </div>
    );
  };

  const TabSection = ({ title, icon, children, fullWidth = false }) => (
    <div
      className={`bg-white rounded-lg shadow-md overflow-hidden ${
        fullWidth ? "col-span-full" : ""
      }`}
    >
      <div className="flex items-center p-4 border-b bg-gray-50">
        {icon}
        <h3 className="ml-2 text-lg font-semibold text-gray-800">{title}</h3>
      </div>
      <div className="p-4">{children}</div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-col items-center justify-between mb-4 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
        <TabButton
          id="primary"
          label="Primary Recipient"
          icon={<FaUser className="text-xl" />}
        />
        <TabButton
          id="secondary"
          label="Secondary Recipient"
          icon={<FaUser className="text-xl" />}
        />
      </div>
      <div className="mt-4">
        {activeTab === "primary"
          ? renderTab("primary", "secondary")
          : renderTab("secondary", "primary")}
      </div>
    </div>
  );
};

export default Tabs;
