import React, { useState, useEffect } from "react";
import {
  FaVial,
  FaDna,
  FaChartBar,
  FaExclamationTriangle,
} from "react-icons/fa";

import HLAMatchScore from "./HLAMatchScore";
import SABTestScore from "./SABTestScore";

const MatchPairOverallScore = ({ pair }) => {
  const [primaryHLAScore, setPrimaryHLAScore] = useState(null);
  const [secondaryHLAScore, setSecondaryHLAScore] = useState(null);
  const [primarySABScore, setPrimarySABScore] = useState(null);
  const [secondarySABScore, setSecondarySABScore] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const calculateScores = async () => {
      try {
        // Calculate HLA Match Scores
        console.log("Calculating primary HLA score...");
        const primaryHLAScoreResult = await HLAMatchScore(
          pair.primaryRecipient._id,
          pair.secondaryDonor._id
        );
        setPrimaryHLAScore(primaryHLAScoreResult);

        console.log("Calculating secondary HLA score...");
        const secondaryHLAScoreResult = await HLAMatchScore(
          pair.secondaryRecipient._id,
          pair.primaryDonor._id
        );
        setSecondaryHLAScore(secondaryHLAScoreResult);

        // Calculate SAB Test Scores
        console.log("Calculating primary SAB score...");
        const primarySABScoreResult = await SABTestScore(
          pair.primaryRecipient._id,
          pair.secondaryDonor._id
        );
        setPrimarySABScore(primarySABScoreResult);

        console.log("Calculating secondary SAB score...");
        const secondarySABScoreResult = await SABTestScore(
          pair.secondaryRecipient._id,
          pair.primaryDonor._id
        );
        setSecondarySABScore(secondarySABScoreResult);

        setError(null);
      } catch (err) {
        console.error("Error calculating scores:", err);
        setError(`Failed to calculate scores: ${err.message}`);
        setPrimaryHLAScore(null);
        setSecondaryHLAScore(null);
        setPrimarySABScore(null);
        setSecondarySABScore(null);
      }
    };

    calculateScores();
  }, [pair]);

  const renderSABScore = (score, isPrimary) => {
    const isNumber = !isNaN(parseFloat(score));
    const scoreClass = isNumber ? "bg-blue-100" : "bg-red-100";
    const textClass = isNumber ? "text-blue-700" : "text-red-700";
    const iconClass = isNumber ? "fa-vial" : "mdi-close-octagon";

    return (
      <div className={`flex items-center p-2 rounded ${scoreClass}`}>
        <FaVial className={`mr-2 ${textClass}`} />
        <span className={`font-medium ${textClass}`}>SAB Test Score:</span>
        <span className={`ml-auto font-bold ${textClass}`}>
          {isNumber ? `${parseFloat(score).toFixed(2)}%` : score}
        </span>
      </div>
    );
  };

  if (error) {
    return (
      <div className="w-full p-6 border-2 border-red-300 rounded-lg shadow-lg bg-red-50">
        <div className="flex items-center mb-4">
          <FaExclamationTriangle className="mr-3 text-3xl text-red-600" />
          <h3 className="text-2xl font-bold text-red-700">
            Error Calculating Scores
          </h3>
        </div>
        <p className="font-medium text-red-600">{error}</p>
        <p className="mt-4 text-gray-700">
          Please try again later or contact support if the problem persists.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full p-6 rounded-lg shadow-lg bg-gray-50">
      <h3 className="flex items-center p-3 mb-4 text-2xl font-bold text-indigo-800 bg-gray-200 rounded-lg">
        <FaChartBar className="mr-2" />
        Match Pair Overall Score Report
      </h3>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="p-4 border border-indigo-200 rounded-lg">
          <h4 className="mb-3 text-lg font-semibold text-indigo-700">
            <i className="mr-2 fas fa-user-friends"></i>Primary Pair Scores
          </h4>
          <div className="space-y-3">
            {primaryHLAScore !== null ? (
              <div className="flex items-center p-2 bg-green-100 rounded">
                <FaDna className="mr-2 text-green-600" />
                <span className="font-medium">HLA Match Score:</span>
                <span className="ml-auto font-bold text-green-700">
                  {primaryHLAScore}%
                </span>
              </div>
            ) : (
              <div className="flex items-center p-2 bg-gray-100 rounded">
                <i className="mr-2 text-gray-500 fas fa-spinner fa-spin"></i>
                <span className="text-gray-600">Calculating HLA score...</span>
              </div>
            )}
            {primarySABScore !== null ? (
              renderSABScore(primarySABScore, true)
            ) : (
              <div className="flex items-center p-2 bg-gray-100 rounded">
                <i className="mr-2 text-gray-500 fas fa-spinner fa-spin"></i>
                <span className="text-gray-600">Calculating SAB score...</span>
              </div>
            )}
          </div>
        </div>
        <div className="p-4 border border-indigo-200 rounded-lg">
          <h4 className="mb-3 text-lg font-semibold text-indigo-700">
            <i className="mr-2 fas fa-user-friends"></i>Secondary Pair Scores
          </h4>
          <div className="space-y-3">
            {secondaryHLAScore !== null ? (
              <div className="flex items-center p-2 bg-green-100 rounded">
                <FaDna className="mr-2 text-green-600" />
                <span className="font-medium">HLA Match Score:</span>
                <span className="ml-auto font-bold text-green-700">
                  {secondaryHLAScore}%
                </span>
              </div>
            ) : (
              <div className="flex items-center p-2 bg-gray-100 rounded">
                <i className="mr-2 text-gray-500 fas fa-spinner fa-spin"></i>
                <span className="text-gray-600">Calculating HLA score...</span>
              </div>
            )}
            {secondarySABScore !== null ? (
              renderSABScore(secondarySABScore, false)
            ) : (
              <div className="flex items-center p-2 bg-gray-100 rounded">
                <i className="mr-2 text-gray-500 fas fa-spinner fa-spin"></i>
                <span className="text-gray-600">Calculating SAB score...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchPairOverallScore;
