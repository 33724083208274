import React, { useState, useEffect } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import ReactMarkdown from "react-markdown";
import toast from "react-hot-toast";
import { FaHistory, FaRobot } from "react-icons/fa";

import SABTestScore from "./SABTestScores";
import { matchHLATyping } from "./MatchScores";
import { analyzeCREG } from "./CREGAnalysis";

const GeminiMatchDonors = ({
  recipient,
  donor,
  recipientClinicals,
  donorClinicals,
  setShowResponse,
  showResponse,
  aiResponse,
  tabId,
  setAiAnalysisData,
  activeTab,
  setShowHistoryModal,
}) => {
  const genAI = new GoogleGenerativeAI(
    process.env.REACT_APP_GOOGLE_GEMINI_APIKEY
  );
  const [localAiResponse, setLocalAiResponse] = useState("");
  const [currentHLAScore, setCurrentHLAScore] = useState(null);
  const [currentSABScore, setCurrentSABScore] = useState(null);
  const [error, setError] = useState(null);
  const [cregAnalysisResults, setCregAnalysisResults] = useState(null);
  const [diagnosis, setDiagnosis] = useState("");
  const [stage, setStage] = useState("idle");

  useEffect(() => {
    console.log("aiResponse prop changed:", aiResponse);
    setLocalAiResponse(aiResponse || ""); // Ensure localAiResponse is always a string
  }, [aiResponse]);

  useEffect(() => {
    const calculateScores = async () => {
      try {
        if (recipientClinicals && donorClinicals) {
          console.log("Calculating primary HLA score...");
          const hlaScoreResult = matchHLATyping(
            recipientClinicals,
            donorClinicals
          );
          setCurrentHLAScore(hlaScoreResult);

          console.log("Calculating primary SAB score...");
          const sABScoreResult = await SABTestScore(recipient._id, donor._id);
          setCurrentSABScore(sABScoreResult);
        } else {
          console.log("Clinical data not yet available");
          setCurrentHLAScore(null);
          setCurrentSABScore(null);
        }
      } catch (err) {
        console.error("Error calculating scores:", err);
        setError(`Failed to calculate scores: ${err.message}`);
        setCurrentHLAScore(null);
        setCurrentSABScore(null);
      }
    };

    if (recipient && donor && recipientClinicals && donorClinicals) {
      calculateScores();
    }
  }, [recipient, donor, recipientClinicals, donorClinicals]);

  useEffect(() => {
    const performAnalysis = () => {
      if (recipientClinicals && donorClinicals && recipient && donor) {
        const cregResults = analyzeCREG(recipientClinicals, donorClinicals);
        setCregAnalysisResults(cregResults);
      }
    };

    performAnalysis();
  }, [recipient, donor, recipientClinicals, donorClinicals]);

  useEffect(() => {
    if (stage === "complete") {
      setStage("idle");
      if (setAiAnalysisData) {
        setAiAnalysisData((prev) => ({
          ...prev,
          [tabId]: { ...prev[tabId], aiResponse: "" },
        }));
      }
    }
  }, [activeTab, setAiAnalysisData, tabId]);

  const generateDiagnosisPrompt = () => `
      You are a Clinical Transplant Specialist with expertise in immunology and transplant medicine. Please analyze the following patient data and provide a detailed diagnosis and assessment.

      **Recipient Blood Group Details:**
      - **ABO Group:** ${recipient?.blood_group?.label || "N/A"}
      - **Rh Factor:** ${
        recipient?.blood_group?.label?.includes("+") ? "Positive" : "Negative"
      }

      **Donor Blood Group Details:**
      - **ABO Group:** ${donor?.blood_group?.label || "N/A"}
      - **Rh Factor:** ${
        donor?.blood_group?.label?.includes("+") ? "Positive" : "Negative"
      }

      **HLA Typing Match:**

      **Recipient HLA Alleles:**
      - **HLA_A_Allele_1:** ${
        recipientClinicals?.hlaTyping?.HLA_A_Allele_1 || "N/A"
      }
      - **HLA_A_Allele_2:** ${
        recipientClinicals?.hlaTyping?.HLA_A_Allele_2 || "N/A"
      }
      - **HLA_B_Allele_1:** ${
        recipientClinicals?.hlaTyping?.HLA_B_Allele_1 || "N/A"
      }
      - **HLA_B_Allele_2:** ${
        recipientClinicals?.hlaTyping?.HLA_B_Allele_2 || "N/A"
      }
      - **HLA_C_Allele_1:** ${
        recipientClinicals?.hlaTyping?.HLA_C_Allele_1 || "N/A"
      }
      - **HLA_C_Allele_2:** ${
        recipientClinicals?.hlaTyping?.HLA_C_Allele_2 || "N/A"
      }
      - **HLA_DPB1_Allele_1:** ${
        recipientClinicals?.hlaTyping?.HLA_DPB1_Allele_1 || "N/A"
      }
      - **HLA_DPB1_Allele_2:** ${
        recipientClinicals?.hlaTyping?.HLA_DPB1_Allele_2 || "N/A"
      }
      - **HLA_DQB1_Allele_1:** ${
        recipientClinicals?.hlaTyping?.HLA_DQB1_Allele_1 || "N/A"
      }
      - **HLA_DQB1_Allele_2:** ${
        recipientClinicals?.hlaTyping?.HLA_DQB1_Allele_2 || "N/A"
      }
      - **HLA_DRB1_Allele_1:** ${
        recipientClinicals?.hlaTyping?.HLA_DRB1_Allele_1 || "N/A"
      }
      - **HLA_DRB1_Allele_2:** ${
        recipientClinicals?.hlaTyping?.HLA_DRB1_Allele_2 || "N/A"
      }

      **Donor HLA Alleles:**
      - **HLA_A_Allele_1:** ${
        donorClinicals?.hlaTyping?.HLA_A_Allele_1 || "N/A"
      }
      - **HLA_A_Allele_2:** ${
        donorClinicals?.hlaTyping?.HLA_A_Allele_2 || "N/A"
      }
      - **HLA_B_Allele_1:** ${
        donorClinicals?.hlaTyping?.HLA_B_Allele_1 || "N/A"
      }
      - **HLA_B_Allele_2:** ${
        donorClinicals?.hlaTyping?.HLA_B_Allele_2 || "N/A"
      }
      - **HLA_C_Allele_1:** ${
        donorClinicals?.hlaTyping?.HLA_C_Allele_1 || "N/A"
      }
      - **HLA_C_Allele_2:** ${
        donorClinicals?.hlaTyping?.HLA_C_Allele_2 || "N/A"
      }
      - **HLA_DPB1_Allele_1:** ${
        donorClinicals?.hlaTyping?.HLA_DPB1_Allele_1 || "N/A"
      }
      - **HLA_DPB1_Allele_2:** ${
        donorClinicals?.hlaTyping?.HLA_DPB1_Allele_2 || "N/A"
      }
      - **HLA_DQB1_Allele_1:** ${
        donorClinicals?.hlaTyping?.HLA_DQB1_Allele_1 || "N/A"
      }
      - **HLA_DQB1_Allele_2:** ${
        donorClinicals?.hlaTyping?.HLA_DQB1_Allele_2 || "N/A"
      }
      - **HLA_DRB1_Allele_1:** ${
        donorClinicals?.hlaTyping?.HLA_DRB1_Allele_1 || "N/A"
      }
      - **HLA_DRB1_Allele_2:** ${
        donorClinicals?.hlaTyping?.HLA_DRB1_Allele_2 || "N/A"
      }

      **Single Antigen Bead (SAB) Match Details:**
      - **Detected Antibodies:** ${JSON.stringify(
        currentSABScore?.matchResults || "None"
      )}
      - **Mean Fluorescence Intensity (MFI) Values:** ${JSON.stringify(
        currentSABScore?.score || "N/A"
      )}

      **CREG Score:**
      - **Score:** ${cregAnalysisResults?.score || "N/A"}
      - **Interpretation:** ${cregAnalysisResults?.interpretation || "N/A"}

      **Clinical Details of Recipient:**
      - **Medical History:** ${recipientClinicals?.medicalHistory || "N/A"}
      - **Comorbidities:** ${recipientClinicals?.comorbidities || "N/A"}
      - **Previous Transfusions/Transplants:** ${
        recipientClinicals?.previousTransfusions || "N/A"
      }
      - **Current Medications:** ${
        recipientClinicals?.currentMedications || "N/A"
      }
      - **Other Relevant Details:** ${recipientClinicals?.otherDetails || "N/A"}

      **Clinical Details of Donor:**
      - **Medical History:** ${donorClinicals?.medicalHistory || "N/A"}
      - **Infectious Disease Status:** ${
        donorClinicals?.infectiousDiseaseStatus || "N/A"
      }
      - **Current Medications:** ${donorClinicals?.currentMedications || "N/A"}
      - **Other Relevant Details:** ${donorClinicals?.otherDetails || "N/A"}

      **Instructions:**

      As a Clinical Transplant Specialist, please:

      1. **Blood Group Compatibility:**
        - Assess the ABO and Rh compatibility between the recipient and donor.
        - Discuss any potential risks associated with the blood group compatibility.

      2. **HLA Matching:**
        - Evaluate the degree of HLA match.
        - Discuss the implications for transplant success and the risk of graft-versus-host disease (GVHD).

      3. **Immunological Risk Assessment:**
        - Analyze the Single Antigen Bead (SAB) results.
        - Interpret the CREG scores, focusing on donor-specific antibodies and their potential impact.
        - Assess the overall immunological risk for the recipient.

      4. **Clinical Risk Stratification:**
        - Consider the recipient's and donor's medical histories, comorbidities, and any previous transfusions or transplants.
        - Identify any additional risk factors or contraindications that may affect the transplant outcome.

      5. **Diagnosis and Assessment:**
        - Provide a comprehensive diagnosis regarding the suitability of proceeding with the transplant.
        - Offer evidence-based recommendations for pre-transplant treatments or interventions.

      **Formatting Guidelines:**

      - Use professional medical language appropriate for a clinical audience.
      - Write in the third person and maintain an objective tone.
      - Organize the assessment under clear headings corresponding to each instruction.
      - Be concise but include all relevant details.
      - Do not include any personal identifiers beyond the provided codes.
      - Ensure compliance with all relevant privacy regulations (e.g., HIPAA).

      **Before finalizing the assessment, please ensure:**

      - All instructions have been followed.
      - The information is accurate and free of contradictions.
      - The assessment maintains a logical flow and clear structure.

      `;

  const generateDiagnosisPromptv1 = () => `
      Please analyze the following patient data and provide a detailed diagnosis and assessment.

      **Recipient Blood Group Details:**
      - ABO Group: ${recipient?.blood_group?.label || "N/A"}
      - Rh Factor: ${
        recipient?.blood_group?.label?.includes("+") ? "Positive" : "Negative"
      }

      **Donor Blood Group Details:**
      - ABO Group: ${donor?.blood_group?.label || "N/A"}
      - Rh Factor: ${
        donor?.blood_group?.label?.includes("+") ? "Positive" : "Negative"
      }

      **HLA Typing Match:**
      - Recipient HLA Alleles: ${JSON.stringify(
        recipientClinicals?.hlaTyping || {}
      )}
      - Donor HLA Alleles: ${JSON.stringify(donorClinicals?.hlaTyping || {})}

      **Single Antigen Bead (SAB) Match Details:**
      - Detected Antibodies: ${JSON.stringify(
        currentSABScore?.matchResults || {}
      )}
      - MFI Values: ${JSON.stringify(currentSABScore?.score || {})}

      **CREG Score:**
      - Score: ${JSON.stringify(cregAnalysisResults || {})}

      **Clinical Details of Recipient:**
      ${JSON.stringify(recipientClinicals || {})}

      **Clinical Details of Donor:**
      ${JSON.stringify(donorClinicals || {})}

      **Instructions:**
      - Assess blood group compatibility.
      - Evaluate HLA matching and its implications.
      - Analyze immunological risks based on SAB and CREG scores.
      - Consider clinical details to identify any additional risk factors.
      - Provide a diagnosis or assessment regarding the suitability of proceeding with the transplant.
      `;

  const generateClinicalReportPrompt = (diagnosisText) => `
You are a Clinical Transplant Specialist and an AI language model specializing in clinical report writing. Using the diagnosis and assessment provided below, generate a comprehensive clinical report suitable for inclusion in the patient's medical record.

**Recipient Code:** ${recipient?.recipient_code || "N/A"}
**Donor Code:** ${donor?.donor_code || "N/A"}

**Diagnosis and Assessment:**
${diagnosisText}

**Instructions for the Clinical Report:**

- **Title:** Include "Clinical Report" at the top of the document.

- **Executive Summary:**
  - Provide a brief overview of key findings and recommendations.
  - Limit this section to one or two paragraphs.

- **Table of Contents:** (if applicable)

- **Introduction:**
  - Briefly introduce the recipient and donor, including relevant background information.

- **Detailed Analyses:**
  - **1. Blood Group Compatibility:**
    - Discuss ABO and Rh compatibility.
    - Mention any potential risks associated with the compatibility.
  - **2. HLA Matching:**
    - Elaborate on the degree of HLA match.
    - Discuss implications for transplant success and risk of graft-versus-host disease (GVHD).
  - **3. Immunological Risk Assessment:**
    - Analyze Single Antigen Bead (SAB) results.
    - Interpret CREG scores.
    - Focus on donor-specific antibodies and their potential impact.
  - **4. Clinical Risk Stratification:**
    - Consider the recipient's and donor's medical histories.
    - Identify any additional risk factors or contraindications.
  - **5. Comparative Case Analysis:**
    - If available, provide brief summaries of similar successful transplant cases known up to your knowledge cutoff (October 2023).
    - Include key details such as the degree of HLA matching, immunological considerations, and outcomes.
    - Cite any relevant references or studies to support the analysis.
    - Ensure that all information is accurate and based on verifiable sources.
    - If no specific cases are known, acknowledge this and proceed without this section.

- **Conclusion:**
  - Summarize the key findings.
  - Provide a clear recommendation regarding the suitability of proceeding with the transplant.

- **Recommendations for the Clinical Team:**
  - Suggest any pre-transplant treatments.
  - Propose monitoring strategies or post-transplant care plans.

- **References:**
  - Include any relevant references or guidelines used in the analysis.
  - Ensure that all references are accurate and properly formatted.

- **Appendices:**
  - Add any supplementary information or detailed data tables (if applicable).

**Formatting Guidelines:**

- Use professional medical language appropriate for a clinical audience.
- Write in the third person.
- Ensure clarity and conciseness.
- Use headings and subheadings as specified.
- Bullet points or numbered lists can be used for clarity.
- Proofread the report for grammatical accuracy.
- Do not include any patient identifiers beyond the provided codes.
- Ensure compliance with all relevant privacy regulations (e.g., HIPAA).

**Note:**

- When including similar cases, do not fabricate any information. Only include cases that are well-documented and known up to your knowledge cutoff.
- If uncertain, it is acceptable to state that no specific cases are available.
- Maintain an objective and evidence-based approach in your analysis.

`;

  const generateClinicalReportPromptv1 = (diagnosisText) => `
      You are an AI language model specializing in clinical report writing. Using the diagnosis and assessment provided below, generate a comprehensive clinical report suitable for inclusion in the patient's medical record.
      
      **Recipient Code:** ${recipient?.recipient_code || "N/A"}
      **Donor Code:** ${donor?.donor_code || "N/A"}
      
      **Diagnosis and Assessment:**
      ${diagnosisText}
      
      **Instructions for the Clinical Report:**
      
      - **Title:** Include "Clinical Report" at the top of the document.
      - **Executive Summary:**
        - Provide a brief overview of key findings and recommendations.
        - Limit this section to one or two paragraphs.
      - **Table of Contents:** (if applicable)
      - **Introduction:**
        - Briefly introduce the recipient and donor, including relevant background information.
      - **Detailed Analyses:**
        - **1. Blood Group Compatibility:**
          - Discuss ABO and Rh compatibility.
          - Mention any potential risks associated with the compatibility.
        - **2. HLA Matching:**
          - Elaborate on the degree of HLA match.
          - Discuss implications for transplant success and risk of graft-versus-host disease (GVHD).
        - **3. Immunological Risk Assessment:**
          - Analyze Single Antigen Bead (SAB) results.
          - Interpret CREG scores.
          - Focus on donor-specific antibodies and their potential impact.
        - **4. Clinical Risk Stratification:**
          - Consider the recipient's and donor's medical histories.
          - Identify any additional risk factors or contraindications.
      - **Conclusion:**
        - Summarize the key findings.
        - Provide a clear recommendation regarding the suitability of proceeding with the transplant.
      - **Recommendations for the Clinical Team:**
        - Suggest any pre-transplant treatments.
        - Propose monitoring strategies or post-transplant care plans.
      - **References:**
        - Include any relevant references or guidelines used in the analysis (if necessary).
      - **Appendices:**
        - Add any supplementary information or detailed data tables (if applicable).
      
      **Formatting Guidelines:**
      
      - Use professional medical language appropriate for a clinical audience.
      - Write in the third person.
      - Ensure clarity and conciseness.
      - Use headings and subheadings as specified.
      - Bullet points or numbered lists can be used for clarity.
      - Proofread the report for grammatical accuracy.
      
      **Note:** Ensure that all patient identifiers are kept confidential and comply with HIPAA regulations.
      
      `;

  const aiRun = async () => {
    setStage("diagnosing");
    setError(null);
    setLocalAiResponse("");
    if (setAiAnalysisData) {
      setAiAnalysisData((prev) => ({
        ...prev,
        [tabId]: { ...prev[tabId], aiResponse: "" },
      }));
    }
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });

    try {
      console.log("Starting diagnosis generation...");
      const diagnosisPrompt = generateDiagnosisPrompt();
      console.log("Diagnosis Prompt:", diagnosisPrompt);

      let diagnosisResult;
      try {
        diagnosisResult = await model.generateContent(diagnosisPrompt);
      } catch (generateError) {
        console.error("Error generating diagnosis content:", generateError);
        throw new Error(
          `Failed to generate diagnosis: ${generateError.message}`
        );
      }

      console.log(
        "Raw Diagnosis Result:",
        JSON.stringify(diagnosisResult, null, 2)
      );

      if (!diagnosisResult) {
        throw new Error("Diagnosis result is null or undefined");
      }

      if (!diagnosisResult.response) {
        throw new Error("No response in diagnosis result");
      }

      if (
        !diagnosisResult.response.candidates ||
        diagnosisResult.response.candidates.length === 0
      ) {
        console.error("Full diagnosis result:", diagnosisResult);
        throw new Error(
          "No candidates in diagnosis result. Check console for full response."
        );
      }

      const diagnosisText =
        diagnosisResult.response.candidates[0].content.parts[0].text;
      if (!diagnosisText) {
        throw new Error("Empty diagnosis text received from the AI model");
      }

      console.log("Extracted Diagnosis Text:", diagnosisText);
      setDiagnosis(diagnosisText);

      setStage("generating");
      console.log("Starting clinical report generation...");
      const reportPrompt = generateClinicalReportPrompt(diagnosisText);
      console.log("Report Prompt:", reportPrompt);
      const reportResult = await model.generateContent(reportPrompt);
      console.log("Report Result:", reportResult);

      // Extract the report text with error checking
      if (
        !reportResult ||
        !reportResult.response ||
        !reportResult.response.candidates ||
        reportResult.response.candidates.length === 0
      ) {
        throw new Error("No valid report result received from the AI model");
      }
      const reportText =
        reportResult.response.candidates[0].content.parts[0].text;
      if (!reportText) {
        throw new Error("Empty report text received from the AI model");
      }
      console.log("Raw Report Text:", reportText);

      // Remove all instances of recipient and donor codes from the report
      const cleanedReportText = reportText
        .replace(/Recipient Code:.*\n?/g, "")
        .replace(/Donor Code:.*\n?/g, "")
        .trim();

      // Combine codes and report
      const reportWithCodes = `Recipient Code: ${
        recipient?.recipient_code || "N/A"
      }
Donor Code: ${donor?.donor_code || "N/A"}

${cleanedReportText}`;
      console.log("Final report with codes:", reportWithCodes);

      setLocalAiResponse(reportWithCodes);
      if (setAiAnalysisData) {
        setAiAnalysisData((prev) => ({
          ...prev,
          [tabId]: { showResponse: true, aiResponse: reportWithCodes },
        }));
      }

      setStage("complete");
      console.log("AI run completed successfully");

      // Save report to history
      const historyKey = `aiAnalysisHistory_${recipient.recipient_code}_${donor.donor_code}`;
      const history = JSON.parse(localStorage.getItem(historyKey)) || [];
      const newReport = {
        timestamp: new Date().toISOString(),
        report: reportWithCodes,
        recipientCode: recipient.recipient_code,
        donorCode: donor.donor_code,
      };
      history.push(newReport);
      localStorage.setItem(historyKey, JSON.stringify(history));
    } catch (error) {
      console.error("Error in AI run:", error);
      setError(`Failed to generate report: ${error.message}`);
      setStage("idle");
      toast.error(`Error: ${error.message}`);
    }
  };

  function calculateAge(dob) {
    // Parse the date of birth string into a Date object
    const birthDate = new Date(dob);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age if birthday hasn't occurred this year
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const handleGenerateGemini = () => {
    if (!recipient || !donor || !recipientClinicals || !donorClinicals) {
      toast.error(
        "Missing required data. Please ensure all information is available."
      );
      return;
    }

    if (!currentHLAScore || !currentSABScore || !cregAnalysisResults) {
      toast.error(
        "Analysis scores are not yet calculated. Please wait and try again."
      );
      return;
    }

    aiRun();
  };

  return (
    <div className="container p-1 mx-auto">
      <div className="flex items-center justify-end mb-4 space-x-4">
        <button
          onClick={() => setShowHistoryModal(true)}
          className="flex items-center px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
        >
          <FaHistory className="mr-2" /> View Report History
        </button>
        <button
          onClick={aiRun}
          className="flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 disabled:bg-blue-300"
          disabled={stage === "diagnosing" || stage === "generating"}
        >
          <FaRobot className="mr-2" />
          {stage === "idle" || stage === "complete"
            ? "Generate AI Analysis"
            : "Generating..."}
        </button>
      </div>

      {(stage === "diagnosing" || stage === "generating") && (
        <div className="flex flex-col items-center justify-center p-4">
          <div className="w-12 h-12 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
          <p className="mt-2 text-lg font-semibold">
            {stage === "diagnosing"
              ? "Analyzing data..."
              : "Generating clinical report..."}
          </p>
        </div>
      )}

      {localAiResponse && (
        <div className="p-8 mb-8 bg-gray-100 rounded-lg">
          <ReactMarkdown className="prose prose-lg">
            {localAiResponse}
          </ReactMarkdown>
        </div>
      )}

      {error && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 rounded-lg">
          {error}
        </div>
      )}
    </div>
  );
};

export default GeminiMatchDonors;
