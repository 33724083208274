import React from "react";
import Select from "react-select";

import { DatePicker } from "antd";
import dayjs from "dayjs";

const Crossmatch = ({
  handleChange,
  formData,
  setFormData,
  handleDateChange,
  postiveNegativeOptions,
  dropDownChange,
  placeHolderText,
  style,
}) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">
          Tissue Crossmatch (Luminex)
        </h2>

        <h3 className="mb-4 font-semibold text-md">
          Circulating Antigen Complex (CAC)
        </h3>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="cac_crossmatch_method"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Method
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="cac_crossmatch_method"
              name="cac_crossmatch_method"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.cac_crossmatch_method}
            />
          </div>
          <div>
            <label
              htmlFor="cac_crossmatch_result"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Result
            </label>
            {/* Generate input using DatePicker */}
            <Select
              name="cac_crossmatch_result"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.cac_crossmatch_result &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.cac_crossmatch_result
                )
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="cac_crossmatch_dateOfTest"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Date of Test
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="cac_crossmatch_dateOfTest"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  cac_crossmatch_dateOfTest: dateString,
                });
              }}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.cac_crossmatch_dateOfTest &&
                dayjs(formData?.cac_crossmatch_dateOfTest, "YYYY-MM-DD")
              }
            />
          </div>
          <div>
            <label
              htmlFor="cac_crossmatch_interpretation"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Interpretation
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="cac_crossmatch_interpretation"
              name="cac_crossmatch_interpretation"
              virtualCrossmatch_dateOfTest
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.cac_crossmatch_interpretation}
              cac_crossmatch_interpretation
            />
          </div>

          <div>
            <label
              htmlFor="cac_crossmatch_performedBy"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Performed By
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="cac_crossmatch_performedBy"
              name="cac_crossmatch_performedBy"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.cac_crossmatch_performedBy}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div>
          <label
            htmlFor="cac_crossmatch_notes"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Notes
          </label>
          <textarea
            id="cac_crossmatch_notes"
            name="cac_crossmatch_notes"
            rows="4"
            className="w-full p-2 border rounded-md"
            onChange={handleChange}
            value={formData?.cac_crossmatch_notes || ""}
          />
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="mb-6">
        {/* <h2 className="mb-4 text-xl font-semibold">Crossmatch (Physical)</h2>
        <br /> */}
        <h3 className="mb-4 font-semibold text-md">Flow Cytometry</h3>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="flow_cytometry_method"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Method
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="flow_cytometry_method"
              name="flow_cytometry_method"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.flow_cytometry_method}
            />
          </div>
          <div>
            <label
              htmlFor="flow_cytometry_result"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              CD4 Count
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="number"
              id="flow_cytometry_cd4_count"
              name="flow_cytometry_cd4_count"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.flow_cytometry_cd4_count}
            />
          </div>
          <div>
            <label
              htmlFor="flow_cytometry_cd8_count"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              CD8 Count
            </label>
            <input
              type="number"
              id="flow_cytometry_cd8_count"
              name="flow_cytometry_cd8_count"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.flow_cytometry_cd8_count}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="flow_cytometry_dateOfTest"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Date of Test
            </label>
            {/* Generate input using DatePicker */}

            <DatePicker
              name="flow_cytometry_dateOfTest"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  flow_cytometry_dateOfTest: dateString,
                });
              }}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.flow_cytometry_dateOfTest &&
                dayjs(formData?.flow_cytometry_dateOfTest, "YYYY-MM-DD")
              }
            />
          </div>
          <div>
            <label
              htmlFor="flow_cytometry_interpretation"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Interpretation
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="flow_cytometry_interpretation"
              name="flow_cytometry_interpretation"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.flow_cytometry_interpretation}
            />
          </div>

          <div>
            <label
              htmlFor="flow_cytometry_performedBy"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Performed By
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="flow_cytometry_performedBy"
              name="flow_cytometry_performedBy"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.flow_cytometry_performedBy}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
          <div>
            <label
              htmlFor="flow_cytometry_notes"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Notes
            </label>
            <textarea
              id="flow_cytometry_notes"
              name="flow_cytometry_notes"
              rows="4"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.flow_cytometry_notes || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crossmatch;
