import React, { useState, useEffect } from "react";

const ViewModal = ({ currentRecord }) => {
  const jsonData = currentRecord;
  const [countryFlag, setCountryFlag] = useState("");

  const relevantKeys = [
    "activity",
    "page",
    "page_route",
    "ip_information",
    "createdAt",
    "user",
    "access_level",
  ];

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const formatValue = (key, value) => {
    if (key === "createdAt") {
      return new Date(value).toLocaleString();
    }
    if (key === "ip_information") {
      return (
        <div className="flex items-center">
          {value}
          {countryFlag && (
            <img
              src={countryFlag}
              alt="Country flag"
              className="w-6 h-4 ml-2"
            />
          )}
        </div>
      );
    }
    return value || "N/A";
  };

  const getCountryFlag = async (ip) => {
    try {
      // Extract the IP address if it's in the ::ffff: format
      const cleanIp = ip.replace(/^::ffff:/, "");

      // Get country code from IP
      // const geoResponse = await fetch(`https://ipapi.co/${cleanIp}/json/`);
      // const geoResponse = await fetch(`https://ip-api.com/json/${cleanIp}`);
      const geoResponse = await fetch(`https://api.country.is/${cleanIp}`);
      const geoData = await geoResponse.json();
      const countryCode = geoData.country;
      console.log(geoData);
      // Get flag URL
      const flagUrl = `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
      setCountryFlag(flagUrl);
    } catch (error) {
      console.error("Error fetching country flag:", error);
    }
  };

  useEffect(() => {
    if (jsonData.ip_information) {
      getCountryFlag(jsonData.ip_information);
    }
  }, [jsonData.ip_information]);

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-6 text-2xl font-semibold text-center text-blue-600">
        Activity Log Details
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead className="bg-blue-100">
            <tr>
              <th className="px-4 py-2 text-left text-blue-700">Field</th>
              <th className="px-4 py-2 text-left text-blue-700">Value</th>
            </tr>
          </thead>
          <tbody>
            {relevantKeys.map((key, index) => (
              <tr
                key={key}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="px-4 py-3 font-semibold text-gray-700">
                  {formatKey(key)}
                </td>
                <td className="px-4 py-3 text-gray-600">
                  {formatValue(key, jsonData[key])}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewModal;
