import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import ViewModal from "./View/ViewModal";

const ActivityLogChild = () => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/alog/`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data.audit_logs;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            user: data?.user_id?.first_name + " " + data?.user_id?.last_name,
            access_level: data?.user_id?.user_type,
            activity: data?.activity,
            ip_information: data?.ip_information,
          };
        });
        //  console.log(updatedData);
        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleViewModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 180,
    },
    {
      field: "page",
      headerName: "Component",
      width: 180,
    },
    {
      field: "page_route",
      headerName: "Route",
      width: 160,
    },
    {
      field: "user",
      headerName: "User",
      width: 160,
    },
    {
      field: "access_level",
      headerName: "Access Level",
      width: 160,
    },
    {
      field: "ip_information",
      headerName: "IP Info",
      width: 200,
      //   renderCell: (params) => params.row.ip_information.replace(/^::ffff:/, ""),
      renderCell: (params) => (
        <CountryFlagComponent ip={params.row.ip_information} />
      ),
    },
  ];

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <h2>Activity Logs</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showViewModal && (
          <Modal onClose={toggleViewModal}>
            <ViewModal currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>

      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Activity Logs"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default ActivityLogChild;

const CountryFlagComponent = ({ ip }) => {
  const [countryFlag, setCountryFlag] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const getCountryFlag = async (ip) => {
    try {
      // Extract the IP address if it's in the ::ffff: format
      const cleanIp = ip.replace(/^::ffff:/, "");

      // Special handling for localhost IP
      if (cleanIp === "::1" || cleanIp === "127.0.0.1") {
        setCountryCode("local");
        return;
      }

      // Get country code from IP
      const geoResponse = await fetch(`https://api.country.is/${cleanIp}`);
      const geoData = await geoResponse.json();
      const countryCode = geoData?.country;

      // Get flag URL
      const flagUrl = `https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`;
      setCountryFlag(flagUrl);
    } catch (error) {
      console.error("Error fetching country flag:", error);
    }
  };

  useEffect(() => {
    getCountryFlag(ip);
  }, [ip]);

  useEffect(() => {
    if (countryCode) {
      if (countryCode === "local") {
        setCountryFlag("🏠"); // Home emoji for localhost
      } else {
        setCountryFlag(
          `https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`
        );
      }
    }
  }, [countryCode]);

  return (
    <div className="flex items-center">
      {ip.replace(/^::ffff:/, "")}
      {countryFlag &&
        (countryFlag === "🏠" ? (
          <span className="ml-2" title="Localhost">
            {countryFlag}
          </span>
        ) : (
          <img
            src={countryFlag}
            alt="Country flag"
            className="w-5 h-3 ml-2"
            title={`IP: ${ip}`}
          />
        ))}
    </div>
  );
};
