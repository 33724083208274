import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import {
  postiveNegativeOptions,
  yesNoOptions,
  urineOptionsPerDay,
  btReasonOptions,
  transfusionTypeOptions,
  pregnancyOutcomeOptions,
  pregnancyStatusOptions,
  transplantOrganOptions,
  transplantReasonOptions,
  transplantOutcomeOptions,
  hivStatusTreatmentOptions,
  genotypeOptions,
  fibrosisStageOptions,
  treatmentRegimenOptions,
  antiHCVstatusOptions,
  basicDiseaseOptions,
  dialysisVintageOptions,
  hlaAntigenOptions,
  HbsAgStatusOptions,
} from "../../../components/Data/dropdownoptions";
import { generateRandomFormData } from "../../../components/Data/fakedatagenerator";
import RecipientNameComponent from "../RecipientNameComponent";

import GeneralProfile from "./GeneralProfile";
import RenalProfile from "./RenalProfile";
import LiverProfile from "./LiverProfile";
import HLAProfile from "./HLAProfile";
import Compatibility from "./Compatibility";
import Crossmatch from "./Crossmatch";
import MedicalHistory from "./MedicalHistory";
import Sensitisation from "./Sensitisation";
import HLAAntibodyForm from "./HLAAntibodyForm";

const AddModal = ({
  currentRecord,
  setShowAddModal,
  setShowClinicalModal,
  getClinicalDataFromServer,
}) => {
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [activeTab, setActiveTab] = useState("profile_general");

  const [formData, setFormData] = useState({
    recipient_id: currentRecord?._id, //Object
    date_of_entry: "", //date
    weight: "", //Number
    // Blood Pressure
    blood_pressure_systolic: "", //Number
    blood_pressure_diastolic: "", //Number
    // CBC
    cbc_rbc: "", //Number
    cbc_pcv: "", //Number
    cbc_wbc: "", //Number
    cbc_platelets: "", //Number
    cbc_hemoglobin: "", //Number
    cbc_esr: "", //Number
    // Renal Profile
    renal_profile_eGFR: "", //Number
    renal_profile_creatinine: "", //Number
    renal_profile_albumin: "", //Number
    renal_profile_calcium: "", //Number
    renal_profile_carbon_dioxide: "", //Number
    renal_profile_glucose: "", //Number
    renal_profile_potassium: "", //Number
    renal_profile_sodium: "", //Number
    renal_profile_phosphorus: "", //Number
    renal_profile_BUN: "", //Number
    renal_profile_hba1c: "", //Number
    renal_profile_residual_renal_function: "",
    renal_profile_urine_output_per_day: "",
    //Liver function Test
    sgot_patient: "", //Number
    sgpt_patient: "", //Number
    tsh_patient: "", //Number
    aptt_patient: "", //Number
    pt_patient: "", //Number
    inr_patient: "", //Number
    hbsag_patient: "", //Number
    cmv_igg_patient: "", //Number
    cmv_igm_patient: "", //Number
    ebv_igg_patient: "", //Number
    ebv_igm_patient: "", //Number
    urine_cs: "", //String
    // Urine Analysis
    urine_analysis_ph: "", //Number
    urine_analysis_protein: "", //Number
    urine_analysis_protein_quantity: "", //Number
    recent_infections: "",
    //HL Typing
    hlatyping_a_allele_1: "",
    hlatyping_a_allele_2: "",
    hlatyping_b_allele_1: "",
    hlatyping_b_allele_2: "",
    hlatyping_c_allele_1: "",
    hlatyping_c_allele_2: "",
    hlatyping_drb1_allele_1: "",
    hlatyping_drb1_allele_2: "",
    hlatyping_dqb1_allele_1: "",
    hlatyping_dqb1_allele_2: "",
    hlatyping_dpb1_allele_1: "",
    hlatyping_dpb1_allele_2: "",
    dtpa_gfr_left_kidney: "",
    dtpa_gfr_left_kidney_findings: "",
    dtpa_gfr_right_kidney: "",
    dtpa_gfr_right_kidney_findings: "",
    //HLA Antibody profile
    hlaAntibodyProfile_antigen: "",
    hlaAntibodyProfile_mfi: "",
    hlaAntibodyProfile_specificity: "",
    hlaAntibodyProfile_antigen_1: "",
    hlaAntibodyProfile_mfi_1: "",
    hlaAntibodyProfile_specificity_1: "",
    hlaAntibody: [],
    //organSize
    organSize_recipientBodySize: "", //Number
    organSize_sizeCompatibility: "", //String
    //preExistingConditions
    preExistingConditions_condition: "", //String
    preExistingConditions_status: "", //String
    preExistingConditions_notes: "", //String
    //crossmatchResults
    //cacResults
    cac_crossmatch_method: "", //String
    cac_crossmatch_result: "", //String
    cac_crossmatch_interpretation: "", //String
    cac_crossmatch_dateOfTest: "", //Date
    cac_crossmatch_performedBy: "", //String
    cac_crossmatch_notes: "", //String
    //flowCytometryResults
    flow_cytometry_method: "", //String
    flow_cytometry_cd4_count: "", //Number
    flow_cytometry_cd8_count: "", //Number
    flow_cytometry_interpretation: "", //String
    flow_cytometry_dateOfTest: "", //Date
    flow_cytometry_performedBy: "", //String
    flow_cytometry_notes: "", //String
    //Sensitisation
    pra_class_I: "",
    pra_class_II: "",
    bloodTransfusion_date: "", // New Addition
    bloodTransfusion_reason: "",
    bloodTransfusion_type: "",
    bloodTransfusion_notes: "",
    pregnancyHistory_date_of_delivery: "",
    pregnancyHistory_number_of_pregnancies: "",
    pregnancyHistory_status: "",
    pregnancyHistory_due_date: "",
    pregnancyHistory_outcome: "",
    pregnancyHistory_notes: "",
    transplantHistory_organ: "",
    transplantHistory_date: "",
    transplantHistory_graft_failure_reason: "",
    transplantHistory_dialysis_vintage: "",
    transplantHistory_notes: "",
    //General HIV AntiHCV
    hivStatus_hiv_status: "",
    hivStatus_hiv_viral_load: "",
    hivStatus_treatment_status: "",
    hivStatus_notes: "",
    antiHCVStatus_anti_hcv_status: "",
    antiHCVStatus_hcv_viral_load: "",
    antiHCVStatus_genotype: "",
    antiHCVStatus_alt_levels: "",
    antiHCVStatus_fibrosis_stage: "",
    antiHCVStatus_treatment_status: "",
    antiHCVStatus_treatment_regimen: "",
    antiHCVStatus_svr12_status: "",
    antiHCVStatus_svr24_status: "",
    antiHCVStatus_notes: "",
    //Chronic Kidney Disease
    ckd_exists: "",
    ckd_duration: "",
    ckd_basic_disease: "",
    //Coronary Artery Disease
    cad_exists: "",
    cad_duration: "",
    cad_basic_disease: "",
    //Chronic Tissue disease
    ctd_exists: "",
    ctd_duration: "",
    ctd_basic_disease: "",
    //Autosomal Dominant Polycystic Kidney Disease
    adpkd_exists: "",
    adpkd_duration: "",
    adpkd_basic_disease: "",
    //Hypertension
    hypertension_exists: "",
    hypertension_duration: "",
    //Renal calculus discharge
    rcd_exists: "",
    rcd_duration: "",
    //Vascular disease
    vasculetion_exists: "",
    vasculetion_specify: "",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const tabs = [
    { id: "profile_general", label: "General Profile" },
    { id: "profile_medhistory", label: "Medical History" },
    { id: "profile_renal", label: "Renal Profile" },
    { id: "profile_liver", label: "Liver Profile" },
    { id: "profile_hla", label: "HLA Profile" },
    { id: "profile_sab", label: "SAB Assay" },
    { id: "profile_sensitisation", label: "Sensitisation" },
    { id: "profile_crossmatch", label: "Crossmatch" },
  ];

  const TabButton = ({ id, label }) => (
    <button
      className={`px-3 py-2 text-sm font-medium rounded-t-lg transition duration-300 ${
        activeTab === id
          ? "bg-orange-500 text-white"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
      onClick={() => setActiveTab(id)}
    >
      {label}
    </button>
  );

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const handleDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      date_of_entry: dateString,
    });
  };

  const handleBTDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      bloodTransfusion_date: dateString,
    });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");

    if (!formData?.weight)
      return toast.error("Kindly input the weight of the patient");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/clinical/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Clinical Record Created Successfully.");
        //getDataFromServer();
        setShowAddModal(false);
        setShowClinicalModal(false);
        getClinicalDataFromServer();
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "AddModal", "/clinical/add");
      });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleRandomClinicalData = () => {
    const clinical_random = generateRandomFormData();
    setFormData({ ...formData, ...clinical_random });
  };

  return (
    <div className="relative p-6">
      <RecipientNameComponent currentRecord={currentRecord} />

      <div className="w-full mb-2 text-end">
        <button
          onClick={handleAddSubmit}
          className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
        >
          Add Clinical Record
        </button>
      </div>
      <br />
      <div className="mb-4">
        {/* Desktop tabs */}
        <div className="hidden mb-4 overflow-x-auto md:block">
          <div className="flex flex-wrap gap-2">
            {tabs.map((tab) => (
              <TabButton key={tab.id} id={tab.id} label={tab.label} />
            ))}
          </div>
        </div>

        {/* Mobile dropdown */}
        <div className="md:hidden">
          <Menu as="div" className="relative w-full">
            <Menu.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-md hover:bg-orange-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {tabs.find((tab) => tab.id === activeTab)?.label || "Select Tab"}
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-white"
                aria-hidden="true"
              />
            </Menu.Button>
            <Menu.Items className="absolute left-0 z-50 w-full mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {tabs.map((tab) => (
                  <Menu.Item key={tab.id}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-orange-500 text-white" : "text-gray-900"
                        } block w-full px-4 py-2 text-left text-sm`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>

      {activeTab === "profile_general" && (
        <GeneralProfile
          handleDateChange={handleDateChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          hivStatusTreatmentOptions={hivStatusTreatmentOptions}
          genotypeOptions={genotypeOptions}
          fibrosisStageOptions={fibrosisStageOptions}
          treatmentRegimenOptions={treatmentRegimenOptions}
          antiHCVstatusOptions={antiHCVstatusOptions}
          basicDiseaseOptions={basicDiseaseOptions}
          handleChange={handleChange}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          setFormData={setFormData}
        />
      )}
      {activeTab === "profile_medhistory" && (
        <MedicalHistory
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          HbsAgStatusOptions={HbsAgStatusOptions}
          hivStatusTreatmentOptions={hivStatusTreatmentOptions}
          genotypeOptions={genotypeOptions}
          fibrosisStageOptions={fibrosisStageOptions}
          treatmentRegimenOptions={treatmentRegimenOptions}
          antiHCVstatusOptions={antiHCVstatusOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      {activeTab === "profile_renal" && (
        <RenalProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          yesNoOptions={yesNoOptions}
          urineOptionsPerDay={urineOptionsPerDay}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      {activeTab === "profile_liver" && (
        <LiverProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          HbsAgStatusOptions={HbsAgStatusOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      {activeTab === "profile_hla" && (
        <HLAProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          hlaAntigenOptions={hlaAntigenOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          setFormData={setFormData}
        />
      )}
      {activeTab === "profile_sab" && (
        <HLAAntibodyForm
          formData={formData}
          setFormData={setFormData}
          placeHolderText={placeHolderText}
          style={style}
          hlaAntigenOptions={hlaAntigenOptions}
          postiveNegativeOptions={postiveNegativeOptions}
        />
      )}
      {activeTab === "profile_sensitisation" && (
        <Sensitisation
          handleChange={handleChange}
          handleBTDateChange={handleBTDateChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          transfusionTypeOptions={transfusionTypeOptions}
          btReasonOptions={btReasonOptions}
          pregnancyOutcomeOptions={pregnancyOutcomeOptions}
          pregnancyStatusOptions={pregnancyStatusOptions}
          transplantOrganOptions={transplantOrganOptions}
          transplantReasonOptions={transplantReasonOptions}
          transplantOutcomeOptions={transplantOutcomeOptions}
          dialysisVintageOptions={dialysisVintageOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          handleDateChange={handleDateChange}
          style={style}
          setFormData={setFormData}
        />
      )}
      {activeTab === "profile_crossmatch" && (
        <Crossmatch
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          handleDateChange={handleDateChange}
          style={style}
          setFormData={setFormData}
        />
      )}

      <div className="mt-4 text-right">
        <button
          onClick={handleAddSubmit}
          className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
        >
          Add Clinical Record
        </button>
      </div>
    </div>
  );
};

export default AddModal;
