import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const AddRoles = ({ getDataFromServer, setShowModal }) => {
  let navigate = useNavigate();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    role_name: null,
    access_level: null,
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const permissionOptions = [
    { value: "ADMIN", label: "Administrator" },
    { value: "CONSULTANT", label: "Consultant" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleRoleRegistration = (e) => {
    e.preventDefault();

    if (!formData.access_level) {
      toast.error("Select Access Level");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/roles/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Roles Created Successfully.");
        getDataFromServer();
        setShowModal(false);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateRoleChild", "/roles/add");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center">
      <div>
        {/* <h2 className="mb-3 text-bold">Add Roles</h2> */}
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleRoleRegistration(e)} autoComplete="off">
          <div className="">
            <label htmlFor="role_name">Role name</label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="role_name"
              type="text"
              required
              placeholder="Enter Role Name"
              autoComplete="new-password"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 ">
            <label htmlFor="access_level">Access Level</label>
            <Select
              name="access_level"
              onChange={dropDownChange}
              options={permissionOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select Roles")}
              styles={style}
              value={
                formData?.access_level &&
                permissionOptions?.find(
                  (option) => option.value === formData?.access_level
                )
              }
              menuPosition={"fixed"}
            />
          </div>
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Add Role
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRoles;
