import React from "react";
import { useLocation } from "react-router-dom";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import CrossMatchDonorsListChild from "./CrossMatchDonorsListChild";

const CrossMatchDonorsList = () => {
  const location = useLocation();
  const recipientRecord = location.state;
  return (
    <MainTemplate>
      <CardBackground>
        <CrossMatchDonorsListChild recipientRecord={recipientRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default CrossMatchDonorsList;
