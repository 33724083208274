import React from "react";
import Select from "react-select";

import { DatePicker } from "antd";
import dayjs from "dayjs";

const Compatibility = ({
  handleChange,
  formData,
  setFormData,
  handleDateChange,
  postiveNegativeOptions,
  dropDownChange,
  placeHolderText,
  style,
}) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Organ Size</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="organSize_donorOrganSize"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Donor Organ Size
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="number"
              id="organSize_donorOrganSize"
              name="organSize_donorOrganSize"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.organSize_donorOrganSize}
            />
          </div>
          <div>
            <label
              htmlFor="organSize_sizeCompatibility"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Size Compatibility
            </label>
            <input
              type="text"
              id="organSize_sizeCompatibility"
              name="organSize_sizeCompatibility"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.organSize_sizeCompatibility}
            />
          </div>
        </div>
      </div>
      <hr />
      <br />
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Crossmatch (Virtual)</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="virtualCrossmatch_method"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Method
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="virtualCrossmatch_method"
              name="virtualCrossmatch_method"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.virtualCrossmatch_method}
            />
          </div>
          <div>
            <label
              htmlFor="virtualCrossmatch_result"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Result
            </label>
            {/* Generate input using DatePicker */}
            <Select
              name="virtualCrossmatch_result"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.virtualCrossmatch_result &&
                postiveNegativeOptions?.find(
                  (option) =>
                    option.value === formData?.virtualCrossmatch_result
                )
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="virtualCrossmatch_dateOfTest"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Date of Test
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="virtualCrossmatch_dateOfTest"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  virtualCrossmatch_dateOfTest: dateString,
                });
              }}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.virtualCrossmatch_dateOfTest &&
                dayjs(formData?.virtualCrossmatch_dateOfTest, "YYYY-MM-DD")
              }
            />
          </div>
          <div>
            <label
              htmlFor="virtualCrossmatch_interpretation"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Interpretation
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="virtualCrossmatch_interpretation"
              name="virtualCrossmatch_interpretation"
              virtualCrossmatch_dateOfTest
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.virtualCrossmatch_interpretation}
              virtualCrossmatch_performedBy
            />
          </div>

          <div>
            <label
              htmlFor="virtualCrossmatch_performedBy"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Performed By
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="virtualCrossmatch_performedBy"
              name="virtualCrossmatch_performedBy"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.virtualCrossmatch_performedBy}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div>
          <label
            htmlFor="virtualCrossmatch_notes"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Notes
          </label>
          <textarea
            id="virtualCrossmatch_notes"
            name="virtualCrossmatch_notes"
            rows="4"
            className="w-full p-2 border rounded-md"
            onChange={handleChange}
            value={formData?.virtualCrossmatch_notes || ""}
          />
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Crossmatch (Physical)</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="physicalCrossmatch_method"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Method
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="physicalCrossmatch_method"
              name="physicalCrossmatch_method"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.physicalCrossmatch_method}
            />
          </div>
          <div>
            <label
              htmlFor="physicalCrossmatch_result"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Result
            </label>
            {/* Generate input using DatePicker */}
            <Select
              name="physicalCrossmatch_result"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.physicalCrossmatch_result &&
                postiveNegativeOptions?.find(
                  (option) =>
                    option.value === formData?.physicalCrossmatch_result
                )
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="physicalCrossmatch_dateOfTest"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Date of Test
            </label>
            {/* Generate input using DatePicker */}

            <DatePicker
              name="physicalCrossmatch_dateOfTest"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  physicalCrossmatch_dateOfTest: dateString,
                });
              }}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.physicalCrossmatch_dateOfTest &&
                dayjs(formData?.physicalCrossmatch_dateOfTest, "YYYY-MM-DD")
              }
            />
          </div>
          <div>
            <label
              htmlFor="physicalCrossmatch_interpretation"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Interpretation
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="physicalCrossmatch_interpretation"
              name="physicalCrossmatch_interpretation"
              virtualCrossmatch_dateOfTest
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.physicalCrossmatch_interpretation}
            />
          </div>

          <div>
            <label
              htmlFor="physicalCrossmatch_performedBy"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Performed By
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="physicalCrossmatch_performedBy"
              name="physicalCrossmatch_performedBy"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.physicalCrossmatch_performedBy}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
          <div>
            <label
              htmlFor="physicalCrossmatch_notes"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Notes
            </label>
            <textarea
              id="physicalCrossmatch_notes"
              name="physicalCrossmatch_notes"
              rows="4"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.physicalCrossmatch_notes || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compatibility;
