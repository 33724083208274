import React, { useState, useEffect } from "react";
import Select from "react-select";

import ExcelImport from "./ExcelImport";

const HLAAntibodyForm = ({
  formData,
  setFormData,
  placeHolderText,
  style,
  hlaAntigenOptions,
  postiveNegativeOptions,
}) => {
  const [newAntibody, setNewAntibody] = useState({
    class: "",
    hlatype: "",
    antigen: "",
    MFI: "",
    status: "",
  });

  const [hlaTypesOptions, setHlaTypesOptions] = useState();
  const [antigenOptions, setAntigenOptions] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAntibody((prev) => ({ ...prev, [name]: value }));
  };

  const addAntibody = () => {
    setFormData((prevData) => ({
      ...prevData,
      hlaAntibody: [...prevData.hlaAntibody, newAntibody],
    }));
    setNewAntibody({
      class: "",
      hlatype: "",
      antigen: "",
      MFI: "",
      status: "",
    });
  };

  const removeAntibody = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      hlaAntibody: prevData.hlaAntibody.filter((_, i) => i !== index),
    }));
  };

  const classOptions = [
    { value: "Class I", label: "Class I" },
    { value: "Class II", label: "Class II" },
  ];

  const hlaTypeOptions = {
    "Class I": [
      { value: "HLA-A", label: "HLA-A" },
      { value: "HLA-B", label: "HLA-B" },
      { value: "HLA-C", label: "HLA-C" },
    ],
    "Class II": [
      { value: "HLA-DR", label: "HLA-DR" },
      { value: "HLA-DQ", label: "HLA-DQ" },
      { value: "HLA-DP", label: "HLA-DP" },
    ],
  };

  useEffect(() => {
    setHlaTypesOptions(hlaTypeOptions[newAntibody.class]);
  }, [newAntibody.class]);

  useEffect(() => {
    setAntigenOptions(hlaAntigenOptions[newAntibody.hlatype]);
  }, [newAntibody.hlatype]);

  const handleDataImported = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      hlaAntibody: [...prevData.hlaAntibody, ...data],
    }));
  };

  return (
    <div>
      <h2 className="mb-4 text-xl font-semibold">Single Antigen Bead Assay</h2>
      <div className="p-4 mb-6 rounded-lg shadow-sm bg-gray-50">
        <h3 className="mb-2 text-lg font-medium text-gray-700">Import Data</h3>
        <ExcelImport onDataImported={handleDataImported} />
      </div>

      {formData.hlaAntibody.map((antibody, index) => (
        <div key={index}>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-6">
            <div>
              <label
                htmlFor="class"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Class
              </label>
              <input
                type="text"
                id="class"
                name="class"
                placeholder="Class"
                className="w-full p-2 border rounded-md"
                value={antibody.class}
                disabled
              />
            </div>
            <div>
              <label
                htmlFor="hlatype"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA Type
              </label>
              <input
                type="text"
                id="hlatype"
                name="hlatype"
                placeholder="HLA Type"
                className="w-full p-2 border rounded-md"
                value={antibody.hlatype}
                disabled
              />
            </div>
            <div>
              <label
                htmlFor="antigen"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Antigen
              </label>
              <input
                type="text"
                id="antigen"
                name="antigen"
                placeholder="Antigen"
                className="w-full p-2 border rounded-md"
                value={antibody.antigen}
                disabled
              />
            </div>

            <div>
              <label
                htmlFor="status"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Status
              </label>
              <input
                type="text"
                id="status"
                name="status"
                placeholder="Status"
                className="w-full p-2 border rounded-md"
                onChange={handleInputChange}
                value={antibody.status}
                disabled
              />
            </div>
            <div>
              <label
                htmlFor="MFI"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                MFI
              </label>
              <input
                type="number"
                id="MFI"
                name="MFI"
                placeholder="MFI"
                className="w-full p-2 border rounded-md"
                value={antibody.MFI}
                disabled
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                onClick={() => removeAntibody(index)}
                className="h-12 px-4 py-0 mt-5 text-white transition duration-300 bg-gray-500 rounded-md hover:bg-gray-600"
              >
                <i className="mdi mdi-minus-circle"></i>
              </button>
            </div>
          </div>
          <br />
        </div>
      ))}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-6">
        <div>
          <label
            htmlFor="class"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Class
          </label>
          <Select
            name="class"
            onChange={(selected, dropdown) => {
              setNewAntibody({
                ...newAntibody,
                class: selected?.value,
                hlatype: null,
                antigen: null,
              });
            }}
            options={classOptions}
            placeholder={placeHolderText("Select")}
            styles={style}
            value={
              newAntibody.class &&
              classOptions?.find((option) => option.value === newAntibody.class)
            }
            menuPosition={"fixed"}
          />
        </div>
        <div>
          <label
            htmlFor="hlatype"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            HLA Type
          </label>
          <Select
            name="hlatype"
            onChange={(selected, dropdown) => {
              setNewAntibody({
                ...newAntibody,
                hlatype: selected?.value,
                antigen: null,
              });
            }}
            options={hlaTypesOptions}
            placeholder={placeHolderText("Select")}
            styles={style}
            value={
              newAntibody.hlatype &&
              hlaTypesOptions?.find(
                (option) => option.value === newAntibody.hlatype
              )
            }
            menuPosition={"fixed"}
          />
        </div>
        <div>
          <label
            htmlFor="antigen"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Antigen
          </label>
          <Select
            name="antigen"
            onChange={(selected, dropdown) => {
              setNewAntibody({ ...newAntibody, antigen: selected?.value });
            }}
            options={antigenOptions}
            placeholder={placeHolderText("Select")}
            styles={style}
            value={
              newAntibody.antigen &&
              antigenOptions?.find(
                (option) => option.value === newAntibody.antigen
              )
            }
            menuPosition={"fixed"}
          />
        </div>
        <div>
          <label
            htmlFor="status"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            Status
          </label>
          <Select
            name="status"
            onChange={(selected, dropdown) => {
              setNewAntibody({ ...newAntibody, status: selected?.value });
            }}
            options={postiveNegativeOptions}
            placeholder={placeHolderText("Select")}
            styles={style}
            value={
              newAntibody.status &&
              postiveNegativeOptions?.find(
                (option) => option.value === newAntibody.status
              )
            }
            menuPosition={"fixed"}
          />
        </div>
        <div>
          <label
            htmlFor="MFI"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            MFI
          </label>
          <input
            type="number"
            id="MFI"
            name="MFI"
            placeholder="MFI"
            className="w-full p-2 border rounded-md"
            onChange={handleInputChange}
            value={newAntibody.MFI}
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={addAntibody}
            className="h-12 px-4 py-0 mt-5 text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
          >
            <i className="mdi mdi-plus-circle"></i>
          </button>
        </div>
      </div>
      <br />
    </div>
  );
};

export default HLAAntibodyForm;
