const HLA_CLASSIFIERS = ["A", "B", "C", "DRB1", "DQB1", "DPB1"];

export const matchHLATyping = (recipient, donor) => {
  if (!recipient || !donor) {
    return { exactMatches: [], partialMatches: [], matchPercentage: 0 };
  }

  const recipientHLA = recipient.hlaTyping || {};
  const donorHLA = donor.hlaTyping || {};
  const exactMatches = [];
  const partialMatches = [];
  let totalAlleles = 0;

  // Iterate through all unique keys from both recipient and donor
  const allKeys = [
    ...new Set([...Object.keys(recipientHLA), ...Object.keys(donorHLA)]),
  ];

  for (const key of allKeys) {
    if (key === "_id" || HLA_CLASSIFIERS.includes(key)) continue; // Skip the _id field and classifiers

    const recipientAllele = recipientHLA[key];
    const donorAllele = donorHLA[key];

    // Only count alleles that are present in the recipient
    if (recipientAllele) {
      totalAlleles++;

      if (donorAllele) {
        if (recipientAllele === donorAllele) {
          exactMatches.push(key);
        } else {
          // Check for partial match (matching before the first ':')
          const recipientGroup = recipientAllele.split(":")[0];
          const donorGroup = donorAllele.split(":")[0];
          if (recipientGroup === donorGroup) {
            partialMatches.push(key);
          }
        }
      }
    }
  }

  // Calculate match percentage
  const matchScore = exactMatches.length + partialMatches.length * 0.5;
  const matchPercentage =
    totalAlleles > 0 ? (matchScore / totalAlleles) * 100 : 0;

  return {
    exactMatches,
    partialMatches,
    matchPercentage: matchPercentage.toFixed(2),
  };
};
