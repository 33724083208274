import axios from "axios"; // Import axios for making HTTP requests

// Define the main SABTestScore function
const SABTestScore = async (recipientId, donorId) => {
  const HLAAntibodyMFI_threshold = 1000; // Set the lower threshold for HLA antibody MFI
  const MAX_HLAAntibodyMFI_threshold = 10000; // Set the upper threshold for HLA antibody MFI

  // Function to transform HLA data into a standardized format
  function transformHLAData(inputArray) {
    return inputArray.map((item) => ({
      allele: item.antigen, // Map antigen to allele
      mfi: Math.round(item.MFI), // Round MFI value
    }));
  }

  // Function to transform HLA alleles from object to array format
  function transformHLAAlleles(inputObject) {
    const alleles = [];

    for (const [key, value] of Object.entries(inputObject)) {
      if (key.includes("Allele") && value !== "") {
        alleles.push({ allele: value }); // Add non-empty allele values to the array
      }
    }

    return alleles.sort((a, b) => a.allele.localeCompare(b.allele)); // Sort alleles alphabetically
  }

  // Function to fetch clinical records from the API
  const getClinicalRecords = async () => {
    let userToken = localStorage.getItem("token"); // Get user token from local storage
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/clinical-pair/`; // Construct API URL
    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`, // Set authorization header
      },
      data: { recipient_id: recipientId, donor_id: donorId }, // Set request payload
    };

    try {
      const response = await axios(config); // Make API request
      console.log(response.data); // Log response data
      const recipientHlaAntibody = transformHLAData(
        response.data?.recipient_clinical?.hlaAntibody
      ); // Transform recipient HLA antibody data
      const donorHlaTyping = transformHLAAlleles(
        response.data?.donor_clinical?.hlaTyping
      ); // Transform donor HLA typing data
      return { recipientHlaAntibody, donorHlaTyping }; // Return transformed data
    } catch (err) {
      console.log("error api call ", err?.response?.data); // Log error details
      throw new Error("Failed to fetch clinical records"); // Throw error if API call fails
    }
  };

  // Function to check if two HLA alleles match
  const isMatch = (antibodyAllele, donorAllele) => {
    if (!antibodyAllele || !donorAllele) return false; // Return false if either allele is missing
    const [antibodyLocus, antibodySpecificity] = antibodyAllele.split("*"); // Split antibody allele
    const [donorLocus, donorSpecificity] = donorAllele.split("*"); // Split donor allele

    if (antibodyLocus !== donorLocus) return false; // Return false if loci don't match

    const antibodyParts = antibodySpecificity.split(":"); // Split antibody specificity
    const donorParts = donorSpecificity.split(":"); // Split donor specificity

    if (antibodyParts[0] === donorParts[0]) return true; // Return true if first parts match

    if (antibodyParts.length > 1 && donorParts.length > 1) {
      return antibodyParts.every((part, index) => part === donorParts[index]); // Check if all parts match
    }

    return false; // Return false if no match found
  };

  // Function to determine the type of match between two HLA alleles
  const getMatchType = (antibodyAllele, donorAllele) => {
    if (!antibodyAllele || !donorAllele) return "No Match"; // Return "No Match" if either allele is missing

    const [, antibodySpecificity] = antibodyAllele.split("*"); // Get antibody specificity
    const [, donorSpecificity] = donorAllele.split("*"); // Get donor specificity

    const antibodyParts = antibodySpecificity.split(":"); // Split antibody specificity
    const donorParts = donorSpecificity.split(":"); // Split donor specificity

    if (antibodyAllele === donorAllele) return "Full Match"; // Return "Full Match" if alleles are identical
    if (antibodyParts[0] === donorParts[0]) {
      return antibodyParts.length === 1 || donorParts.length === 1
        ? "Low Resolution Match"
        : "Partial Match"; // Determine match type based on specificity parts
    }
    return "No Match"; // Return "No Match" if no match found
  };

  const analyzeHlaMatches = (recipientHlaAntibody, donorHlaTyping) => {
    const significantAntibodies = recipientHlaAntibody.filter(
      (antibody) => antibody.mfi > HLAAntibodyMFI_threshold
    );

    return significantAntibodies.map((antibody) => {
      const matchingDonorAntigens = donorHlaTyping.filter((donorAntigen) =>
        isMatch(antibody.allele, donorAntigen.allele)
      );

      const bestMatch = matchingDonorAntigens[0]?.allele;
      const matchType = getMatchType(antibody.allele, bestMatch);

      let matchScore = 0;
      let unfitReason = "";

      if (matchType !== "No Match") {
        if (antibody.mfi > MAX_HLAAntibodyMFI_threshold) {
          matchScore = 0;
          unfitReason = "High MFI match - Unfit for transplant";
        } else {
          if (matchType === "Full Match") matchScore = 1;
          else if (matchType === "Low Resolution Match") matchScore = 0.8;
          else if (matchType === "Partial Match") matchScore = 0.5;
        }
      }

      return {
        recipientAntibody: antibody.allele,
        mfi: antibody.mfi,
        matchingDonorAntigens: matchingDonorAntigens.map((a) => a.allele),
        isMatch: matchingDonorAntigens.length > 0,
        matchType: matchType,
        matchScore: matchScore,
        unfitReason: unfitReason,
      };
    });
  };

  const calculateMatchScore = (matchResults) => {
    if (matchResults.length === 0) return null;
    const totalScore = matchResults.reduce(
      (sum, result) => sum + result.matchScore,
      0
    );
    const maxPossibleScore = matchResults.length;
    return (100 - (totalScore / maxPossibleScore) * 100).toFixed(2);
  };

  try {
    const { recipientHlaAntibody, donorHlaTyping } = await getClinicalRecords();
    console.log("Recipient HLA Antibody:", recipientHlaAntibody);
    console.log("Donor HLA Typing:", donorHlaTyping);

    if (recipientHlaAntibody.length > 0 && donorHlaTyping.length > 0) {
      const matchResults = analyzeHlaMatches(
        recipientHlaAntibody,
        donorHlaTyping
      );

      // Check MFI values only for matching alleles
      const incompatibleMatch = matchResults.find(
        (result) => result.isMatch && result.mfi > MAX_HLAAntibodyMFI_threshold
      );

      if (incompatibleMatch) {
        return "Incompatible: High MFI match found";
      }

      const score = calculateMatchScore(matchResults);
      console.log("Calculated Score:", score);
      return score;
    }
    return null;
  } catch (error) {
    console.error("Error in SABTestScore:", error);
    throw error;
  }
};

export default SABTestScore; // Export the SABTestScore function
