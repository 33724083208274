import axios from "axios";

const HLA_CLASSIFIERS = ["A", "B", "C", "DRB1", "DQB1", "DPB1"];

const HLAMatchScore = (recipient, donor) => {
  return new Promise((resolve, reject) => {
    console.log("Calculating HLA Match Score for:", recipient, donor);

    const getClinicalRecords = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/clinical/clinical-pair/`;
      console.log("API URL:", urlLink); // Log the API URL for debugging

      const config = {
        method: "post",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { recipient_id: recipient, donor_id: donor },
      };

      axios(config)
        .then(function (response) {
          console.log("API Response:", response.data);
          if (
            response.data?.recipient_clinical &&
            response.data?.donor_clinical
          ) {
            const result = matchHLATyping(
              response.data.recipient_clinical,
              response.data.donor_clinical
            );
            resolve(result.matchPercentage);
          } else {
            console.warn("Incomplete clinical data received");
            resolve(0);
          }
        })
        .catch(function (err) {
          console.error(
            "API call error:",
            err.response?.status,
            err.response?.data
          );
          if (err.response?.status === 404) {
            console.error(
              "API endpoint not found. Please check the URL:",
              urlLink
            );
          }
          resolve(0); // Resolve with 0 instead of rejecting to avoid breaking the UI
        });
    };

    getClinicalRecords();
  });
};

const matchHLATyping = (recipient, donor) => {
  if (!recipient || !donor) {
    return { exactMatches: [], partialMatches: [], matchPercentage: 0 };
  }

  const recipientHLA = recipient.hlaTyping || {};
  const donorHLA = donor.hlaTyping || {};
  const exactMatches = [];
  const partialMatches = [];
  let totalAlleles = 0;

  // Iterate through all unique keys from both recipient and donor
  const allKeys = [
    ...new Set([...Object.keys(recipientHLA), ...Object.keys(donorHLA)]),
  ];

  for (const key of allKeys) {
    if (key === "_id" || HLA_CLASSIFIERS.includes(key)) continue; // Skip the _id field and classifiers

    const recipientAllele = recipientHLA[key];
    const donorAllele = donorHLA[key];

    // Only count alleles that are present in the recipient
    if (recipientAllele) {
      totalAlleles++;

      if (donorAllele) {
        if (recipientAllele === donorAllele) {
          exactMatches.push(key);
        } else {
          // Check for partial match (matching before the first ':')
          const recipientGroup = recipientAllele.split(":")[0];
          const donorGroup = donorAllele.split(":")[0];
          if (recipientGroup === donorGroup) {
            partialMatches.push(key);
          }
        }
      }
    }
  }

  // Calculate match percentage
  const matchScore = exactMatches.length + partialMatches.length * 0.5;
  const matchPercentage =
    totalAlleles > 0 ? (matchScore / totalAlleles) * 100 : 0;

  return {
    exactMatches,
    partialMatches,
    matchPercentage: matchPercentage.toFixed(2),
  };
};

export default HLAMatchScore;
