import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import DashboardAdminChild from "./DashboardAdminChild";

const DashboardAdmin = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <DashboardAdminChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default DashboardAdmin;
