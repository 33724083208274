import React from "react";
import Select from "react-select";

const MedicalHistory = ({
  formData,
  handleChange,
  HbsAgStatusOptions,
  hivStatusTreatmentOptions,
  postiveNegativeOptions,
  genotypeOptions,
  fibrosisStageOptions,
  treatmentRegimenOptions,
  antiHCVstatusOptions,
  placeHolderText,
  style,
  dropDownChange,
  isEditable,
}) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Infections</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-5">
          {["HbsAg", "CMV IgG", "CMV IgM", "EBV IgG", "EBV IgM"].map((item) => (
            <div key={item}>
              <label
                htmlFor={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                {item}
              </label>
              <Select
                name={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
                onChange={dropDownChange}
                options={HbsAgStatusOptions}
                //   className={`w-[${inputWidth}] outline-none h-10`}
                placeholder={placeHolderText("Select")}
                styles={style}
                value={
                  formData?.[
                    `${item.toLowerCase().replace(/ /g, "_")}_patient`
                  ] &&
                  HbsAgStatusOptions?.find(
                    (option) =>
                      option.value ===
                      formData?.[
                        `${item.toLowerCase().replace(/ /g, "_")}_patient`
                      ]
                  )
                }
                menuPosition={"fixed"}
                isDisabled={!isEditable}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">HIV Status</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="hivStatus_hiv_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HIV Status
            </label>
            <Select
              name="hivStatus_hiv_status"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.hivStatus_hiv_status &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.hivStatus_hiv_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="hivStatus_hiv_viral_load"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HIV Viral load
            </label>
            <input
              type="number"
              id="hivStatus_hiv_viral_load"
              name="hivStatus_hiv_viral_load"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hivStatus_hiv_viral_load}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="hivStatus_treatment_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Treatment Status
            </label>
            <Select
              name="hivStatus_treatment_status"
              onChange={dropDownChange}
              options={hivStatusTreatmentOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.hivStatus_treatment_status &&
                hivStatusTreatmentOptions?.find(
                  (option) =>
                    option.value === formData?.hivStatus_treatment_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <label
          htmlFor="hivStatus_notes"
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          Clinical Notes
        </label>
        <textarea
          id="hivStatus_notes"
          name="hivStatus_notes"
          rows="4"
          className="w-full p-2 border rounded-md"
          onChange={handleChange}
          value={formData?.hivStatus_notes || ""}
          disabled={!isEditable}
        />
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">AntiHCV Status</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="antiHCVStatus_anti_hcv_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              AntiHCV Status
            </label>
            <Select
              name="antiHCVStatus_anti_hcv_status"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_anti_hcv_status &&
                postiveNegativeOptions?.find(
                  (option) =>
                    option.value === formData?.antiHCVStatus_anti_hcv_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="antiHCVStatus_hcv_viral_load"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HCV Viral load
            </label>
            <input
              type="number"
              id="antiHCVStatus_hcv_viral_load"
              name="antiHCVStatus_hcv_viral_load"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.antiHCVStatus_hcv_viral_load}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="antiHCVStatus_genotype"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Genotype
            </label>
            <Select
              name="antiHCVStatus_genotype"
              onChange={dropDownChange}
              options={genotypeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_genotype &&
                genotypeOptions?.find(
                  (option) => option.value === formData?.antiHCVStatus_genotype
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="antiHCVStatus_alt_levels"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              ALT Levels
            </label>
            <input
              type="number"
              id="antiHCVStatus_alt_levels"
              name="antiHCVStatus_alt_levels"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.antiHCVStatus_alt_levels}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="antiHCVStatus_fibrosis_stage"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Fibrosis Stage
            </label>
            <Select
              name="antiHCVStatus_fibrosis_stage"
              onChange={dropDownChange}
              options={fibrosisStageOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_fibrosis_stage &&
                fibrosisStageOptions?.find(
                  (option) =>
                    option.value === formData?.antiHCVStatus_fibrosis_stage
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="antiHCVStatus_treatment_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              AntiHCV Treatment Status
            </label>
            <Select
              name="antiHCVStatus_treatment_status"
              onChange={dropDownChange}
              options={hivStatusTreatmentOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_treatment_status &&
                hivStatusTreatmentOptions?.find(
                  (option) =>
                    option.value === formData?.antiHCVStatus_treatment_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="antiHCVStatus_treatment_regimen"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              AntiHCV Treatment Regimen
            </label>
            <Select
              name="antiHCVStatus_treatment_regimen"
              onChange={dropDownChange}
              options={treatmentRegimenOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_treatment_regimen &&
                treatmentRegimenOptions?.find(
                  (option) =>
                    option.value === formData?.antiHCVStatus_treatment_regimen
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="antiHCVStatus_svr12_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              SVR12 status
            </label>
            <Select
              name="antiHCVStatus_svr12_status"
              onChange={dropDownChange}
              options={antiHCVstatusOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_svr12_status &&
                antiHCVstatusOptions?.find(
                  (option) =>
                    option.value === formData?.antiHCVStatus_svr12_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="antiHCVStatus_svr24_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              SVR24 status
            </label>
            <Select
              name="antiHCVStatus_svr24_status"
              onChange={dropDownChange}
              options={antiHCVstatusOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.antiHCVStatus_svr24_status &&
                antiHCVstatusOptions?.find(
                  (option) =>
                    option.value === formData?.antiHCVStatus_svr24_status
                )
              }
              menuPosition={"fixed"}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <label
          htmlFor="antiHCVStatus_notes"
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          Clinical Notes
        </label>
        <textarea
          id="antiHCVStatus_notes"
          name="antiHCVStatus_notes"
          rows="4"
          className="w-full p-2 border rounded-md"
          onChange={handleChange}
          value={formData?.antiHCVStatus_notes || ""}
          disabled={!isEditable}
        />
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Pre-existing Condition</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="preExistingConditions_condition"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Condition
            </label>
            {/* Generate input using DatePicker */}
            <input
              type="text"
              id="preExistingConditions_condition"
              name="preExistingConditions_condition"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.preExistingConditions_condition}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="preExistingConditions_status"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <input
              type="text"
              id="preExistingConditions_status"
              name="preExistingConditions_status"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.preExistingConditions_status}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
          <div>
            <label
              htmlFor="preExistingConditions_notes"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Notes
            </label>
            <textarea
              id="preExistingConditions_notes"
              name="preExistingConditions_notes"
              rows="4"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.preExistingConditions_notes || ""}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalHistory;
