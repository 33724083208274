import React, { useState } from "react";

const RecipientDonorList = ({ saveImportsToDatabase }) => {
  const [data, setData] = useState([]);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      const parsedData = JSON.parse(fileContent);
      setData(parsedData);
    };
    reader.readAsText(uploadedFile);
  };

  return (
    <div className="p-4">
      <h1 className="mb-4 text-2xl font-bold">Recipient-Donor Pairs</h1>
      <input
        type="file"
        accept=".json"
        onChange={handleFileChange}
        className="mb-4"
      />
      {data.length === 0 ? (
        <p>Please upload a JSON file to view recipient-donor pairs.</p>
      ) : (
        data.map((pair, index) => (
          <div key={index} className="p-4 mb-4 border rounded-lg">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="mb-2 text-xl font-semibold">Recipient</h2>
                <p>
                  <strong>Title:</strong> {pair.recipient.member_title}
                </p>
                <p>
                  <strong>Name:</strong> {pair.recipient.recipient_name}{" "}
                  {pair.recipient.last_name}
                </p>
                <p>
                  <strong>Date of Birth:</strong> {pair.recipient.dob}
                </p>
                <p>
                  <strong>Gender:</strong> {pair.recipient.gender}
                </p>
                <p>
                  <strong>Bloodgroup:</strong> {pair.recipient.bloodgroup}
                </p>
                <p>
                  <strong>Treating Doctor:</strong>{" "}
                  {pair.recipient.treating_doctor}
                </p>
                <p>
                  <strong>Registration Number:</strong>{" "}
                  {pair.recipient.registration_number}
                </p>
                <p>
                  <strong>Address:</strong> {pair.recipient.address}
                </p>
                <p>
                  <strong>Pincode:</strong> {pair.recipient.pincode}
                </p>
                <p>
                  <strong>Primary Mobile:</strong>{" "}
                  {pair.recipient.primary_mobile}
                </p>
                <p>
                  <strong>Secondary Mobile:</strong>{" "}
                  {pair.recipient.secondary_mobile}
                </p>
                <p>
                  <strong>WhatsApp Number:</strong>{" "}
                  {pair.recipient.whatsapp_number}
                </p>
                <p>
                  <strong>Email:</strong> {pair.recipient.email}
                </p>
                <p>
                  <strong>Comments:</strong> {pair.recipient.comments}
                </p>
              </div>
              <div>
                <h2 className="mb-2 text-xl font-semibold">Donor</h2>
                <p>
                  <strong>Title:</strong> {pair.donor.member_title}
                </p>
                <p>
                  <strong>Name:</strong> {pair.donor.donor_name}{" "}
                  {pair.donor.last_name}
                </p>
                <p>
                  <strong>Date of Birth:</strong> {pair.donor.dob}
                </p>
                <p>
                  <strong>Gender:</strong> {pair.donor.gender}
                </p>
                <p>
                  <strong>Bloodgroup:</strong> {pair.donor.bloodgroup}
                </p>
                <p>
                  <strong>Address:</strong> {pair.donor.address}
                </p>
                <p>
                  <strong>Primary Mobile:</strong> {pair.donor.primary_mobile}
                </p>
                <p>
                  <strong>Secondary Mobile:</strong>{" "}
                  {pair.donor.secondary_mobile}
                </p>
                <p>
                  <strong>WhatsApp Number:</strong> {pair.donor.whatsapp_number}
                </p>
                <p>
                  <strong>Comments:</strong> {pair.donor.comments}
                </p>
              </div>
            </div>
            <button
              className="px-4 py-2 mt-4 text-white bg-blue-500 rounded"
              onClick={() => saveImportsToDatabase(pair)}
            >
              Save
            </button>
          </div>
        ))
      )}
    </div>
  );
};

export default RecipientDonorList;
