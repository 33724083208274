import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar, Line } from "react-chartjs-2";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardAdminChild = () => {
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [recipients, setRecipients] = useState([]);
  const [donors, setDonors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recipientsClinical, setRecipientClinicals] = useState([]);
  const [donorsClinical, setDonorClinicals] = useState([]);
  const [clinicals, setClinicals] = useState([]);

  const getClinicalDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data;
        setClinicals(oldData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getClinicalDataFromServer();
  }, []);

  useEffect(() => {
    if (clinicals?.length) {
      setRecipientClinicals(clinicals.filter((item) => item.donor_id === null));
      setDonorClinicals(clinicals.filter((item) => item.donor_id !== null));
    }
  }, [clinicals]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [recipientsResponse, donorsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/recipients/admin`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/donors`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        setRecipients(recipientsResponse.data);
        setDonors(donorsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const bloodGroupData = donors.reduce((acc, donor) => {
    const bloodGroup = donor.blood_group?.label || "Unknown";
    acc[bloodGroup] = (acc[bloodGroup] || 0) + 1;
    return acc;
  }, {});

  const ageData = donors.reduce((acc, donor) => {
    const age = donor.age || 0;
    const ageGroup = Math.floor(age / 10) * 10;
    acc[ageGroup] = (acc[ageGroup] || 0) + 1;
    return acc;
  }, {});

  const bloodGroupChartData = {
    labels: Object.keys(bloodGroupData),
    datasets: [
      {
        label: "Donors by Blood Group",
        data: Object.values(bloodGroupData),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const ageChartData = {
    labels: Object.keys(ageData)
      .sort((a, b) => a - b)
      .map((age) => `${age}-${parseInt(age) + 9}`),
    datasets: [
      {
        label: "Donors by Age Group",
        data: Object.values(ageData),
        fill: false,
        borderColor: "rgb(255, 99, 132)",
        tension: 0.1,
      },
    ],
  };

  const prepareBloodPressureData = (clinicalData) => {
    return {
      labels: clinicalData.map((data) => data.date_of_entry.split("T")[0]),
      datasets: [
        {
          label: "Systolic",
          data: clinicalData.map((data) => data.blood_pressure.systolic),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Diastolic",
          data: clinicalData.map((data) => data.blood_pressure.diastolic),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
  };

  const prepareCBCData = (clinicalData) => {
    return {
      labels: clinicalData.map((data) => data.date_of_entry.split("T")[0]),
      datasets: [
        {
          label: "WBC",
          data: clinicalData.map((data) => data.cbc.wbc),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Platelets",
          data: clinicalData.map((data) => data.cbc.platelets),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Hemoglobin",
          data: clinicalData.map((data) => data.cbc.hemoglobin),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    };
  };

  const prepareRenalProfileData = (clinicalData) => {
    return {
      labels: clinicalData.map((data) => data.date_of_entry.split("T")[0]),
      datasets: [
        {
          label: "Creatinine",
          data: clinicalData.map((data) => data.renal_profile.creatinine),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Glucose",
          data: clinicalData.map((data) => data.renal_profile.glucose),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "HbA1c",
          data: clinicalData.map((data) => data.renal_profile.HbA1c),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Clinical Data Over Time",
      },
    },
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-32 h-32 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen p-3">
      <div className="w-full max-w-6xl">
        <h1 className="mb-6 text-3xl font-bold text-center text-gray-800">
          Donor Matching Dashboard
        </h1>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <div className="p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              Total Recipients
            </h2>
            <div className="flex items-center">
              <svg
                className="w-12 h-12 mr-4 text-blue-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <span className="text-4xl font-bold text-gray-800">
                {recipients.length}
              </span>
            </div>
          </div>
          <div className="p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              Total Donors
            </h2>
            <div className="flex items-center">
              <svg
                className="w-12 h-12 mr-4 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
              <span className="text-4xl font-bold text-gray-800">
                {donors.length}
              </span>
            </div>
          </div>
          <div className="col-span-1 p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md md:col-span-2 lg:col-span-1 hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              Blood Group Distribution
            </h2>
            <div className="h-64">
              <Bar data={bloodGroupChartData} options={chartOptions} />
            </div>
          </div>
          <div className="col-span-1 p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md md:col-span-2 lg:col-span-1 hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              Donor Age Distribution
            </h2>
            <div className="h-64">
              <Line data={ageChartData} options={chartOptions} />
            </div>
          </div>
          <div className="col-span-1 p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md md:col-span-2 lg:col-span-1 hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              Blood Pressure Trends
            </h2>
            <div className="h-64">
              <Line
                data={prepareBloodPressureData(recipientsClinical)}
                options={chartOptions}
              />
            </div>
          </div>
          <div className="col-span-1 p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md md:col-span-2 lg:col-span-1 hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              CBC Trends
            </h2>
            <div className="h-64">
              <Line
                data={prepareCBCData(recipientsClinical)}
                options={chartOptions}
              />
            </div>
          </div>
          <div className="col-span-1 p-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-md md:col-span-2 lg:col-span-1 hover:-translate-y-1 hover:shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-gray-700">
              Renal Profile Trends
            </h2>
            <div className="h-64">
              <Line
                data={prepareRenalProfileData(recipientsClinical)}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <button className="px-6 py-3 font-bold text-white transition duration-300 ease-in-out transform bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Find Matching Donor
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdminChild;
