import React from "react";
import Select from "react-select";

const HLAProfile = ({
  handleChange,
  formData,
  postiveNegativeOptions,
  dropDownChange,
  placeHolderText,
  style,
  isEditable,
}) => {
  return (
    <div className="">
      {/* HLA Typing inputs */}
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">HLA Profile</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div>
            <label
              htmlFor="hlatyping_a_allele_1"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-A (Allele-1)
            </label>
            <input
              type="text"
              id="hlatyping_a_allele_1"
              name="hlatyping_a_allele_1"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hlatyping_a_allele_1}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="hlatyping_a_allele_2"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-A (Allele-2)
            </label>
            <input
              type="text"
              id="hlatyping_a_allele_2"
              name="hlatyping_a_allele_2"
              min="0"
              className="w-full p-2 border rounded-md"
              hlatyping_a_allele_1
              onChange={handleChange}
              value={formData?.hlatyping_a_allele_2}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="hlatyping_b_allele_1"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-B (Allele-1)
            </label>
            <input
              type="text"
              id="hlatyping_b_allele_1"
              name="hlatyping_b_allele_1"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hlatyping_b_allele_1}
              disabled={!isEditable}
            />
          </div>
          <div>
            <label
              htmlFor="hlatyping_b_allele_2"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-B (Allele-2)
            </label>
            <input
              type="text"
              id="hlatyping_b_allele_2"
              name="hlatyping_b_allele_2"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hlatyping_b_allele_2}
              disabled={!isEditable}
            />
          </div>
        </div>
        <br />
        <div className="mb-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div>
              <label
                htmlFor="hlatyping_c_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-C (Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_c_allele_1"
                name="hlatyping_c_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_c_allele_1}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_c_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-C (Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_c_allele_2"
                name="hlatyping_c_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_c_allele_2}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_drb1_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DRB1(Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_drb1_allele_1"
                name="hlatyping_drb1_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_drb1_allele_1}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_drb1_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DRB1(Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_drb1_allele_2"
                name="hlatyping_drb1_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_drb1_allele_2}
                disabled={!isEditable}
              />
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div>
              <label
                htmlFor="hlatyping_dqb1_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DQB1(Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_dqb1_allele_1"
                name="hlatyping_dqb1_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dqb1_allele_1}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_dqb1_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DQB1(Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_dqb1_allele_2"
                name="hlatyping_dqb1_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dqb1_allele_2}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_dpb1_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DPB1(Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_dpb1_allele_1"
                name="hlatyping_dpb1_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dpb1_allele_1}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_dpb1_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DPB1(Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_dpb1_allele_2"
                name="hlatyping_dpb1_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dpb1_allele_2}
                disabled={!isEditable}
              />
            </div>
          </div>
          <br />
          {/* HLA Antibody Profile */}
          {/* <h2 className="mt-2 mb-4 text-xl font-semibold">
            Single Antigen Bead (SAB) Profile
          </h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div>
              <label
                htmlFor="hlaAntibodyProfile_antigen"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Antigen
              </label>
              <input
                type="text"
                id="hlaAntibodyProfile_antigen"
                name="hlaAntibodyProfile_antigen"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlaAntibodyProfile_antigen}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlaAntibodyProfile_mfi"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                MFI
              </label>
              <input
                type="number"
                id="hlaAntibodyProfile_mfi"
                name="hlaAntibodyProfile_mfi"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlaAntibodyProfile_mfi}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlaAntibodyProfile_specificity"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Specificity
              </label>
              <Select
                name="hlaAntibodyProfile_specificity"
                onChange={dropDownChange}
                options={postiveNegativeOptions}
                //   className={`w-[${inputWidth}] outline-none h-10`}
                placeholder={placeHolderText("Select")}
                styles={style}
                value={
                  formData?.hlaAntibodyProfile_specificity &&
                  postiveNegativeOptions?.find(
                    (option) =>
                      option.value === formData?.hlaAntibodyProfile_specificity
                  )
                }
                menuPosition={"fixed"}
                isDisabled={!isEditable}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-3 md:grid-cols-3">
            <div>
              <label
                htmlFor="hlaAntibodyProfile_antigen_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Antigen
              </label>
              <input
                type="text"
                id="hlaAntibodyProfile_antigen_1"
                name="hlaAntibodyProfile_antigen_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlaAntibodyProfile_antigen_1}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlaAntibodyProfile_mfi_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                MFI
              </label>
              <input
                type="number"
                id="hlaAntibodyProfile_mfi_1"
                name="hlaAntibodyProfile_mfi_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlaAntibodyProfile_mfi_1}
                disabled={!isEditable}
              />
            </div>
            <div>
              <label
                htmlFor="hlaAntibodyProfile_specificity_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Specificity
              </label>
              <Select
                name="hlaAntibodyProfile_specificity_1"
                onChange={dropDownChange}
                options={postiveNegativeOptions}
                //   className={`w-[${inputWidth}] outline-none h-10`}
                placeholder={placeHolderText("Select")}
                styles={style}
                value={
                  formData?.hlaAntibodyProfile_specificity_1 &&
                  postiveNegativeOptions?.find(
                    (option) =>
                      option.value ===
                      formData?.hlaAntibodyProfile_specificity_1
                  )
                }
                menuPosition={"fixed"}
                isDisabled={!isEditable}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HLAProfile;
