import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import RecipientDonorList from "./RecipientDonorList";
import {
  genderOptions,
  relationshipOptions,
} from "../../components/Data/dropdownoptions";

const ManageRecipientDonorImportsChild = () => {
  const [bloodgroupOptions, setBloodgroupOptions] = useState();

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/bloodgroup/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          const oldData = response.data; // Access consultants array from the response data
          //   console.log(oldData);
          const updatedData = oldData.map((data) => {
            return {
              value: data?.bloodgroup,
              label: data?.bloodgroup,
            };
          });
          setBloodgroupOptions(updatedData); // Assuming setData is a useState hook function
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data);
        });
    };
    getDataFromServer();
  }, []);

  const saveImportsToDatabase = (data) => {
    console.log("Save imports to database", data);
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/recipients/import`;
    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        recipient: {
          ...data.recipient,
          gender: genderOptions.find((g) => g.label === data.recipient.gender),
          blood_group: bloodgroupOptions.find(
            (b) => b.label === data.recipient.bloodgroup
          ),
        },
        donor: {
          ...data.donor,
          gender: genderOptions.find((g) => g.label === data.donor.gender),
          blood_group: bloodgroupOptions.find(
            (b) => b.label === data.donor.bloodgroup
          ),
          recipient_relation: relationshipOptions.find(
            (r) => r.value === data.donor.relationship
          ),
          donor_active_status: true,
        },
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        toast.success("Data saved successfully");
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  return (
    <div className="w-full">
      <RecipientDonorList saveImportsToDatabase={saveImportsToDatabase} />
    </div>
  );
};

export default ManageRecipientDonorImportsChild;
