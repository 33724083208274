import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography } from "@mui/material";

const calculateProgress = (data) => {
  if (!data || Object.keys(data).length === 0) {
    return 0;
  }

  let totalFields = 0;
  let filledFields = 0;

  const countFields = (obj) => {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          countFields(value);
        } else if (value !== null && value !== undefined && value !== "") {
          filledFields++;
        }
        totalFields++;
      }
    }
  };

  countFields(data);

  return ((filledFields / totalFields) * 100).toFixed(0);
};

const ClinicalDataStatus = ({ record }) => {
  const recipient_id = record?.id;
  const [recipientClinicals, setRecipientClinicals] = useState({});
  const [donorClinicals, setDonorClinicals] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch recipient clinical data
  const getRecpientClinicalDataFromServer = async () => {
    try {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/clinical/recipient/${recipient_id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await axios(config);
      setRecipientClinicals(response.data);
    } catch (err) {
      console.log("error api call ", err?.response?.data || err.message);
    }
  };

  useEffect(() => {
    getRecpientClinicalDataFromServer();
  }, [recipient_id]);

  const progress = calculateProgress(recipientClinicals);

  return (
    <div className="flex flex-col w-full mt-3">
      <div className="ml-1 text-sm">{record?.name}</div>
      <div className="flex items-center -mt-5">
        <i
          className="flex-shrink-0 mdi mdi-plus-circle"
          style={{
            color: "red",
            marginRight: "0px",
            display: "flex",
            alignItems: "center",
            fontSize: "11px",
          }}
        ></i>
        <div className="flex items-center w-full h-2 bg-gray-200 rounded-full">
          <div
            className="h-full transition-all duration-300 ease-in-out bg-green-500 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
        <span className="ml-1 text-xs">{`${progress}%`}</span>
      </div>
    </div>
  );
};

export default ClinicalDataStatus;
