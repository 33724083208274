import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import DashboardConsultantChild from "./DashboardConsultantChild";

const DashboardConsultant = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <DashboardConsultantChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default DashboardConsultant;
