import React from "react";
import Select from "react-select";

const LiverProfile = ({ handleChange, formData, isEditable }) => {
  return (
    <div className="mb-6">
      <h2 className="mb-4 text-xl font-semibold">Liver Profile</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {["SGOT", "SGPT", "TSH", "APTT", "PT", "INR"].map((item) => (
          <div key={item}>
            <label
              htmlFor={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              {item}
            </label>
            <input
              type="number"
              id={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
              name={`${item.toLowerCase().replace(/ /g, "_")}_patient`}
              min="0"
              step="0.01"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={
                formData?.[
                  `${item.toLowerCase().replace(/ /g, "_")}_patient`
                ] ?? ""
              }
              disabled={!isEditable}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiverProfile;
