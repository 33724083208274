import React, { useState } from "react";
import Select from "react-select";

import HLAAntibodyForm from "./HLAAntibodyForm";
const HLAProfile = ({
  handleChange,
  formData,
  postiveNegativeOptions,
  dropDownChange,
  hlaAntigenOptions,
  placeHolderText,
  style,
  setFormData,
}) => {
  const [hlaAntibodies, setHlaAntibodies] = useState({
    antigen: "",
    mfi: "",
    specificity: "",
  });

  return (
    <div className="">
      {/* HLA Typing inputs */}
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">HLA Profile</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div>
            <label
              htmlFor="hlatyping_a_allele_1"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-A (Allele-1)
            </label>
            <input
              type="text"
              id="hlatyping_a_allele_1"
              name="hlatyping_a_allele_1"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hlatyping_a_allele_1}
            />
          </div>
          <div>
            <label
              htmlFor="hlatyping_a_allele_2"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-A (Allele-2)
            </label>
            <input
              type="text"
              id="hlatyping_a_allele_2"
              name="hlatyping_a_allele_2"
              min="0"
              className="w-full p-2 border rounded-md"
              hlatyping_a_allele_1
              onChange={handleChange}
              value={formData?.hlatyping_a_allele_2}
            />
          </div>
          <div>
            <label
              htmlFor="hlatyping_b_allele_1"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-B (Allele-1)
            </label>
            <input
              type="text"
              id="hlatyping_b_allele_1"
              name="hlatyping_b_allele_1"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hlatyping_b_allele_1}
            />
          </div>
          <div>
            <label
              htmlFor="hlatyping_b_allele_2"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              HLA-B (Allele-2)
            </label>
            <input
              type="text"
              id="hlatyping_b_allele_2"
              name="hlatyping_b_allele_2"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.hlatyping_b_allele_2}
            />
          </div>
        </div>
        <br />
        <div className="mb-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div>
              <label
                htmlFor="hlatyping_c_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-C (Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_c_allele_1"
                name="hlatyping_c_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_c_allele_1}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_c_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-C (Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_c_allele_2"
                name="hlatyping_c_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_c_allele_2}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_drb1_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DRB1(Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_drb1_allele_1"
                name="hlatyping_drb1_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_drb1_allele_1}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_drb1_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DRB1(Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_drb1_allele_2"
                name="hlatyping_drb1_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_drb1_allele_2}
              />
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div>
              <label
                htmlFor="hlatyping_dqb1_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DQB1(Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_dqb1_allele_1"
                name="hlatyping_dqb1_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dqb1_allele_1}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_dqb1_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DQB1(Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_dqb1_allele_2"
                name="hlatyping_dqb1_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dqb1_allele_2}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_dpb1_allele_1"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DPB1(Allele-1)
              </label>
              <input
                type="text"
                id="hlatyping_dpb1_allele_1"
                name="hlatyping_dpb1_allele_1"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dpb1_allele_1}
              />
            </div>
            <div>
              <label
                htmlFor="hlatyping_dpb1_allele_2"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                HLA-DPB1(Allele-2)
              </label>
              <input
                type="text"
                id="hlatyping_dpb1_allele_2"
                name="hlatyping_dpb1_allele_2"
                min="0"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.hlatyping_dpb1_allele_2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HLAProfile;
