import React from "react";
import { useLocation } from "react-router-dom";

import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import ManageDonorsChild from "./ManageDonorsChild";

const ManageDonors = () => {
  const { state } = useLocation();
  const rcptRecord = state;
  return (
    <MainTemplate>
      <CardBackground>
        <ManageDonorsChild rcptRecord={rcptRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default ManageDonors;
