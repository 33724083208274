import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const GeneralProfile = ({ handleDateChange, formData, handleChange }) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Patient Information</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label
              htmlFor="date_of_entry"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Date of Entry
            </label>
            {/* Generate input using DatePicker */}
            <DatePicker
              name="date_of_entry"
              onChange={handleDateChange}
              className="w-full h-10"
              format="YYYY-MM-DD"
              value={
                formData?.date_of_entry &&
                dayjs(formData?.date_of_entry, "YYYY-MM-DD")
              }
            />
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Weight (kg)
            </label>
            <input
              type="number"
              id="weight"
              name="weight"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.weight}
            />
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Height (meters)
            </label>
            <input
              type="number"
              id="height"
              name="height"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.height}
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Blood Pressure</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="blood_pressure_systolic"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Systolic
            </label>
            <input
              type="number"
              id="blood_pressure_systolic"
              name="blood_pressure_systolic"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.blood_pressure_systolic}
            />
          </div>
          <div>
            <label
              htmlFor="blood_pressure_diastolic"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Diastolic
            </label>
            <input
              type="number"
              id="blood_pressure_diastolic"
              name="blood_pressure_diastolic"
              min="0"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.blood_pressure_diastolic}
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">CBC</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {["WBC", "PLATELETS", "Hemoglobin", "ESR"].map((item) => (
            <div key={item}>
              <label
                htmlFor={`cbc_${item.toLowerCase()}`}
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                {item}
              </label>
              <input
                type="number"
                id={`cbc_${item.toLowerCase()}`}
                name={`cbc_${item.toLowerCase()}`}
                min="0"
                step="0.01"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={formData?.[`cbc_${item.toLowerCase()}`] || ""}
              />
            </div>
          ))}
        </div>
      </div>
      {/* Create a textarea named recent_infections */}
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Recent Infections</h2>
        <textarea
          id="recent_infections"
          name="recent_infections"
          rows="4"
          className="w-full p-2 border rounded-md"
          onChange={handleChange}
          value={formData?.recent_infections || ""}
        />
      </div>
    </div>
  );
};

export default GeneralProfile;
