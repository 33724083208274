import React from "react";

const ViewModal = ({ currentRecord }) => {
  const {
    gender,
    blood_group,
    recipient_code,
    member_title,
    recipient_name,
    last_name,
    dob,
    treating_doctor,
    registration_number,
    address,
    pincode,
    primary_mobile,
    secondary_mobile,
    whatsapp_number,
    email,
    comments,
    name,
  } = currentRecord;

  const calculateAge = (birthDateString) => {
    const birthDate = new Date(birthDateString);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs); // Convert milliseconds to date object
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(dob);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-medium text-gray-800">{name}</h2>
        <div className="flex items-center space-x-2">
          <span className="inline-block px-2 py-1 text-xs font-semibold text-white bg-blue-500 rounded-full">
            {recipient_code}
          </span>
          <span className="inline-block px-2 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-full">
            {gender.label}
          </span>
          <span className="inline-block px-2 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-full">
            {blood_group.label}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Age:</span>
          <span className="font-medium text-gray-700">{age}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Date of Birth:</span>
          <span className="font-medium text-gray-700">{dob.slice(0, 10)}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Treating Doctor:</span>
          <span className="font-medium text-gray-700">{treating_doctor}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Registration Number:</span>
          <span className="font-medium text-gray-700">
            {registration_number}
          </span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Address:</span>
          <span className="font-medium text-gray-700">{address}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Pincode:</span>
          <span className="font-medium text-gray-700">{pincode}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Primary Mobile:</span>
          <span className="font-medium text-gray-700">{primary_mobile}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Secondary Mobile:</span>
          <span className="font-medium text-gray-700">{secondary_mobile}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Whatsapp Number:</span>
          <span className="font-medium text-gray-700">{whatsapp_number}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Email:</span>
          <span className="font-medium text-gray-700">{email}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-light text-gray-500">Comments:</span>
          <span className="font-medium text-gray-700">{comments}</span>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;
