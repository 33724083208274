import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";
import Login from "../../pages/Login/Login";
import ManageRoles from "../../pages/Roles/ManageRoles";
import Unauthorised from "../../pages/404/Unauthorised";
import Page404 from "../../pages/404/Page404";
import Page403 from "../../pages/403/Page403";
import DashboardAdmin from "../../pages/DashBoard/DashboardAdmin";
import ManageConsultants from "../../pages/Consultants/ManageConsultants";
import ManageBloodgroup from "../../pages/Bloodgroup/ManageBloodgroup";
import ManageAdmins from "../../pages/Admins/ManageAdmins";
import ManageRecipients from "../../pages/Recipients/ManageRecipients";
import DashboardConsultant from "../../pages/DashBoard/DashboardConsultant";
import ManageDonors from "../../pages/Donors/ManageDonors";
import ManageRecipientDonorImports from "../../pages/ImportRecipients/ManageRecipientDonorImports";
// import CrossMatchDonors from "../../pages/CrossMatchDonors/CrossMatchDonors";
import CrossMatchDonorsList from "../../pages/CrossMatchDonors/v1/CrossMatchDonorsList";
// import DeepAnalysisComponent from "../../pages/CrossMatchDonors/DeepAnalysisComponent";
import ManageRecipientClinicalPage from "../../pages/RecipientClinical/ManageRecipientClinicalPage";
import ManageDonorClinicalPage from "../../pages/DonorClinical/ManageDonorClinicalPage";
import MyProfileUpdate from "../../pages/MyProfileUpdate/MyProfileUpdate";
import ActivityLog from "../../pages/ActivityLogs/ActivityLog";
import DeepAnalyzeComponent from "../../pages/CrossMatchDonors/v1/DeepAnalyzeComponent";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" name="Login" element={<Login />} />
      <Route path="/login" name="Login" element={<Login />} />
      <Route
        path="/error404"
        name="Error: Page not found"
        element={<Unauthorised />}
      />
      <Route element={<PrivateRoutes />}>
        <Route
          path="/error404"
          name="Error: Page not found"
          element={<Page404 />}
        />
        <Route
          path="/error403"
          name="Error: Permission denied"
          element={<Page403 />}
        />
        <Route element={<PrivateRoutes roleRequired={["ADMIN"]} />}>
          <Route path="/roles" name="Roles" element={<ManageRoles />} />
          <Route
            path="/dashboard-admin"
            name="Dashboard Admin"
            element={<DashboardAdmin />}
          />
          <Route
            path="/consultants"
            name="Manage Consultants"
            element={<ManageConsultants />}
          />

          <Route
            path="/admin-users"
            name="Manage Admins"
            element={<ManageAdmins />}
          />

          <Route
            path="/activity-logs"
            name="Show all Logs"
            element={<ActivityLog />}
          />
        </Route>
        <Route
          element={<PrivateRoutes roleRequired={["ADMIN", "CONSULTANT"]} />}
        >
          <Route
            path="/dashboard-consultant"
            name="Dashboard Consultant"
            element={<DashboardConsultant />}
          />
          <Route
            path="/blood"
            name="Manage Bloodgroup"
            element={<ManageBloodgroup />}
          />
          <Route
            path="/recipients"
            name="Manage Recipients"
            element={<ManageRecipients />}
          />
          <Route
            path="/donors"
            name="Manage Donors"
            element={<ManageDonors />}
          />
          <Route
            path="/imports"
            name="Manage Imports"
            element={<ManageRecipientDonorImports />}
          />
          {/* <Route
            path="/cross-match"
            name="Cross Match Donors"
            element={<CrossMatchDonors />}
          /> */}
          <Route
            path="/v1/cross-match"
            name="Cross Match Donors"
            element={<CrossMatchDonorsList />}
          />
          {/* <Route
            path="/deep-analysis"
            name="Deep Analyse"
            element={<DeepAnalysisComponent />}
          /> */}
          <Route
            path="/recipient/clinical/add"
            name="Clinical"
            element={<ManageRecipientClinicalPage />}
          />
          <Route
            path="/donor/clinical/add"
            name="Clinical"
            element={<ManageDonorClinicalPage />}
          />
          <Route
            path="/profile/update"
            name="My Profile"
            element={<MyProfileUpdate />}
          />
          <Route
            path="/v1/deep-analyze"
            name="Deep Analyse"
            element={<DeepAnalyzeComponent />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
