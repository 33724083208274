import React, { useState, useEffect } from "react";
import axios from "axios";
import { cregMatchList } from "../../../components/Data/dropdownoptions";

const CREGAnalysisComponent = ({ recipient, donor }) => {
  const [recipientHlaAntibody, setRecipientHlaAntibody] = useState([]);
  const [donorHlaTyping, setDonorHlaTyping] = useState([]);
  const [cregAnalysisResults, setCregAnalysisResults] = useState({
    details: [],
    overallMatchScore: "0",
    hasHighRiskAntibodies: false,
  });
  const [cregScore, setCregScore] = useState(0);

  const HLAAntibodyMFI_threshold = 1000;
  const MAX_HLAAntibodyMFI_threshold = 10000;

  function transformHLAData(inputArray) {
    return inputArray.map((item) => ({
      allele: item.antigen,
      mfi: Math.round(item.MFI),
    }));
  }

  function transformHLAAlleles(inputObject) {
    const alleles = [];

    for (const [key, value] of Object.entries(inputObject)) {
      if (key.includes("Allele") && value !== "") {
        alleles.push({ allele: value });
      }
    }

    // Sort alleles alphabetically
    alleles.sort((a, b) => a.allele.localeCompare(b.allele));

    return alleles;
  }

  const getClinicalRecords = () => {
    // Reset states when fetching new data
    setRecipientHlaAntibody([]);
    setDonorHlaTyping([]);
    setCregAnalysisResults({
      details: [],
      overallMatchScore: "0",
      hasHighRiskAntibodies: false,
    });
    setCregScore(0);

    // API call to fetch clinical records
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/clinical-pair/`;
    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { recipient_id: recipient, donor_id: donor },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setRecipientHlaAntibody(
          transformHLAData(response.data?.recipient_clinical?.hlaAntibody)
        );
        setDonorHlaTyping(
          transformHLAAlleles(response.data?.donor_clinical?.hlaTyping)
        );
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  const performCREGAnalysis = () => {
    const results = [];
    let totalScore = 0;
    let totalPossibleScore = 0;

    // Filter antibodies to only include those above the MFI threshold
    const highMFIAntibodies = recipientHlaAntibody.filter(
      (ab) => ab.mfi > HLAAntibodyMFI_threshold
    );

    // Analyze each high MFI antibody
    highMFIAntibodies.forEach((antibody) => {
      // Find the matching CREG group for this antibody
      const cregMatch = cregMatchList.find((creg) =>
        antibody.allele.startsWith(creg.allele)
      );

      if (cregMatch) {
        // If a CREG match is found, look for matching donor alleles
        const matchingDonorAlleles = donorHlaTyping
          .filter((donorAllele) =>
            // Check if any CREG allele in this group matches the donor allele
            cregMatch.creg.some((cregAllele) =>
              // Compare only the part before the first colon (e.g., "B*07" from "B*07:02")
              donorAllele.allele.startsWith(cregAllele.split(":")[0])
            )
          )
          .map((allele) => allele.allele); // Extract just the allele string

        const isHighRisk = antibody.mfi > MAX_HLAAntibodyMFI_threshold;
        let score = isHighRisk
          ? 0
          : (matchingDonorAlleles.length / cregMatch.creg.length) * 100;

        totalScore += score;
        totalPossibleScore += 100;

        results.push({
          antibody: antibody.allele,
          mfi: antibody.mfi,
          cregGroup: cregMatch.creg.join(", "),
          matchingDonorAlleles: matchingDonorAlleles,
          score: score,
          isHighRisk: isHighRisk,
        });
      }
    });

    // Calculate the CREG score
    let cregScore;
    if (totalPossibleScore === 0) {
      // No matches found, set CREG score to 0
      cregScore = 0;
    } else {
      // Matches found, calculate the score
      cregScore = (totalScore / totalPossibleScore) * 100;
    }

    // Calculate the overall match score (inverse of CREG score)
    const overallMatchScore = 100 - cregScore;

    setCregScore(cregScore.toFixed(2));
    setCregAnalysisResults({
      details: results,
      overallMatchScore: overallMatchScore.toFixed(2),
      hasHighRiskAntibodies: results.some((result) => result.isHighRisk),
    });
  };

  useEffect(() => {
    getClinicalRecords();
  }, [recipient, donor]); // Add recipient and donor to the dependency array

  useEffect(() => {
    if (recipientHlaAntibody.length > 0 && donorHlaTyping.length > 0) {
      performCREGAnalysis();
    }
  }, [recipientHlaAntibody, donorHlaTyping]);

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="relative p-4">
      <div
        className={`flex items-center justify-between p-4 mb-6 rounded-lg ${
          cregAnalysisResults?.hasHighRiskAntibodies
            ? "bg-red-100"
            : cregAnalysisResults?.overallMatchScore === "100"
            ? "bg-green-100"
            : "bg-gradient-to-r from-indigo-100 to-blue-100"
        }`}
      >
        {cregAnalysisResults?.hasHighRiskAntibodies ? (
          <div className="w-full">
            <p className="text-2xl font-bold text-red-800">Warning:</p>
            <p className="text-xl font-bold text-red-800">
              Currently unfit for transplant
            </p>
            <p className="mt-2 text-sm text-red-600">
              One or more matching antibodies have MFI values exceeding the
              maximum threshold
            </p>
          </div>
        ) : cregAnalysisResults?.overallMatchScore === "100" ? (
          <div className="w-full">
            <p className="text-2xl font-bold text-green-800">Suitable Match</p>
            <p className="text-xl font-bold text-green-800">
              No CREG matches found
            </p>
            <p className="mt-2 text-sm text-green-600">
              This could be a suitable donor with a 100% match score
            </p>
          </div>
        ) : (
          <>
            <div>
              <p className="text-2xl font-bold text-indigo-800">Match Score:</p>
              <p className="text-3xl font-bold text-indigo-800">
                {cregAnalysisResults?.overallMatchScore}%
              </p>
            </div>
            <div className="text-right">
              <p className="text-sm text-indigo-600">CREG Score</p>
              <p className="text-3xl font-bold text-indigo-800">{cregScore}%</p>
            </div>
          </>
        )}
      </div>
      <button
        onClick={() => setShowDetails(!showDetails)}
        className="px-4 py-2 mb-4 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        {showDetails ? "Hide Details" : "Show Details"}
      </button>
      {showDetails && (
        <>
          <div className="grid grid-cols-1 gap-6 mb-8">
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="mb-2 text-xl font-semibold text-indigo-600">
                Recipient: {recipient}
              </h2>
              <p className="mb-2 text-sm font-medium text-gray-600">
                HLA Antibodies (MFI &gt; {HLAAntibodyMFI_threshold})
              </p>
              <ul className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                {recipientHlaAntibody
                  .filter((ab) => ab.mfi > HLAAntibodyMFI_threshold)
                  .map((ab) => (
                    <li
                      key={ab.allele}
                      className="flex items-center p-2 bg-blue-100 rounded-md"
                    >
                      <span className="inline-block w-16 font-medium">
                        {ab.allele}
                      </span>
                      <span
                        className={`px-2 py-1 ml-2 text-xs font-semibold text-white rounded ${
                          ab.mfi > MAX_HLAAntibodyMFI_threshold
                            ? "bg-red-500"
                            : "bg-blue-500"
                        }`}
                      >
                        MFI: {ab.mfi}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="mb-2 text-xl font-semibold text-indigo-600">
                Donor: {donor}
              </h2>
              <p className="mb-2 text-sm font-medium text-gray-600">
                HLA Typing
              </p>
              <ul className="grid grid-cols-2 gap-2 sm:grid-cols-3">
                {donorHlaTyping.map((allele) => (
                  <li
                    key={allele.allele}
                    className="px-2 py-1 text-sm font-medium text-gray-700 bg-gray-200 rounded"
                  >
                    {allele.allele}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <h2 className="mb-4 text-2xl font-semibold text-indigo-700">
              CREG Analysis Results
            </h2>
            {cregAnalysisResults.hasHighRiskAntibodies && (
              <p className="p-3 mb-4 text-red-700 bg-red-100 border border-red-400 rounded">
                Warning: High-risk antibodies detected. This donor may be
                unsuitable.
              </p>
            )}
            {cregAnalysisResults?.details?.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-indigo-100">
                      <th className="p-3 text-left border border-indigo-200">
                        Antibody
                      </th>
                      <th className="p-3 text-left border border-indigo-200">
                        MFI
                      </th>
                      <th className="p-3 text-left border border-indigo-200">
                        CREG Group
                      </th>
                      <th className="p-3 text-left border border-indigo-200">
                        Matching Donor Alleles
                      </th>
                      <th className="p-3 text-left border border-indigo-200">
                        Match Score
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cregAnalysisResults.details.map((result, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        } ${result.isHighRisk ? "font-bold" : ""}`}
                      >
                        <td className="p-3 border border-indigo-100">
                          {result.antibody}
                        </td>
                        <td
                          className={`p-3 border border-indigo-100 ${
                            result.isHighRisk ? "text-red-600" : ""
                          }`}
                        >
                          {result.mfi}
                        </td>
                        <td className="p-3 border border-indigo-100">
                          {result.cregGroup}
                        </td>
                        <td className="p-3 border border-indigo-100">
                          {result.matchingDonorAlleles.join(", ")}
                        </td>
                        <td className="p-3 font-semibold border border-indigo-100">
                          <span
                            className={`px-2 py-1 rounded ${
                              result.isHighRisk
                                ? "bg-red-100 text-red-800"
                                : result.score > 75
                                ? "bg-green-100 text-green-800"
                                : result.score > 50
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-red-100 text-red-800"
                            }`}
                          >
                            {result.isHighRisk
                              ? "High Risk"
                              : `${result.score.toFixed(2)}%`}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="p-4 text-lg text-center text-gray-600 bg-gray-100 rounded-md">
                No CREG matches found.
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CREGAnalysisComponent;
