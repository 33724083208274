import React from "react";

const Modal = ({ isOpen, onClose, children, footer }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-10 w-full h-full overflow-y-auto bg-gray-600 bg-opacity-50"
      id="my-modal"
    >
      <div className="relative w-11/12 p-5 mx-auto bg-white border rounded-md shadow-lg top-20 md:w-3/4 lg:w-1/2">
        <div className="mt-3">
          {children}
          {footer ? (
            footer
          ) : (
            <div className="items-center px-4 py-3">
              <button
                id="ok-btn"
                className="w-full px-4 py-2 text-base font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
