export const memberTitleOptions = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Ms.", label: "Ms." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Prof.", label: "Prof." },
];

export const qualificationOptions = [
  { value: "bachelors", label: "Bachelor's Degree" },
  { value: "masters", label: "Master's Degree" },
  { value: "doctoral", label: "Doctorate/Doctoral Degree" },
];

export const membershipOptions = [
  { value: "LIFETIME", label: "Lifetime Membership" },
  { value: "ASSOCIATE", label: "Associate Membership" },
];

export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const relationshipOptions = [
  { value: "spouse", label: "Spouse" },
  { value: "child", label: "Child" },
  { value: "parent", label: "Parent" },
  { value: "sibling", label: "Sibling" },
  { value: "other-blood-relatives", label: "Other blood relatives" },
];

export const postiveNegativeOptions = [
  { value: "positive", label: "Positive" },
  { value: "negative", label: "Negative" },
];

export const yesNoOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const urineOptionsPerDay = [
  { value: "200 ml - 500 ml", label: "200 ml - 500 ml" },
  { value: "500 ml - 1000 ml", label: "500 ml - 1000 ml" },
  { value: "1000+ ml", label: "1000+ ml" },
];

export const btReasonOptions = [
  { value: "Anemia", label: "Anemia" },
  { value: "Surgery", label: "Surgery" },
  { value: "Trauma", label: "Trauma" },
  { value: "Chronic Kidney Disease", label: "Chronic Kidney Disease" },
  { value: "Cancer", label: "Cancer" },
  { value: "Hemophilia", label: "Hemophilia" },
  { value: "Thalassemia", label: "Thalassemia" },
  { value: "Bone Marrow Transplant", label: "Bone Marrow Transplant" },
  { value: "Chemotherapy", label: "Chemotherapy" },
  { value: "Burns", label: "Burns" },
];

export const transfusionTypeOptions = [
  { value: "Whole Blood", label: "Whole Blood" },
  { value: "Red Blood Cells", label: "Red Blood Cells" },
  { value: "Plasma", label: "Plasma" },
  { value: "Platelets", label: "Platelets" },
  // { value: "Cryoprecipitate", label: "Cryoprecipitate" },
  // { value: "Granulocytes", label: "Granulocytes" },
];

export const pregnancyOutcomeOptions = [
  { value: "Live Birth", label: "Live Birth" },
  { value: "Still Birth", label: "Still Birth" },
  { value: "Miscarriage", label: "Miscarriage" },
  { value: "Ectopic Pregnancy", label: "Ectopic Pregnancy" },
  { value: "Molar Pregnancy", label: "Molar Pregnancy" },
  { value: "Abortion (Elective)", label: "Abortion (Elective)" },
  { value: "Abortion (Spontaneous)", label: "Abortion (Spontaneous)" },
  { value: "Premature Birth", label: "Premature Birth" },
  { value: "Induced Labor", label: "Induced Labor" },
  {
    value: "Terminated for Medical Reasons",
    label: "Terminated for Medical Reasons",
  },
  { value: "Ongoing", label: "Ongoing" },
];

export const pregnancyStatusOptions = [
  { value: "Current", label: "Current" },
  { value: "Previous", label: "Previous" },
];

export const transplantOrganOptions = [
  { value: "Kidney", label: "Kidney" },
  { value: "Liver", label: "Liver" },
  { value: "Liver-Kidney", label: "Liver-Kidney" },
  { value: "Heart", label: "Heart" },
  { value: "Lung", label: "Lung" },
  { value: "Pancreas", label: "Pancreas" },
  { value: "Bone Marrow", label: "Bone Marrow" },
  { value: "Cornea", label: "Cornea" },
  { value: "Skin", label: "Skin" },
  { value: "Islet Cells", label: "Islet Cells" },
  {
    value: "Composite Tissue (Face/Hand)",
    label: "Composite Tissue (Face/Hand)",
  },
  { value: "Heart-Lung", label: "Heart-Lung" },

  { value: "Multi-Visceral", label: "Multi-Visceral" },
];

export const transplantReasonOptions = [
  { value: "Acute Rejection", label: "Acute Rejection" },
  { value: "Chronic Rejection", label: "Chronic Rejection" },
  { value: "Graft Failure", label: "Graft Failure" },
  { value: "Dry Toxicity", label: "Dry Toxicity" },
  { value: "Septicemia", label: "Septicemia" },
  { value: "Other", label: "Other" },
];

export const transplantOutcomeOptions = [
  { value: "Stable", label: "Stable" },
  { value: "Ongoing Monitoring", label: "Ongoing Monitoring" },
  { value: "Graft Failure", label: "Graft Failure" },
  { value: "Rejection", label: "Rejection" },
  { value: "Infection", label: "Infection" },
  { value: "Complications", label: "Complications" },
  { value: "Recovered", label: "Recovered" },
  { value: "Improving", label: "Improving" },
  { value: "Deteriorating", label: "Deteriorating" },
  { value: "Re-transplant Required", label: "Re-transplant Required" },
  { value: "Deceased", label: "Deceased" },
];

export const hivStatusTreatmentOptions = [
  { value: "Treatment Naive", label: "Treatment Naive" }, // Patient has not started any treatment
  // { value: "Under Treatment", label: "Under Treatment" }, // Patient is currently undergoing treatment
  // { value: "Adherent", label: "Adherent" }, // Patient is consistently following their treatment regimen
  // { value: "Non-Adherent", label: "Non-Adherent" }, // Patient is not consistently following their treatment regimen
  { value: "Treatment Failure", label: "Treatment Failure" }, // Treatment is not effectively controlling the virus
  // { value: "Treatment Interruption", label: "Treatment Interruption" }, // Patient has stopped treatment temporarily
  // { value: "Switching Regimen", label: "Switching Regimen" }, // Patient is changing their treatment regimen
  { value: "Viral Suppression", label: "Viral Suppression" }, // Virus is suppressed to undetectable levels
  // { value: "Stable", label: "Stable" }, // Patient is stable on current treatment
  { value: "Discontinued Treatment", label: "Discontinued Treatment" }, // Treatment has been stopped
  // { value: "Cured", label: "Cured" }, // The patient has been cured (though this is rare and would generally be noted as a functional cure or long-term remission)
];

export const genotypeOptions = [
  { value: "SSLP", label: "Simple Sequence Length Polymorphism (SSLP)" },
  { value: "RFLP", label: "Restriction Fragment Length Polymorphism (RFLP)" },
  { value: "AS_PCR", label: "Allele-Specific PCR (AS-PCR)" },
  { value: "SNP_Genotyping", label: "SNP Genotyping" },
  { value: "STR_Analysis", label: "Short Tandem Repeat (STR) Analysis" },
  { value: "qPCR", label: "Quantitative PCR (qPCR)" },
  { value: "NGS", label: "Next-Generation Sequencing (NGS)" },
  { value: "Microarray", label: "DNA Microarray" },
  {
    value: "MLPA",
    label: "Multiplex Ligation-dependent Probe Amplification (MLPA)",
  },
  { value: "TaqMan", label: "TaqMan Genotyping Assays" },
];

export const fibrosisStageOptions = [
  { value: "F0", label: "F0 - No Fibrosis" },
  { value: "F1", label: "F1 - Mild Fibrosis" },
  { value: "F2", label: "F2 - Moderate Fibrosis" },
  { value: "F3", label: "F3 - Severe Fibrosis" },
  { value: "F4", label: "F4 - Cirrhosis" },
];

export const treatmentRegimenOptions = [
  { value: "Sofosbuvir_Ledipasvir", label: "Sofosbuvir + Ledipasvir" },
  { value: "Sofosbuvir_Velpatasvir", label: "Sofosbuvir + Velpatasvir" },
  { value: "Glecaprevir_Pibrentasvir", label: "Glecaprevir + Pibrentasvir" },
  { value: "Sofosbuvir_Daclatasvir", label: "Sofosbuvir + Daclatasvir" },
  { value: "Elbasvir_Grazoprevir", label: "Elbasvir + Grazoprevir" },
  {
    value: "Ombitasvir_Paritaprevir_Ritonavir_Dasabuvir",
    label: "Ombitasvir + Paritaprevir + Ritonavir + Dasabuvir",
  },
  { value: "Simeprevir_Sofosbuvir", label: "Simeprevir + Sofosbuvir" },
  { value: "Peginterferon_Ribavirin", label: "Peginterferon + Ribavirin" },
  { value: "Ribavirin_Sofosbuvir", label: "Ribavirin + Sofosbuvir" },
  {
    value: "Boceprevir_Ribavirin_Peginterferon",
    label: "Boceprevir + Ribavirin + Peginterferon",
  },
];

export const antiHCVstatusOptions = [
  { value: "achieved", label: "Achieved" },
  { value: "not_achieved", label: "Not Achieved" },
  { value: "relapsed", label: "Relapsed" },
  { value: "reinfection", label: "Reinfection" },
  { value: "unknown", label: "Unknown" },
  { value: "pending", label: "Pending" },
];

export const dialysisVintageOptions = [
  { value: "less_than_6_months", label: "Less than 6 months" },
  { value: "6_to_3_years", label: "6 to 3 years" },
  { value: "greater_than_3_years", label: "Greater than 3 years" },
];

export const basicDiseaseOptions = [
  { value: "cgn", label: "CGN (Chronic Glomerulonephritis)" },
  { value: "cin", label: "CIN (Chronic Interstitial Nephritis)" },
  { value: "other", label: "Other" },
];

export const hlaAntigenOptions = {
  "HLA-A": [
    { value: "A*01", label: "A*01" },
    { value: "A*01:01", label: "A*01:01" },
    { value: "A*02", label: "A*02" },
    { value: "A*02:01", label: "A*02:01" },
    { value: "A*02:03", label: "A*02:03" },
    { value: "A*02:06", label: "A*02:06" },
    { value: "A*02:07", label: "A*02:07" },
    { value: "A*02:11", label: "A*02:11" },
    { value: "A*03", label: "A*03" },
    { value: "A*03:01", label: "A*03:01" },
    { value: "A*11", label: "A*11" },
    { value: "A*11:01", label: "A*11:01" },
    { value: "A*11:02", label: "A*11:02" },
    { value: "A*23", label: "A*23" },
    { value: "A*23:01", label: "A*23:01" },
    { value: "A*24", label: "A*24" },
    { value: "A*24:02", label: "A*24:02" },
    { value: "A*24:03", label: "A*24:03" },
    { value: "A*25", label: "A*25" },
    { value: "A*25:01", label: "A*25:01" },
    { value: "A*26", label: "A*26" },
    { value: "A*26:01", label: "A*26:01" },
    { value: "A*29", label: "A*29" },
    { value: "A*29:01", label: "A*29:01" },
    { value: "A*30", label: "A*30" },
    { value: "A*30:01", label: "A*30:01" },
    { value: "A*31", label: "A*31" },
    { value: "A*31:01", label: "A*31:01" },
    { value: "A*32", label: "A*32" },
    { value: "A*32:01", label: "A*32:01" },
    { value: "A*33", label: "A*33" },
    { value: "A*33:01", label: "A*33:01" },
    { value: "A*34", label: "A*34" },
    { value: "A*34:01", label: "A*34:01" },
    { value: "A*36", label: "A*36" },
    { value: "A*36:01", label: "A*36:01" },
    { value: "A*43", label: "A*43" },
    { value: "A*66", label: "A*66" },
    { value: "A*66:01", label: "A*66:01" },
    { value: "A*68", label: "A*68" },
    { value: "A*68:01", label: "A*68:01" },
    { value: "A*68:02", label: "A*68:02" },
    { value: "A*69", label: "A*69" },
    { value: "A*69:01", label: "A*69:01" },
    { value: "A*74", label: "A*74" },
    { value: "A*74:01", label: "A*74:01" },
    { value: "A*80", label: "A*80" },
    { value: "A*80:01", label: "A*80:01" },
  ],
  "HLA-B": [
    { value: "B*07", label: "B*07" },
    { value: "B*08", label: "B*08" },
    { value: "B*13", label: "B*13" },
    { value: "B*14:01", label: "B*14:01" },
    { value: "B*14:02", label: "B*14:02" },
    { value: "B*15:01", label: "B*15:01" },
    { value: "B*15:02", label: "B*15:02" },
    { value: "B*15:03", label: "B*15:03" },
    { value: "B*15:10", label: "B*15:10" },
    { value: "B*15:12", label: "B*15:12" },
    { value: "B*15:13", label: "B*15:13" },
    { value: "B*15:16", label: "B*15:16" },
    { value: "B*18:01", label: "B*18:01" },
    { value: "B*27", label: "B*27" },
    { value: "B*35", label: "B*35" },
    { value: "B*37", label: "B*37" },
    { value: "B*38", label: "B*38" },
    { value: "B*39", label: "B*39" },
    { value: "B*40:01", label: "B*40:01" },
    { value: "B*40:02", label: "B*40:02" },
    { value: "B*40:06", label: "B*40:06" },
    { value: "B*41", label: "B*41" },
    { value: "B*42", label: "B*42" },
    { value: "B*44:02", label: "B*44:02" },
    { value: "B*44:03", label: "B*44:03" },
    { value: "B*45", label: "B*45" },
    { value: "B*46", label: "B*46" },
    { value: "B*49", label: "B*49" },
    { value: "B*47", label: "B*47" },
    { value: "B*48", label: "B*48" },
    { value: "B*50", label: "B*50" },
    { value: "B*51:01", label: "B*51:01" },
    { value: "B*51:02", label: "B*51:02" },
    { value: "B*52", label: "B*52" },
    { value: "B*53", label: "B*53" },
    { value: "B*54", label: "B*54" },
    { value: "B*55", label: "B*55" },
    { value: "B*56", label: "B*56" },
    { value: "B*57", label: "B*57" },
    { value: "B*58", label: "B*58" },
    { value: "B*59", label: "B*59" },
    { value: "B*62", label: "B*62" },
    { value: "B*65", label: "B*65" },
    { value: "B*67", label: "B*67" },
    { value: "B*73", label: "B*73" },
    { value: "B*78", label: "B*78" },
    { value: "B*81", label: "B*81" },
    { value: "B*82", label: "B*82" },
  ],
  "HLA-C": [
    { value: "C*01:02", label: "C*01:02" },
    { value: "C*02:02", label: "C*02:02" },
    { value: "C*02:10", label: "C*02:10" },
    { value: "C*03:02", label: "C*03:02" },
    { value: "C*03:03", label: "C*03:03" },
    { value: "C*03:04", label: "C*03:04" },
    { value: "C*03:07", label: "C*03:07" },
    { value: "C*04:01", label: "C*04:01" },
    { value: "C*04:03", label: "C*04:03" },
    { value: "C*05:01", label: "C*05:01" },
    { value: "C*06:02", label: "C*06:02" },
    { value: "C*07:01", label: "C*07:01" },
    { value: "C*07:02", label: "C*07:02" },
    { value: "C*08:01", label: "C*08:01" },
    { value: "C*08:02", label: "C*08:02" },
    { value: "C*12:03", label: "C*12:03" },
    { value: "C*12:04", label: "C*12:04" },
    { value: "C*14:02", label: "C*14:02" },
    { value: "C*14:03", label: "C*14:03" },
    { value: "C*15:02", label: "C*15:02" },
    { value: "C*15:05", label: "C*15:05" },
    { value: "C*16:01", label: "C*16:01" },
    { value: "C*16:02", label: "C*16:02" },
    { value: "C*17:01", label: "C*17:01" },
    { value: "C*17:02", label: "C*17:02" },
    { value: "C*18:01", label: "C*18:01" },
  ],
  "HLA-DR": [
    { value: "DRB1*01", label: "DRB1*01" },
    { value: "DRB1*01:01", label: "DRB1*01:01" },
    { value: "DRB1*01:03", label: "DRB1*01:03" },
    { value: "DRB1*03:01", label: "DRB1*03:01" },
    { value: "DRB1*03:02", label: "DRB1*03:02" },
    { value: "DRB1*04", label: "DRB1*04" },
    { value: "DRB1*04:01", label: "DRB1*04:01" },
    { value: "DRB1*04:02", label: "DRB1*04:02" },
    { value: "DRB1*07", label: "DRB1*07" },
    { value: "DRB1*07:01", label: "DRB1*07:01" },
    { value: "DRB1*08", label: "DRB1*08" },
    { value: "DRB1*08:01", label: "DRB1*08:01" },
    { value: "DRB1*09", label: "DRB1*09" },
    { value: "DRB1*09:01", label: "DRB1*09:01" },
    { value: "DRB1*10", label: "DRB1*10" },
    { value: "DRB1*11", label: "DRB1*11" },
    { value: "DRB1*11:01", label: "DRB1*11:01" },
    { value: "DRB1*11:04", label: "DRB1*11:04" },
    { value: "DRB1*12", label: "DRB1*12" },
    { value: "DRB1*12:01", label: "DRB1*12:01" },
    { value: "DRB1*13", label: "DRB1*13" },
    { value: "DRB1*13:01", label: "DRB1*13:01" },
    { value: "DRB1*13:02", label: "DRB1*13:02" },
    { value: "DRB1*14", label: "DRB1*14" },
    { value: "DRB1*14:01", label: "DRB1*14:01" },
    { value: "DRB1*14:54", label: "DRB1*14:54" },
    { value: "DRB1*15", label: "DRB1*15" },
    { value: "DRB1*15:01", label: "DRB1*15:01" },
    { value: "DRB1*16", label: "DRB1*16" },
    { value: "DRB1*16:01", label: "DRB1*16:01" },
    { value: "DRB3", label: "DRB3" },
    { value: "DRB4", label: "DRB4" },
    { value: "DRB5", label: "DRB5" },
  ],
  "HLA-DQ": [
    { value: "DQB1*02", label: "DQB1*02" },
    { value: "DQB1*04", label: "DQB1*04" },
    { value: "DQB1*05", label: "DQB1*05" },
    { value: "DQB1*06", label: "DQB1*06" },
    { value: "DQB1*03:01", label: "DQB1*03:01" },
    { value: "DQB1*03:02", label: "DQ*03:02" },
    { value: "DQB1*03:03", label: "DQB1*03:03" },
    { value: "DQB1*03:04", label: "DQB1*03:04" },
    { value: "DQB1*03:05", label: "DQB1*03:05" },
    { value: "DQB1*03:19", label: "DQB1*03:19" },
    { value: "DQB1*07", label: "DQB1*07" },
    { value: "DQB1*08", label: "DQB1*08" },
    { value: "DQB1*09", label: "DQB1*09" },
    { value: "DQB1*0101", label: "DQB1*0101" },
    { value: "DQB1*0102", label: "DQB1*0102" },
    { value: "DQB1*0103", label: "DQB1*0103" },
    { value: "DQB1*0104", label: "DQB1*0104" },
  ],
  "HLA-DP": [
    { value: "DPB1*01", label: "DPB1*01" },
    { value: "DPB1*01:01", label: "DPB1*01:01" },
    { value: "DPB1*02", label: "DPB1*02" },
    { value: "DPB1*02:01", label: "DPB1*02:01" },
    { value: "DPB1*03", label: "DPB1*03" },
    { value: "DPB1*03:01", label: "DPB1*03:01" },
    { value: "DPB1*04", label: "DPB1*04" },
    { value: "DPB1*04:01", label: "DPB1*04:01" },
    { value: "DPB1*05", label: "DPB1*05" },
    { value: "DPB1*06", label: "DPB1*06" },
    { value: "DPB1*06:01", label: "DPB1*06:01" },
    { value: "DPB1*09", label: "DPB1*09" },
    { value: "DPB1*09:01", label: "DPB1*09:01" },
    { value: "DPB1*10", label: "DPB1*10" },
    { value: "DPB1*10:01", label: "DPB1*10:01" },
    { value: "DPB1*11", label: "DPB1*11" },
    { value: "DPB1*13", label: "DPB1*13" },
    { value: "DPB1*13:01", label: "DPB1*13:01" },
    { value: "DPB1*14", label: "DPB1*14" },
    { value: "DPB1*14:01", label: "DPB1*14:01" },
    { value: "DPB1*15", label: "DPB1*15" },
    { value: "DPB1*17", label: "DPB1*17" },
    { value: "DPB1*18", label: "DPB1*18" },
    { value: "DPB1*19", label: "DPB1*19" },
    { value: "DPB1*20", label: "DPB1*20" },
    { value: "DPB1*21", label: "DPB1*21" },
    { value: "DPB1*23", label: "DPB1*23" },
    { value: "DPB1*28", label: "DPB1*28" },
    { value: "DPB1*34", label: "DPB1*34" },
    { value: "DPB1*36", label: "DPB1*36" },
  ],
};

export const HbsAgStatusOptions = [
  { value: "reactive", label: "Reactive" },
  { value: "non_reactive", label: "Non-Reactive" },
  { value: "equivocal", label: "Equivocal" },
  { value: "unknown", label: "Unknown" },
  { value: "pending", label: "Pending" },
];

export const cregMatchList = [
  {
    allele: "A*01",
    creg: [
      "A*01",
      "A*03",
      "A*11",
      "A*36",
      "A*23",
      "A*24",
      "A*29",
      "A*30",
      "A*31",
      "A*80",
    ],
  },
  {
    allele: "A*02",
    creg: ["A*02", "A*68", "A*69", "A*23", "A*24", "B*57", "A*58"],
  },
  {
    allele: "A*10",
    creg: [
      "A*11",
      "A*25",
      "A*32",
      "A*26",
      "A*33",
      "A*34",
      "A*66",
      "A*68",
      "A*69",
      "A*74",
    ],
  },
  { allele: "A*19", creg: ["A*29", "A*30", "A*31", "A*32", "A*33", "A*74"] },
  {
    allele: "B*05",
    creg: [
      "B*18",
      "B*35",
      "B*51",
      "B*52",
      "B*53",
      "B*49",
      "B*50",
      "B*57",
      "B*58",
      "B*62",
      "B*63",
      "B*71",
      "B*72",
      "B*75",
      "B*76",
      "B*77",
      "B*78",
    ],
  },
  {
    allele: "B*07",
    creg: [
      "B*07",
      "B*27",
      "B*13",
      "B*37",
      "B*41",
      "B*42",
      "B*47",
      "B*48",
      "B*54",
      "B*55",
      "B*56",
      "B*60",
      "B*61",
      "B*40:05",
      "B*73",
    ],
  },
  {
    allele: "B*08",
    creg: ["B*08", "B*18", "B*38", "B*39", "B*59", "B*64", "B*65", "B*67"],
  },
  {
    allele: "B*12",
    creg: [
      "B*13",
      "B*37",
      "B*41",
      "B*44",
      "B*45",
      "B*49",
      "B*50",
      "B*47",
      "B*60",
      "B*61",
    ],
  },
  {
    allele: "B*15",
    creg: [
      "B*57",
      "B*58",
      "B*62",
      "B*63",
      "B*71",
      "B*72",
      "B*75",
      "B*76",
      "B*77",
    ],
  },
  {
    allele: "B*04",
    creg: ["B*04", "A*23", "B*24", "B*25", "B*32"],
  },
  {
    allele: "B*06",
    creg: ["B*06"],
  },
];
