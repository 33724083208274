import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ReportHistoryModal = ({
  show,
  onClose,
  recipientCode,
  donorCode,
  recipientClinicals,
  donorClinicals,
}) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (show && recipientCode && donorCode) {
      const historyKey = `aiAnalysisHistory_${recipientCode}_${donorCode}`;
      const storedHistory = JSON.parse(localStorage.getItem(historyKey)) || [];
      setHistory(storedHistory);
    }
  }, [show, recipientCode, donorCode]);

  if (!show) return null;

  const deleteReport = (index) => {
    const updatedHistory = history.filter((_, i) => i !== index);
    setHistory(updatedHistory);
    const historyKey = `aiAnalysisHistory_${recipientCode}_${donorCode}`;
    localStorage.setItem(historyKey, JSON.stringify(updatedHistory));
  };

  const downloadReportAsPDF = (report, timestamp) => {
    const doc = new jsPDF();
    const date = new Date(timestamp).toLocaleString();

    // Set font size and type for the title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("DonoSure AI Generated Report", 20, 20);

    // Set font for the date
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Generated on: ${date}`, 20, 30);

    // Add HLA typing table
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("HLA Typing", 20, 45);

    const getHLAValue = (clinicals, allele) =>
      clinicals?.hlaTyping?.[allele] || "N/A";

    const hlaData = [
      ["Locus", "Recipient", "Donor"],
      [
        "A",
        `${getHLAValue(recipientClinicals, "HLA_A_Allele_1")}, ${getHLAValue(
          recipientClinicals,
          "HLA_A_Allele_2"
        )}`,
        `${getHLAValue(donorClinicals, "HLA_A_Allele_1")}, ${getHLAValue(
          donorClinicals,
          "HLA_A_Allele_2"
        )}`,
      ],
      [
        "B",
        `${getHLAValue(recipientClinicals, "HLA_B_Allele_1")}, ${getHLAValue(
          recipientClinicals,
          "HLA_B_Allele_2"
        )}`,
        `${getHLAValue(donorClinicals, "HLA_B_Allele_1")}, ${getHLAValue(
          donorClinicals,
          "HLA_B_Allele_2"
        )}`,
      ],
      [
        "C",
        `${getHLAValue(recipientClinicals, "HLA_C_Allele_1")}, ${getHLAValue(
          recipientClinicals,
          "HLA_C_Allele_2"
        )}`,
        `${getHLAValue(donorClinicals, "HLA_C_Allele_1")}, ${getHLAValue(
          donorClinicals,
          "HLA_C_Allele_2"
        )}`,
      ],
      [
        "DRB1",
        `${getHLAValue(recipientClinicals, "HLA_DRB1_Allele_1")}, ${getHLAValue(
          recipientClinicals,
          "HLA_DRB1_Allele_2"
        )}`,
        `${getHLAValue(donorClinicals, "HLA_DRB1_Allele_1")}, ${getHLAValue(
          donorClinicals,
          "HLA_DRB1_Allele_2"
        )}`,
      ],
      [
        "DQB1",
        `${getHLAValue(recipientClinicals, "HLA_DQB1_Allele_1")}, ${getHLAValue(
          recipientClinicals,
          "HLA_DQB1_Allele_2"
        )}`,
        `${getHLAValue(donorClinicals, "HLA_DQB1_Allele_1")}, ${getHLAValue(
          donorClinicals,
          "HLA_DQB1_Allele_2"
        )}`,
      ],
      [
        "DPB1",
        `${getHLAValue(recipientClinicals, "HLA_DPB1_Allele_1")}, ${getHLAValue(
          recipientClinicals,
          "HLA_DPB1_Allele_2"
        )}`,
        `${getHLAValue(donorClinicals, "HLA_DPB1_Allele_1")}, ${getHLAValue(
          donorClinicals,
          "HLA_DPB1_Allele_2"
        )}`,
      ],
    ];

    doc.autoTable({
      startY: 50,
      head: [hlaData[0]],
      body: hlaData.slice(1),
      theme: "grid",
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      alternateRowStyles: { fillColor: [242, 242, 242] },
    });

    // Format the report content
    const formattedReport = report
      .replace(/\*\*(.*?)\*\*/g, "$1")
      .replace(/\*(.*?)\*/g, "$1");

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");

    let y = doc.lastAutoTable.finalY + 20; // Start after the table
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 7;
    const margin = 20;

    // Split the report into paragraphs
    const paragraphs = formattedReport.split("\n\n");

    paragraphs.forEach((paragraph) => {
      const lines = doc.splitTextToSize(
        paragraph,
        doc.internal.pageSize.width - 2 * margin
      );

      if (y + lines.length * lineHeight > pageHeight - margin) {
        doc.addPage();
        y = margin;
      }

      lines.forEach((line) => {
        doc.text(line, margin, y);
        y += lineHeight;
      });

      y += lineHeight;
    });

    doc.save(`AI_Analysis_Report_${date.replace(/[/:\\]/g, "_")}.pdf`);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 outline-none focus:outline-none">
      <div className="relative w-full max-w-4xl mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 bg-gray-100 border-b border-solid rounded-t border-blueGray-200">
            <h3 className="text-2xl font-semibold text-gray-800">
              Report History for {recipientCode} - {donorCode}
            </h3>
            <button
              className="p-1 ml-auto text-3xl font-semibold leading-none text-gray-600 bg-transparent border-0 outline-none opacity-70 hover:opacity-100 focus:outline-none"
              onClick={onClose}
            >
              ×
            </button>
          </div>
          <div className="relative flex-auto p-6 overflow-y-auto max-h-[70vh]">
            {history.length === 0 ? (
              <p className="text-gray-600">No history available.</p>
            ) : (
              [...history].reverse().map((item, index) => (
                <div
                  key={index}
                  className="p-4 mb-8 transition-shadow duration-200 border border-gray-200 rounded-lg shadow-sm hover:shadow-md"
                >
                  <div className="flex items-center justify-between mb-2">
                    <p className="font-semibold text-gray-600">
                      Generated on: {new Date(item.timestamp).toLocaleString()}
                    </p>
                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          console.log("Downloading report:", item);
                          downloadReportAsPDF(item.report, item.timestamp);
                        }}
                        className="p-1 mr-1 text-blue-500 transition-colors duration-200 hover:text-blue-700"
                        aria-label="Download PDF"
                        title="Download PDF"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => deleteReport(history.length - 1 - index)}
                        className="p-1 text-red-500 transition-colors duration-200 hover:text-red-700"
                        aria-label="Delete report"
                        title="Delete report"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <ReactMarkdown className="prose-sm prose max-w-none">
                    {item.report}
                  </ReactMarkdown>
                </div>
              ))
            )}
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200 bg-gray-50">
            <button
              className="px-6 py-2 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none hover:bg-red-600 hover:shadow-lg focus:outline-none"
              type="button"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHistoryModal;
