import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import RecipientDonorCards from "./RecipientDonorCards";
import MatchDisplay from "./MatchDisplay";

const CrossMatchDonorsListChild = ({ recipientRecord }) => {
  let navigate = useNavigate();
  const [donor, setDonor] = useState([]);
  const [qualifiedPairs, setQualifiedPairs] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/donors/${recipientRecord?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data; // Access consultants array from the response data
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data?.member_title ? data?.member_title : ""} ${
              data?.donor_name
            } ${data?.last_name ? data?.last_name : ""}`,
            gender_label: data?.gender?.label,
            gender: data?.gender,
            blood_group: data?.blood_group,
            bloodgroup: data?.blood_group?.label,
            donor_active_status: data?.donor_active_status,
            relationship: data?.recipient_relation?.label,
          };
        });
        setDonor(updatedData[0]); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
    // Load search history from localStorage for this specific recipient-donor pair
    const historyKey = `crossMatchHistory_${recipientRecord?._id}_${donor?.id}`;
    const savedHistory = localStorage.getItem(historyKey);
    if (savedHistory) {
      setSearchHistory(JSON.parse(savedHistory));
    }
  }, [recipientRecord?._id, donor?.id]);

  const crossMatchDonors = () => {
    if (!donor) {
      return toast.error(
        "Cannot proceed with Crossmatch, At least 1 Donor needed"
      );
    }

    setIsLoading(true);
    setQualifiedPairs([]);

    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/crossmatch/`;
    const config = {
      method: "POST",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { recipient: recipientRecord, donor: donor },
    };

    axios(config)
      .then(function (response) {
        const crossMatchData = response.data;
        console.log(crossMatchData);
        const newQualifiedPairs = crossMatchData.qualifiedPairs;
        setQualifiedPairs(newQualifiedPairs);

        // Add new search to history
        const newHistory = [
          ...searchHistory,
          { timestamp: new Date(), pairs: newQualifiedPairs },
        ];
        setSearchHistory(newHistory);
        setSelectedHistoryIndex(newHistory.length - 1);

        // Save updated history to localStorage with a unique key
        const historyKey = `crossMatchHistory_${recipientRecord?._id}_${donor?.id}`;
        localStorage.setItem(historyKey, JSON.stringify(newHistory));
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
        toast.error("An error occurred while processing the match");
      })
      .finally(() => {
        setTimeout(() => setIsLoading(false), 2000); // Increased delay for animation visibility
      });
  };

  const selectHistoryItem = (index) => {
    setSelectedHistoryIndex(index);
    setQualifiedPairs(searchHistory[index].pairs);
  };

  const clearSearchHistory = () => {
    setSearchHistory([]);
    setSelectedHistoryIndex(null);
    setQualifiedPairs([]);
    // Remove history from localStorage with the unique key
    const historyKey = `crossMatchHistory_${recipientRecord?._id}_${donor?.id}`;
    localStorage.removeItem(historyKey);
    toast.success("Search history cleared");
  };

  return (
    <div className="flex flex-col w-full min-h-screen">
      <header className="p-4 bg-gray-100 shadow-md">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <h1 className="mb-4 text-2xl font-bold md:mb-0">
            Cross Match Donors
          </h1>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
            <button
              onClick={() => navigate(-1)}
              className="w-full px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600 sm:w-auto"
            >
              Back
            </button>
            <button
              onClick={crossMatchDonors}
              className="flex items-center justify-center w-full px-4 py-2 text-white bg-purple-500 rounded hover:bg-purple-600 sm:w-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              Start Bloodgroup Match
            </button>
          </div>
        </div>
      </header>

      <main className="flex-grow w-full p-4">
        <div className="w-full mb-8">
          <RecipientDonorCards recipient={recipientRecord} donor={donor} />
        </div>

        {searchHistory.length > 0 && (
          <div className="w-full mt-8">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold">Search History</h2>
              <button
                onClick={clearSearchHistory}
                className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600"
              >
                Clear History
              </button>
            </div>
            <div className="flex space-x-2 overflow-x-auto">
              {searchHistory.map((item, index) => (
                <button
                  key={index}
                  onClick={() => selectHistoryItem(index)}
                  className={`px-4 py-2 text-sm rounded ${
                    selectedHistoryIndex === index
                      ? "bg-purple-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {new Date(item.timestamp).toLocaleString()}
                </button>
              ))}
            </div>
          </div>
        )}

        {isLoading && (
          <div className="w-full mt-8">
            <h2 className="mb-4 text-xl font-semibold text-center text-gray-700">
              Searching for a Match
            </h2>
            <div className="flex justify-center">
              <div className="donor-search-animation">
                <div className="magnifying-glass">
                  <i className="mdi mdi-account-heart donor-icon"></i>
                </div>
                <i className="mdi mdi-account-circle recipient-icon"></i>
                <i className="mdi mdi-account-group match-icon"></i>
                <div className="dot-container">
                  <div className="moving-dot dot-1"></div>
                  <div className="moving-dot dot-2"></div>
                  <div className="moving-dot dot-3"></div>
                  <div className="moving-dot dot-4"></div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isLoading && qualifiedPairs.length > 0 && (
          <div className="w-full mt-8">
            <h2 className="mb-4 text-xl font-semibold">Match Results</h2>
            <MatchDisplay pairs={qualifiedPairs} />
          </div>
        )}
      </main>

      <style jsx>{`
        .donor-search-animation {
          position: relative;
          width: 200px;
          height: 240px;
          background: linear-gradient(135deg, #f0f4ff 0%, #e4e9f7 100%);
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }

        .magnifying-glass {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100px;
          height: 100px;
          border: 8px solid rgba(79, 70, 229, 0.3);
          border-radius: 50%;
          box-shadow: 0 0 20px rgba(79, 70, 229, 0.1);
        }

        .magnifying-glass::after {
          content: "";
          position: absolute;
          width: 8px;
          height: 40px;
          background: rgba(79, 70, 229, 0.3);
          bottom: -35px;
          right: -10px;
          transform: rotate(-45deg);
          border-radius: 4px;
        }

        .donor-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 48px;
          color: #ff0000;
          animation: pulseDonor 2s ease-in-out infinite;
        }

        @keyframes pulseDonor {
          0%,
          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0.8;
          }
          50% {
            transform: translate(-50%, -50%) scale(1.2);
            opacity: 1;
          }
        }

        .recipient-icon,
        .match-icon {
          position: absolute;
          font-size: 36px;
          color: #4f46e5;
          opacity: 0.7;
          animation: pulse 2s ease-in-out infinite;
          z-index: 2;
        }

        .recipient-icon {
          left: 20px;
          bottom: 20px;
        }

        .match-icon {
          right: 20px;
          bottom: 20px;
        }

        @keyframes pulse {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.7;
          }
          50% {
            transform: scale(1.1);
            opacity: 1;
          }
        }

        .dot-container {
          position: absolute;
          bottom: 35px;
          left: 60px;
          right: 60px;
          height: 10px;
        }

        .moving-dot {
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #4f46e5;
          border-radius: 50%;
          opacity: 0;
        }

        .dot-1 {
          animation: moveRightToLeft 3s infinite 0s, fadeInOut 3s infinite 0s;
        }
        .dot-2 {
          animation: moveRightToLeft 3s infinite 0.5s,
            fadeInOut 3s infinite 0.5s;
        }
        .dot-3 {
          animation: moveLeftToRight 3s infinite 1s, fadeInOut 3s infinite 1s;
        }
        .dot-4 {
          animation: moveLeftToRight 3s infinite 1.5s,
            fadeInOut 3s infinite 1.5s;
        }

        @keyframes moveRightToLeft {
          0% {
            left: 100%;
          }
          100% {
            left: 0%;
          }
        }

        @keyframes moveLeftToRight {
          0% {
            left: 0%;
          }
          100% {
            left: 100%;
          }
        }

        @keyframes fadeInOut {
          0%,
          100% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default CrossMatchDonorsListChild;
