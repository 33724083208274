import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import DonorNameComponent from "../DonorNameComponent";
import {
  postiveNegativeOptions,
  urineOptionsPerDay,
  HbsAgStatusOptions,
} from "../../../components/Data/dropdownoptions";

import GeneralProfile from "./GeneralProfile";
import RenalProfile from "./RenalProfile";
import LiverProfile from "./LiverProfile";
import HLAProfile from "./HLAProfile";
import Compatibility from "./Compatibility";
import MedicalHistory from "./MedicalHistory";

const UpdateModal = ({ clinicalRecord, currentRecord }) => {
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [activeTab, setActiveTab] = useState("profile_general");
  const [isEditable, setIsEditable] = useState(false);

  const [formData, setFormData] = useState({
    ...clinicalRecord,
    aptt_patient: clinicalRecord?.APTT_patient,
    hbsag_patient: clinicalRecord?.HbsAg_patient,
    // Blood Pressure
    blood_pressure_systolic: clinicalRecord?.blood_pressure?.systolic,
    blood_pressure_diastolic: clinicalRecord?.blood_pressure?.diastolic,
    // CBC
    cbc_wbc: clinicalRecord?.cbc?.wbc,
    cbc_platelets: clinicalRecord?.cbc?.platelets,
    cbc_esr: clinicalRecord?.esr,
    cbc_hemoglobin: clinicalRecord?.cbc?.hemoglobin,
    // Renal Profile
    renal_profile_egfr: clinicalRecord?.renal_profile?.eGFR,
    renal_profile_creatinine: clinicalRecord?.renal_profile?.creatinine,
    renal_profile_albumin: clinicalRecord?.renal_profile?.albumin,
    renal_profile_calcium: clinicalRecord?.renal_profile?.calcium,
    renal_profile_carbon_dioxide: clinicalRecord?.renal_profile?.carbon_dioxide,
    renal_profile_glucose: clinicalRecord?.renal_profile?.glucose,
    renal_profile_potassium: clinicalRecord?.renal_profile?.potassium,
    renal_profile_sodium: clinicalRecord?.renal_profile?.sodium,
    renal_profile_phosphorus: clinicalRecord?.renal_profile?.phosphorus,
    renal_profile_bun: clinicalRecord?.renal_profile?.BUN,
    renal_profile_hba1c: clinicalRecord?.renal_profile?.HbA1c,
    renal_profile_residual_renal_function:
      clinicalRecord?.renal_profile?.residual_renal_function,
    renal_profile_urine_output_per_day:
      clinicalRecord?.renal_profile?.urine_output_per_day,
    // Urine Analysis
    urine_analysis_ph: clinicalRecord?.urine_analysis?.ph,
    urine_analysis_protein: clinicalRecord?.urine_analysis?.protein,
    //HLA Typing
    hlatyping_a_allele_1: clinicalRecord?.hlaTyping?.HLA_A_Allele_1,
    hlatyping_a_allele_2: clinicalRecord?.hlaTyping?.HLA_A_Allele_2,
    hlatyping_b_allele_1: clinicalRecord?.hlaTyping?.HLA_B_Allele_1,
    hlatyping_b_allele_2: clinicalRecord?.hlaTyping?.HLA_B_Allele_2,
    hlatyping_c_allele_1: clinicalRecord?.hlaTyping?.HLA_C_Allele_1,
    hlatyping_c_allele_2: clinicalRecord?.hlaTyping?.HLA_C_Allele_2,
    hlatyping_drb1_allele_1: clinicalRecord?.hlaTyping?.HLA_DRB1_Allele_1,
    hlatyping_drb1_allele_2: clinicalRecord?.hlaTyping?.HLA_DRB1_Allele_2,
    hlatyping_dqb1_allele_1: clinicalRecord?.hlaTyping?.HLA_DQB1_Allele_1,
    hlatyping_dqb1_allele_2: clinicalRecord?.hlaTyping?.HLA_DQB1_Allele_2,
    hlatyping_dpb1_allele_1: clinicalRecord?.hlaTyping?.HLA_DPB1_Allele_1,
    hlatyping_dpb1_allele_2: clinicalRecord?.hlaTyping?.HLA_DPB1_Allele_2,
    //HLA Antibody profile
    hlaAntibodyProfile_antigen: clinicalRecord?.hlaAntibodyProfile?.antigen,
    hlaAntibodyProfile_mfi: clinicalRecord?.hlaAntibodyProfile?.MFI,
    hlaAntibodyProfile_specificity:
      clinicalRecord?.hlaAntibodyProfile?.specificity,
    hlaAntibodyProfile_antigen_1: clinicalRecord?.hlaAntibodyProfile?.antigen_1,
    hlaAntibodyProfile_mfi_1: clinicalRecord?.hlaAntibodyProfile?.MFI_1,
    hlaAntibodyProfile_specificity_1:
      clinicalRecord?.hlaAntibodyProfile?.specificity_1,
    //organSize
    organSize_donorOrganSize: clinicalRecord?.organSize?.donorOrganSize,
    organSize_sizeCompatibility: clinicalRecord?.organSize?.sizeCompatibility,
    //preExistingConditions
    preExistingConditions_condition:
      clinicalRecord?.preExistingConditions?.condition,
    preExistingConditions_status: clinicalRecord?.preExistingConditions?.status,
    preExistingConditions_notes: clinicalRecord?.preExistingConditions?.notes,
    //crossmatchResults
    //virtualCrossmatch
    virtualCrossmatch_method:
      clinicalRecord?.crossmatchResults?.virtualCrossmatch?.method, //String
    virtualCrossmatch_result:
      clinicalRecord?.crossmatchResults?.virtualCrossmatch?.result, //String
    virtualCrossmatch_interpretation:
      clinicalRecord?.crossmatchResults?.virtualCrossmatch?.interpretation, //String
    virtualCrossmatch_dateOfTest:
      clinicalRecord?.crossmatchResults?.virtualCrossmatch?.dateOfTest, //Date
    virtualCrossmatch_performedBy:
      clinicalRecord?.crossmatchResults?.virtualCrossmatch?.performedBy, //String
    virtualCrossmatch_notes:
      clinicalRecord?.crossmatchResults?.virtualCrossmatch?.notes, //String
    //physicalCrossmatch
    physicalCrossmatch_method:
      clinicalRecord?.crossmatchResults?.physicalCrossmatch?.method, //String
    physicalCrossmatch_result:
      clinicalRecord?.crossmatchResults?.physicalCrossmatch?.result, //String
    physicalCrossmatch_interpretation:
      clinicalRecord?.crossmatchResults?.physicalCrossmatch?.interpretation, //String
    physicalCrossmatch_dateOfTest:
      clinicalRecord?.crossmatchResults?.physicalCrossmatch?.dateOfTest, //Date
    physicalCrossmatch_performedBy:
      clinicalRecord?.crossmatchResults?.physicalCrossmatch?.performedBy, //String
    physicalCrossmatch_notes:
      clinicalRecord?.crossmatchResults?.physicalCrossmatch?.notes, //String
    //serumSample
    serumSample_sampleId: clinicalRecord?.serumSample?.sampleId, //String
    serumSample_collectionDate: clinicalRecord?.serumSample?.collectionDate, //Date
    serumSample_condition: clinicalRecord?.serumSample?.condition, //String
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const tabs = [
    { id: "profile_general", label: "General Profile" },
    { id: "profile_medhistory", label: "Medical History" },
    { id: "profile_renal", label: "Renal Profile" },
    { id: "profile_liver", label: "Liver Profile" },
    { id: "profile_hla", label: "HLA Profile" },
    { id: "profile_compatibility", label: "Compatibility" },
    // Add more tabs here as needed
  ];

  const TabButton = ({ id, label }) => (
    <button
      className={`px-3 py-2 text-sm font-medium rounded-t-lg transition duration-300 ${
        activeTab === id
          ? "bg-orange-500 text-white"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
      onClick={() => setActiveTab(id)}
    >
      {label}
    </button>
  );

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      date_of_entry: dateString,
    });
  };

  const handleSubmit = () => {
    // e.preventDefault();
    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/clinical/update/${clinicalRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Clinical Record Updated Successfully.");
        setIsEditable(false);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "UpdateModal", "/clinical/update");
      });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <div className="relative p-6 bg-white rounded-lg shadow-md">
      <DonorNameComponent currentRecord={currentRecord} />
      <div className="w-full p-3 text-end top-4">
        {!isEditable && (
          <Tooltip title="Enable edit">
            <i
              className="mdi mdi-square-edit-outline"
              style={{ fontSize: "30px", color: "grey", cursor: "pointer" }}
              onClick={() => setIsEditable(!isEditable)}
            ></i>
          </Tooltip>
        )}
      </div>
      {isEditable && (
        <div className="w-full mb-3 text-end">
          <button
            onClick={() => setIsEditable(false)}
            className="px-4 py-2 text-white transition duration-300 bg-purple-500 rounded-md hover:bg-purple-600"
          >
            Cancel
          </button>
          &nbsp;
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Update Clinical Record
          </button>
        </div>
      )}
      <div className="mb-4">
        {/* Desktop tabs */}
        <div className="hidden mb-4 overflow-x-auto md:block">
          <div className="flex flex-wrap gap-2">
            {tabs.map((tab) => (
              <TabButton key={tab.id} id={tab.id} label={tab.label} />
            ))}
          </div>
        </div>

        {/* Mobile dropdown */}
        <div className="md:hidden">
          <Menu as="div" className="relative w-full">
            <Menu.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-md hover:bg-orange-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {tabs.find((tab) => tab.id === activeTab)?.label || "Select Tab"}
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-white"
                aria-hidden="true"
              />
            </Menu.Button>
            <Menu.Items className="absolute left-0 z-50 w-full mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {tabs.map((tab) => (
                  <Menu.Item key={tab.id}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-orange-500 text-white" : "text-gray-900"
                        } block w-full px-4 py-2 text-left text-sm`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>

      {activeTab === "profile_general" && (
        <GeneralProfile
          handleDateChange={handleDateChange}
          formData={formData}
          handleChange={handleChange}
          isEditable={isEditable}
        />
      )}

      {activeTab === "profile_renal" && (
        <RenalProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          urineOptionsPerDay={urineOptionsPerDay}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          isEditable={isEditable}
        />
      )}

      {activeTab === "profile_liver" && (
        <LiverProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          HbsAgStatusOptions={HbsAgStatusOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          isEditable={isEditable}
        />
      )}

      {activeTab === "profile_hla" && (
        <HLAProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          isEditable={isEditable}
        />
      )}
      {activeTab === "profile_compatibility" && (
        <Compatibility
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          isEditable={isEditable}
          setFormData={setFormData}
        />
      )}
      {activeTab === "profile_medhistory" && (
        <MedicalHistory
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          HbsAgStatusOptions={HbsAgStatusOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
          isEditable={isEditable}
        />
      )}
    </div>
  );
};

export default UpdateModal;
