import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";

const ManageRecipientClinicalPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRecord = location.state;
  const [data, setData] = useState(null);

  const getClinicalDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/recipient/${currentRecord?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data; // Access consultants array from the response data
        console.log(oldData);
        setData(oldData); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getClinicalDataFromServer();
  }, []);

  return (
    <MainTemplate>
      <CardBackground>
        <ManageRecipientClinical
          currentRecord={currentRecord}
          data={data}
          getClinicalDataFromServer={getClinicalDataFromServer}
        />
      </CardBackground>
    </MainTemplate>
  );
};

export default ManageRecipientClinicalPage;

const ManageRecipientClinical = ({
  currentRecord,
  data,
  getClinicalDataFromServer,
}) => {
  let navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="mr-7 text-end">
        <button
          className="p-2 px-3 text-white rounded bg-gradient-to-r from-gray-500 to-gray-500 hover:from-gray-600 hover:to-gray-600"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        &nbsp;
        <button
          className="p-2 px-3 text-white rounded bg-gradient-to-r from-sky-600 to-sky-600 hover:from-sky-800 hover:to-sky-800"
          onClick={() => navigate("/donors", { state: currentRecord })}
        >
          Add / Update Donor
        </button>
      </div>
      {data ? (
        <UpdateModal clinicalRecord={data} currentRecord={currentRecord} />
      ) : (
        <AddModalComponent
          currentRecord={currentRecord}
          getClinicalDataFromServer={getClinicalDataFromServer}
        />
      )}
    </div>
  );
};

const AddModalComponent = ({ currentRecord, getClinicalDataFromServer }) => {
  return (
    <AddModal
      currentRecord={currentRecord}
      setShowAddModal={() => {}}
      setShowClinicalModal={() => {}}
      getClinicalDataFromServer={getClinicalDataFromServer}
    />
  );
};
