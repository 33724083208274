import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";

const ManageRecipientClinical = ({
  setShowClinicalModal,
  getDataFromServer,
  currentRecord,
}) => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(true);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [data, setData] = useState(null);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const getClinicalDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/recipient/${currentRecord?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data; // Access consultants array from the response data
        console.log(oldData);
        setData(oldData); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getClinicalDataFromServer();
  }, []);

  return (
    <div>
      {data ? (
        <UpdateModal clinicalRecord={data} currentRecord={currentRecord} />
      ) : (
        <AddModalComponent
          currentRecord={currentRecord}
          setShowAddModal={setShowAddModal}
          showAddModal={showAddModal}
          setShowClinicalModal={setShowClinicalModal}
        />
      )}
    </div>
  );
};

const AddModalComponent = ({
  currentRecord,
  setShowAddModal,
  showAddModal,
  setShowClinicalModal,
}) => {
  if (showAddModal) {
    return (
      <AddModal
        currentRecord={currentRecord}
        setShowAddModal={setShowAddModal}
        setShowClinicalModal={setShowClinicalModal}
      />
    );
  } else {
    return "";
  }
};

export default ManageRecipientClinical;
