import React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

const MatchCardComponent = ({
  title,
  person,
  bgColor,
  textColor,
  code,
  consultant,
}) => {
  return (
    <div
      // className={`${bgColor} rounded-lg p-4 shadow-md transition-transform hover:scale-105 w-full md:w-5/12`}
      className={`${bgColor} rounded-lg p-4 shadow-md  w-full md:w-5/12`}
    >
      <h3 className={`mb-3 text-lg font-semibold ${textColor}`}>{title}</h3>
      <div className="space-y-1">
        <InfoItem label="Name" value={getFullName(person)} />
        <InfoItem label="Code" value={code} />
        <InfoItem label="Blood Group" value={getBloodGroup(person)} />
        <InfoItem label="Gender" value={getGender(person)} />
        <InfoItem label="Consultant" value={consultant} />
      </div>
    </div>
  );
};

export const ArrowIndicator = () => (
  <div className="flex items-center justify-center py-2 md:py-0">
    <ArrowLeft className="mr-1 text-indigo-500" size={20} />
    <ArrowRight className="ml-1 text-indigo-500" size={20} />
  </div>
);

const InfoItem = ({ label, value }) => (
  <p className="flex justify-between text-sm">
    <span className="font-medium">{label}:</span>
    <span className="text-gray-700">{value}</span>
  </p>
);

const getFullName = (person) => {
  if (person.name) return person.name;
  return `${person.member_title || ""} ${
    person.donor_name || person.recipient_name || ""
  } ${person.last_name || ""}`.trim();
};

const getBloodGroup = (person) => {
  return person.bloodgroup || person.blood_group?.label || "N/A";
};

const getGender = (person) => {
  return person.gender_label || person.gender?.label || "N/A";
};

export default MatchCardComponent;
