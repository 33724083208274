import React from "react";

export const MaleIcon = ({ className = "w-6 h-6" }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="7" r="5" stroke="currentColor" strokeWidth="2" />
    <path
      d="M12 12V22M8 18H16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const FemaleIcon = ({ className = "w-6 h-6" }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="9" r="5" stroke="currentColor" strokeWidth="2" />
    <path
      d="M12 14V22"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8 18H16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const GenderIcon = ({ gender, className }) => {
  const normalizedGender = gender?.toLowerCase();

  if (normalizedGender === "male") {
    return <MaleIcon className={className} />;
  } else if (normalizedGender === "female") {
    return <FemaleIcon className={className} />;
  } else {
    // Default or unknown gender
    return null; // Or a default icon
  }
};

export default GenderIcon;
