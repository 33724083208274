import { faker } from "@faker-js/faker";

export const generateRandomFormDatav1 = () => {
  const HLA_A = ["A*01:01", "A*02:01", "A*24:02", "A*03:01", "A*11:01"];
  const HLA_B = ["B*07:02", "B*08:01", "B*44:02", "B*27:05", "B*15:01"];
  const HLA_C = ["C*07:01", "C*07:02", "C*04:01", "C*06:02", "C*03:04"];
  const HLA_DRB1 = [
    "DRB1*15:01",
    "DRB1*03:01",
    "DRB1*07:01",
    "DRB1*04:01",
    "DRB1*11:01",
  ];
  const HLA_DQB1 = [
    "DQB1*06:02",
    "DQB1*06:01",
    "DQB1*05:01",
    "DQB1*03:01",
    "DQB1*02:01",
  ];
  const HLA_DPB1 = [
    "DPB1*04:01",
    "DPB1*04:02",
    "DPB1*05:01",
    "DPB1*03:01",
    "DPB1*02:01",
  ];

  // Preformed meaningful data for certain fields
  const recentInfections = [
    "No recent infections",
    "Upper respiratory tract infection",
    "Urinary tract infection",
    "Skin infection: cellulitis",
    "Gastrointestinal infection: gastroenteritis",
    "Respiratory syncytial virus (RSV) infection",
    "Influenza A infection",
    "Streptococcal pharyngitis",
    "Otitis media",
    "Sinusitis",
    "Bronchitis",
    "Pneumonia",
    "Herpes zoster",
    "Human papillomavirus (HPV) infection",
    "Hepatitis B",
    "Hepatitis C",
    "HIV/AIDS",
    "Tuberculosis",
    "Malaria",
    "Dengue fever",
    "Lyme disease",
    "Candidiasis",
    "Athlete's foot",
    "Impetigo",
    "Scabies",
    "Chickenpox",
    "Measles",
    "Mumps",
    "Rubella",
    "Pertussis",
    "Salmonella food poisoning",
    "Escherichia coli infection",
    "Cryptosporidiosis",
    "Giardiasis",
    "Helicobacter pylori infection",
    "Staphylococcal food poisoning",
    "Clostridium difficile colitis",
  ];

  const kidneyFindings = [
    "Normal renal function",
    "Reduced renal perfusion",
    "Renal scarring observed",
    "Evidence of unilateral renal disease",
    "Signs of renal artery stenosis",
    "Mild hydronephrosis",
    "Moderate to severe hydronephrosis",
    "Acute kidney injury detected",
    "Chronic kidney disease stage 1",
    "Chronic kidney disease stage 2",
    "Chronic kidney disease stage 3",
    "Chronic kidney disease stage 4",
    "Chronic kidney disease stage 5",
    "Polycystic kidney disease",
    "Glomerulonephritis noted",
    "Interstitial nephritis",
    "Nephrolithiasis (kidney stones)",
    "Renal cysts detected",
    "Proteinuria present",
    "Hematuria observed",
    "Nephrotic syndrome",
    "Signs of lupus nephritis",
    "Diabetic nephropathy",
    "Hypertensive nephrosclerosis",
    "Pyelonephritis",
    "Renal papillary necrosis",
    "Renal vein thrombosis",
    "Renal tubular acidosis",
    "Fanconi syndrome",
    "Renal amyloidosis",
    "Signs of renal failure",
  ];

  return {
    date_of_entry: faker.date.recent().toISOString().split("T")[0], // Gets recent date in YYYY-MM-DD format
    weight: faker.datatype.number({ min: 20, max: 90 }),
    blood_pressure_systolic: faker.datatype.number({ min: 90, max: 160 }),
    blood_pressure_diastolic: faker.datatype.number({ min: 60, max: 100 }),
    cbc_wbc: faker.datatype.number({ min: 3000, max: 10000 }),
    cbc_platelets: faker.datatype.number({ min: 150000, max: 450000 }),
    cbc_hemoglobin: faker.datatype.number({ min: 12, max: 18 }),
    cbc_esr: faker.datatype.number({ min: 1, max: 40 }),
    renal_profile_eGFR: faker.datatype.number({ min: 30, max: 120 }),
    renal_profile_creatinine: faker.datatype.number({ min: 0.5, max: 1.5 }),
    renal_profile_albumin: faker.datatype.number({ min: 3.5, max: 5.5 }),
    renal_profile_calcium: faker.datatype.number({ min: 8.6, max: 10.3 }),
    renal_profile_carbon_dioxide: faker.datatype.number({ min: 23, max: 29 }),
    renal_profile_glucose: faker.datatype.number({ min: 70, max: 140 }),
    renal_profile_potassium: faker.datatype.number({ min: 3.5, max: 5.2 }),
    renal_profile_sodium: faker.datatype.number({ min: 135, max: 145 }),
    renal_profile_phosphorus: faker.datatype.number({ min: 2.5, max: 4.5 }),
    renal_profile_BUN: faker.datatype.number({ min: 6, max: 20 }),
    renal_profile_hba1c: faker.datatype.number({ min: 4, max: 6 }),
    sgot_patient: faker.datatype.number({ min: 0, max: 40 }),
    sgpt_patient: faker.datatype.number({ min: 0, max: 56 }),
    tsh_patient: faker.datatype.number({ min: 0.5, max: 5 }),
    aptt_patient: faker.datatype.number({ min: 25, max: 39 }),
    pt_patient: faker.datatype.number({ min: 11, max: 13.5 }),
    hbsag_patient: faker.datatype.number({ min: 0, max: 2 }), // Assuming binary (negative/positive)
    cmv_igg_patient: faker.helpers.arrayElement(["positive", "negative"]),
    cmv_igm_patient: faker.helpers.arrayElement(["positive", "negative"]),
    ebv_igg_patient: faker.helpers.arrayElement(["positive", "negative"]),
    ebv_igm_patient: faker.helpers.arrayElement(["positive", "negative"]),
    urine_cs: faker.helpers.arrayElement(["negative", "positive", "trace"]),
    urine_analysis_ph: faker.datatype.number({ min: 4.5, max: 8 }),
    urine_analysis_protein: faker.helpers.arrayElement([
      "positive",
      "negative",
    ]),
    recent_infections: faker.helpers.arrayElement(recentInfections),
    hlatyping_a_allele_1: faker.helpers.arrayElement(HLA_A),
    hlatyping_a_allele_2: faker.helpers.arrayElement(HLA_A),
    hlatyping_b_allele_1: faker.helpers.arrayElement(HLA_B),
    hlatyping_b_allele_2: faker.helpers.arrayElement(HLA_B),
    hlatyping_c_allele_1: faker.helpers.arrayElement(HLA_C),
    hlatyping_c_allele_2: faker.helpers.arrayElement(HLA_C),
    hlatyping_drb1_allele_1: faker.helpers.arrayElement(HLA_DRB1),
    hlatyping_drb1_allele_2: faker.helpers.arrayElement(HLA_DRB1),
    hlatyping_dqb1_allele_1: faker.helpers.arrayElement(HLA_DQB1),
    hlatyping_dqb1_allele_2: faker.helpers.arrayElement(HLA_DQB1),
    hlatyping_dpb1_allele_1: faker.helpers.arrayElement(HLA_DPB1),
    hlatyping_dpb1_allele_2: faker.helpers.arrayElement(HLA_DPB1),
    dtpa_gfr_left_kidney: faker.datatype
      .number({ min: 30, max: 70 })
      .toString(),
    dtpa_gfr_left_kidney_findings: faker.helpers.arrayElement(kidneyFindings),
    dtpa_gfr_right_kidney: faker.datatype
      .number({ min: 30, max: 70 })
      .toString(),
    dtpa_gfr_right_kidney_findings: faker.helpers.arrayElement(kidneyFindings),
    hlaAntibodyProfile_antigen: faker.helpers.arrayElement(
      HLA_A.concat(HLA_B, HLA_C)
    ),
    hlaAntibodyProfile_mfi: faker.datatype.number({ min: 1000, max: 20000 }),
    hlaAntibodyProfile_specificity: faker.helpers.arrayElement([
      "positive",
      "negative",
    ]),
    hlaAntibodyProfile_antigen_1: faker.helpers.arrayElement(
      HLA_DRB1.concat(HLA_DQB1, HLA_DPB1)
    ),
    hlaAntibodyProfile_mfi_1: faker.datatype.number({
      min: 1000,
      max: 20000,
    }),
    hlaAntibodyProfile_specificity_1: faker.helpers.arrayElement([
      "positive",
      "negative",
    ]),
  };
};

export const generateRandomFormData = () => {
  const HLA_A = ["A*01:01", "A*02:01", "A*24:02", "A*03:01", "A*11:01"];
  const HLA_B = ["B*07:02", "B*08:01", "B*44:02", "B*27:05", "B*15:01"];
  const HLA_C = ["C*07:01", "C*07:02", "C*04:01", "C*06:02", "C*03:04"];
  const HLA_DRB1 = [
    "DRB1*15:01",
    "DRB1*03:01",
    "DRB1*07:01",
    "DRB1*04:01",
    "DRB1*11:01",
  ];
  const HLA_DQB1 = [
    "DQB1*06:02",
    "DQB1*06:01",
    "DQB1*05:01",
    "DQB1*03:01",
    "DQB1*02:01",
  ];
  const HLA_DPB1 = [
    "DPB1*04:01",
    "DPB1*04:02",
    "DPB1*05:01",
    "DPB1*03:01",
    "DPB1*02:01",
  ];

  // Preformed meaningful data for certain fields
  const recentInfections = [
    "No recent infections",
    "Upper respiratory tract infection",
    "Urinary tract infection",
    "Skin infection: cellulitis",
    "Gastrointestinal infection: gastroenteritis",
    "Respiratory syncytial virus (RSV) infection",
    "Influenza A infection",
    "Streptococcal pharyngitis",
    "Otitis media",
    "Sinusitis",
    "Bronchitis",
    "Pneumonia",
    "Herpes zoster",
    "Human papillomavirus (HPV) infection",
    "Hepatitis B",
    "Hepatitis C",
    "HIV/AIDS",
    "Tuberculosis",
    "Malaria",
    "Dengue fever",
    "Lyme disease",
    "Candidiasis",
    "Athlete's foot",
    "Impetigo",
    "Scabies",
    "Chickenpox",
    "Measles",
    "Mumps",
    "Rubella",
    "Pertussis",
    "Salmonella food poisoning",
    "Escherichia coli infection",
    "Cryptosporidiosis",
    "Giardiasis",
    "Helicobacter pylori infection",
    "Staphylococcal food poisoning",
    "Clostridium difficile colitis",
  ];

  const kidneyFindings = [
    "Normal renal function",
    "Reduced renal perfusion",
    "Renal scarring observed",
    "Evidence of unilateral renal disease",
    "Signs of renal artery stenosis",
    "Mild hydronephrosis",
    "Moderate to severe hydronephrosis",
    "Acute kidney injury detected",
    "Chronic kidney disease stage 1",
    "Chronic kidney disease stage 2",
    "Chronic kidney disease stage 3",
    "Chronic kidney disease stage 4",
    "Chronic kidney disease stage 5",
    "Polycystic kidney disease",
    "Glomerulonephritis noted",
    "Interstitial nephritis",
    "Nephrolithiasis (kidney stones)",
    "Renal cysts detected",
    "Proteinuria present",
    "Hematuria observed",
    "Nephrotic syndrome",
    "Signs of lupus nephritis",
    "Diabetic nephropathy",
    "Hypertensive nephrosclerosis",
    "Pyelonephritis",
    "Renal papillary necrosis",
    "Renal vein thrombosis",
    "Renal tubular acidosis",
    "Fanconi syndrome",
    "Renal amyloidosis",
    "Signs of renal failure",
  ];

  const organSizes = ["Small", "Medium", "Large", "Very Large"];
  const sizeCompatibilities = [
    "Compatible",
    "Incompatible",
    "Marginally Compatible",
    "Highly Compatible",
  ];

  const preExistingConditions = [
    "Diabetes",
    "Hypertension",
    "Chronic Kidney Disease",
    "Cardiovascular Disease",
    "Hepatitis C",
    "HIV/AIDS",
    "Autoimmune Disorder",
    "Cancer",
  ];

  const conditionStatus = ["Stable", "Progressive", "Resolved", "Critical"];
  const notes = [
    "Condition under control with medication.",
    "Requires further monitoring.",
    "Recently diagnosed, treatment initiated.",
    "In remission, no active symptoms.",
  ];

  const crossmatchMethods = ["Flow Cytometry", "CDC", "ELISA", "Luminex"];
  const crossmatchResults = ["Positive", "Negative", "Equivocal"];
  const interpretations = [
    "High risk for rejection",
    "Low risk for rejection",
    "Borderline risk, monitor closely",
  ];

  const crossmatchNotes = [
    "No significant antibodies detected.",
    "Low-level donor-specific antibodies identified.",
    "Multiple antibodies identified, high risk for rejection.",
    "No reaction observed in the test.",
    "Weak positive reaction, clinical significance unclear.",
    "Crossmatch negative, highly favorable for transplant.",
    "Strong positive reaction, proceed with caution.",
    "Results suggest low immunological risk.",
    "No contraindications for transplantation.",
    "Antibody levels within acceptable range.",
  ];

  const performedBy = ["Dr. Smith", "Dr. Johnson", "Dr. Lee", "Dr. Patel"];

  const sampleConditions = [
    "Good",
    "Degraded",
    "Contaminated",
    "Insufficient Volume",
  ];

  return {
    date_of_entry: faker.date.recent().toISOString().split("T")[0], // Gets recent date in YYYY-MM-DD format
    weight: faker.datatype.number({ min: 20, max: 90 }),
    blood_pressure_systolic: faker.datatype.number({ min: 90, max: 160 }),
    blood_pressure_diastolic: faker.datatype.number({ min: 60, max: 100 }),
    cbc_wbc: faker.datatype.number({ min: 3000, max: 10000 }),
    cbc_platelets: faker.datatype.number({ min: 150000, max: 450000 }),
    cbc_hemoglobin: faker.datatype.number({ min: 12, max: 18 }),
    cbc_esr: faker.datatype.number({ min: 1, max: 40 }),
    renal_profile_eGFR: faker.datatype.number({ min: 30, max: 120 }),
    renal_profile_creatinine: faker.datatype.number({ min: 0.5, max: 1.5 }),
    renal_profile_albumin: faker.datatype.number({ min: 3.5, max: 5.5 }),
    renal_profile_calcium: faker.datatype.number({ min: 8.6, max: 10.3 }),
    renal_profile_carbon_dioxide: faker.datatype.number({ min: 23, max: 29 }),
    renal_profile_glucose: faker.datatype.number({ min: 70, max: 140 }),
    renal_profile_potassium: faker.datatype.number({ min: 3.5, max: 5.2 }),
    renal_profile_sodium: faker.datatype.number({ min: 135, max: 145 }),
    renal_profile_phosphorus: faker.datatype.number({ min: 2.5, max: 4.5 }),
    renal_profile_BUN: faker.datatype.number({ min: 6, max: 20 }),
    renal_profile_hba1c: faker.datatype.number({ min: 4, max: 6 }),
    sgot_patient: faker.datatype.number({ min: 0, max: 40 }),
    sgpt_patient: faker.datatype.number({ min: 0, max: 56 }),
    tsh_patient: faker.datatype.number({ min: 0.5, max: 5 }),
    aptt_patient: faker.datatype.number({ min: 25, max: 39 }),
    pt_patient: faker.datatype.number({ min: 11, max: 13.5 }),
    hbsag_patient: faker.datatype.number({ min: 0, max: 2 }), // Assuming binary (negative/positive)
    cmv_igg_patient: faker.helpers.arrayElement(["positive", "negative"]),
    cmv_igm_patient: faker.helpers.arrayElement(["positive", "negative"]),
    ebv_igg_patient: faker.helpers.arrayElement(["positive", "negative"]),
    ebv_igm_patient: faker.helpers.arrayElement(["positive", "negative"]),
    urine_cs: faker.helpers.arrayElement(["negative", "positive", "trace"]),
    urine_analysis_ph: faker.datatype.number({ min: 4.5, max: 8 }),
    urine_analysis_protein: faker.helpers.arrayElement([
      "positive",
      "negative",
    ]),
    recent_infections: faker.helpers.arrayElement(recentInfections),
    hlatyping_a_allele_1: faker.helpers.arrayElement(HLA_A),
    hlatyping_a_allele_2: faker.helpers.arrayElement(HLA_A),
    hlatyping_b_allele_1: faker.helpers.arrayElement(HLA_B),
    hlatyping_b_allele_2: faker.helpers.arrayElement(HLA_B),
    hlatyping_c_allele_1: faker.helpers.arrayElement(HLA_C),
    hlatyping_c_allele_2: faker.helpers.arrayElement(HLA_C),
    hlatyping_drb1_allele_1: faker.helpers.arrayElement(HLA_DRB1),
    hlatyping_drb1_allele_2: faker.helpers.arrayElement(HLA_DRB1),
    hlatyping_dqb1_allele_1: faker.helpers.arrayElement(HLA_DQB1),
    hlatyping_dqb1_allele_2: faker.helpers.arrayElement(HLA_DQB1),
    hlatyping_dpb1_allele_1: faker.helpers.arrayElement(HLA_DPB1),
    hlatyping_dpb1_allele_2: faker.helpers.arrayElement(HLA_DPB1),
    dtpa_gfr_left_kidney: faker.datatype
      .number({ min: 30, max: 70 })
      .toString(),
    dtpa_gfr_left_kidney_findings: faker.helpers.arrayElement(kidneyFindings),
    dtpa_gfr_right_kidney: faker.datatype
      .number({ min: 30, max: 70 })
      .toString(),
    dtpa_gfr_right_kidney_findings: faker.helpers.arrayElement(kidneyFindings),
    hlaAntibodyProfile_antigen: faker.helpers.arrayElement(
      HLA_A.concat(HLA_B, HLA_C)
    ),
    hlaAntibodyProfile_mfi: faker.datatype.number({ min: 1000, max: 20000 }),
    hlaAntibodyProfile_specificity: faker.helpers.arrayElement([
      "positive",
      "negative",
    ]),
    hlaAntibodyProfile_antigen_1: faker.helpers.arrayElement(
      HLA_DRB1.concat(HLA_DQB1, HLA_DPB1)
    ),
    hlaAntibodyProfile_mfi_1: faker.datatype.number({
      min: 1000,
      max: 20000,
    }),
    hlaAntibodyProfile_specificity_1: faker.helpers.arrayElement([
      "positive",
      "negative",
    ]),
    organSize_donorOrganSize: faker.helpers.arrayElement(organSizes),
    organSize_sizeCompatibility:
      faker.helpers.arrayElement(sizeCompatibilities),
    preExistingConditions_condition: faker.helpers.arrayElement(
      preExistingConditions
    ),
    preExistingConditions_status: faker.helpers.arrayElement(conditionStatus),
    preExistingConditions_notes: faker.helpers.arrayElement(notes),
    virtualCrossmatch_method: faker.helpers.arrayElement(crossmatchMethods),
    virtualCrossmatch_result: faker.helpers.arrayElement(crossmatchResults),
    virtualCrossmatch_interpretation:
      faker.helpers.arrayElement(interpretations),
    virtualCrossmatch_dateOfTest: faker.date
      .recent()
      .toISOString()
      .split("T")[0],
    virtualCrossmatch_performedBy: faker.helpers.arrayElement(performedBy),
    virtualCrossmatch_notes: faker.helpers.arrayElement(crossmatchNotes),
    physicalCrossmatch_method: faker.helpers.arrayElement(crossmatchMethods),
    physicalCrossmatch_result: faker.helpers.arrayElement(crossmatchResults),
    physicalCrossmatch_interpretation:
      faker.helpers.arrayElement(interpretations),
    physicalCrossmatch_dateOfTest: faker.date
      .recent()
      .toISOString()
      .split("T")[0],
    physicalCrossmatch_performedBy: faker.helpers.arrayElement(performedBy),
    physicalCrossmatch_notes: faker.helpers.arrayElement(crossmatchNotes),
    serumSample_sampleId: faker.datatype.uuid(),
    serumSample_collectionDate: faker.date.recent().toISOString().split("T")[0],
    serumSample_condition: faker.helpers.arrayElement(sampleConditions),
  };
};
