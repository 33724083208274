import React, { useState, useEffect } from "react";
import axios from "axios";

const SingleAntigenBeadTest = ({ recipient, donor }) => {
  //   const [clinicalRecords, setClinicalRecords] = useState([]);
  const [recipientHlaAntibody, setRecipientHlaAntibody] = useState([]);
  const [donorHlaTyping, setDonorHlaTyping] = useState([]);
  const [matchResults, setMatchResults] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);

  const HLAAntibodyMFI_threshold = 1000;
  const MAX_HLAAntibodyMFI_threshold = 10000;

  function transformHLAData(inputArray) {
    return inputArray.map((item) => ({
      allele: item.antigen,
      mfi: Math.round(item.MFI),
    }));
  }

  function transformHLAAlleles(inputObject) {
    const alleles = [];

    for (const [key, value] of Object.entries(inputObject)) {
      if (key.includes("Allele") && value !== "") {
        alleles.push({ allele: value });
      }
    }

    // Sort alleles alphabetically
    alleles.sort((a, b) => a.allele.localeCompare(b.allele));

    return alleles;
  }

  const getClinicalRecords = () => {
    // API call to fetch clinical records
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/clinical-pair/`;
    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { recipient_id: recipient, donor_id: donor },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        // setClinicalRecords(response.data);
        setRecipientHlaAntibody(
          transformHLAData(response.data?.recipient_clinical?.hlaAntibody)
        );
        setDonorHlaTyping(
          transformHLAAlleles(response.data?.donor_clinical?.hlaTyping)
        );
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
        // Clear previous data if there's an error
        setRecipientHlaAntibody([]);
        setDonorHlaTyping([]);
        setMatchResults([]);
      });
  };

  useEffect(() => {
    getClinicalRecords();
  }, [recipient, donor]); // Add recipient and donor to the dependency array

  useEffect(() => {
    if (recipientHlaAntibody.length > 0 && donorHlaTyping.length > 0) {
      const results = analyzeHlaMatches();
      setMatchResults(results);
    }
  }, [recipientHlaAntibody, donorHlaTyping]);

  const isMatch = (antibodyAllele, donorAllele) => {
    if (!antibodyAllele || !donorAllele) return false;
    const [antibodyLocus, antibodySpecificity] = antibodyAllele.split("*");
    const [donorLocus, donorSpecificity] = donorAllele.split("*");

    if (antibodyLocus !== donorLocus) return false;

    const antibodyParts = antibodySpecificity.split(":");
    const donorParts = donorSpecificity.split(":");

    // Check for serological level match
    if (antibodyParts[0] === donorParts[0]) return true;

    // Check for high resolution match
    if (antibodyParts.length > 1 && donorParts.length > 1) {
      return antibodyParts.every((part, index) => part === donorParts[index]);
    }

    return false;
  };

  const getMatchType = (antibodyAllele, donorAllele) => {
    if (!antibodyAllele || !donorAllele) return "No Match";

    const [, antibodySpecificity] = antibodyAllele.split("*");
    const [, donorSpecificity] = donorAllele.split("*");

    const antibodyParts = antibodySpecificity.split(":");
    const donorParts = donorSpecificity.split(":");

    if (antibodyAllele === donorAllele) return "Full Match";
    if (antibodyParts[0] === donorParts[0]) {
      return antibodyParts.length === 1 || donorParts.length === 1
        ? "Low Resolution Match"
        : "Partial Match";
    }
    return "No Match";
  };

  const analyzeHlaMatches = () => {
    const significantAntibodies = recipientHlaAntibody.filter(
      (antibody) => antibody.mfi > HLAAntibodyMFI_threshold
    );

    return significantAntibodies.map((antibody) => {
      const matchingDonorAntigens = donorHlaTyping.filter((donorAntigen) =>
        isMatch(antibody.allele, donorAntigen.allele)
      );

      const bestMatch = matchingDonorAntigens[0]?.allele;
      const matchType = getMatchType(antibody.allele, bestMatch);

      let matchScore = 0;
      let unfitReason = "";

      if (
        antibody.mfi > MAX_HLAAntibodyMFI_threshold &&
        matchType !== "No Match"
      ) {
        // Match score is 0 for high MFI matches, as they indicate an unfit donor
        matchScore = 0;
        unfitReason = "High MFI match - Unfit for transplant";
      } else {
        if (matchType === "Full Match") matchScore = 1;
        else if (matchType === "Low Resolution Match") matchScore = 0.8;
        else if (matchType === "Partial Match") matchScore = 0.5;
      }

      return {
        recipientAntibody: antibody.allele,
        mfi: antibody.mfi,
        matchingDonorAntigens: matchingDonorAntigens.map((a) => a.allele),
        isMatch: matchingDonorAntigens.length > 0,
        matchType: matchType,
        matchScore: matchScore,
        unfitReason: unfitReason,
      };
    });
  };

  const calculateMatchScore = () => {
    if (matchResults.length === 0) return null;
    const totalScore = matchResults.reduce(
      (sum, result) => sum + result.matchScore,
      0
    );
    const maxPossibleScore = matchResults.length;
    return 100 - (totalScore / maxPossibleScore) * 100;
  };

  const getBackgroundColor = (score) => {
    if (score >= 80) return { bg: "bg-green-500", text: "text-white" };
    if (score >= 60) return { bg: "bg-green-400", text: "text-white" };
    if (score >= 40) return { bg: "bg-green-300", text: "text-black" };
    if (score >= 20) return { bg: "bg-green-200", text: "text-black" };
    return { bg: "bg-green-100", text: "text-black" };
  };

  return (
    <div className="container p-4 mx-auto">
      {/* <h1 className="mb-4 text-2xl font-bold">Single Antigen Bead Test</h1> */}
      <p className="mb-2">
        <strong>Recipient:</strong> {recipient}
      </p>
      <p className="mb-4">
        <strong>Donor:</strong> {donor}
      </p>

      <div
        className={`p-4 mb-4 rounded-lg ${
          calculateMatchScore() !== null
            ? matchResults.some(
                (result) =>
                  result.isMatch && result.mfi > MAX_HLAAntibodyMFI_threshold
              )
              ? "bg-red-500"
              : "bg-green-500"
            : "bg-gray-200"
        }`}
        style={{
          backgroundColor:
            calculateMatchScore() !== null
              ? matchResults.some(
                  (result) =>
                    result.isMatch && result.mfi > MAX_HLAAntibodyMFI_threshold
                )
                ? "rgb(239, 68, 68)" // Tailwind's red-500
                : "rgb(34, 197, 94)" // Tailwind's green-500
              : "rgb(229, 231, 235)", // Tailwind's gray-200
          color: calculateMatchScore() !== null ? "white" : "rgb(55, 65, 81)", // Tailwind's gray-700
        }}
      >
        {calculateMatchScore() !== null ? (
          <>
            <p className="text-lg font-semibold text-white">
              {matchResults.some(
                (result) =>
                  result.isMatch && result.mfi > MAX_HLAAntibodyMFI_threshold
              )
                ? "Currently unfit for transplant"
                : `Match Score: ${calculateMatchScore().toFixed(2)}%`}
            </p>
            <p className="text-sm text-gray-200">
              {matchResults.some(
                (result) =>
                  result.isMatch && result.mfi > MAX_HLAAntibodyMFI_threshold
              )
                ? "One or more matching antibodies have MFI values exceeding the maximum threshold"
                : "(Higher score indicates better match. Full match: 1 point, Low Resolution match: 0.8 points, Partial match: 0.5 points)"}
            </p>
          </>
        ) : (
          <p className="text-lg font-semibold text-gray-700">
            {recipientHlaAntibody.length === 0
              ? "Antigen assay not found for recipient"
              : donorHlaTyping.length === 0
              ? "HLA typing not found for donor"
              : "Insufficient data to calculate match score"}
          </p>
        )}
      </div>

      <button
        onClick={() => setIsTableVisible(!isTableVisible)}
        className="px-4 py-2 mb-4 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        {isTableVisible ? "Hide Details" : "Show Details"}
      </button>

      {isTableVisible && (
        <table className="w-full mb-4 border border-collapse border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border border-gray-300">Recipient Antibody</th>
              <th className="p-2 border border-gray-300">MFI</th>
              <th className="p-2 border border-gray-300">
                Matching Donor Antigens
              </th>
              <th className="p-2 border border-gray-300">Match Type</th>
              <th className="p-2 border border-gray-300">Notes</th>
            </tr>
          </thead>
          <tbody>
            {matchResults.map((result, index) => (
              <tr
                key={index}
                className={`${
                  result.isMatch
                    ? "bg-red-100"
                    : index % 2 === 0
                    ? "bg-gray-50"
                    : ""
                } ${
                  result.mfi > MAX_HLAAntibodyMFI_threshold ? "font-bold" : ""
                }`}
              >
                <td className="p-2 border border-gray-300">
                  {result.recipientAntibody}
                </td>
                <td className="p-2 border border-gray-300">{result.mfi}</td>
                <td className="p-2 border border-gray-300">
                  {result.matchingDonorAntigens.join(", ") || "None"}
                </td>
                <td
                  className={`p-2 border border-gray-300 ${
                    result.matchType !== "No Match"
                      ? "font-bold text-red-600"
                      : ""
                  }`}
                >
                  {result.matchType}
                </td>
                <td className="p-2 text-red-600 border border-gray-300">
                  {result.unfitReason}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SingleAntigenBeadTest;
