import React from "react";
import Select from "react-select";

const RenalProfile = ({
  handleChange,
  formData,
  postiveNegativeOptions,
  urineOptionsPerDay,
  dropDownChange,
  placeHolderText,
  style,
}) => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Renal Profile</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {[
            "eGFR",
            "Creatinine",
            "Albumin",
            "Calcium",
            "HbA1c",
            //   "Glucose",
            "Potassium",
            "Sodium",
            "Phosphorus",
            "BUN",
            //   "Anion Gap Value",
          ].map((item) => (
            <div key={item}>
              <label
                htmlFor={`renal_profile_${item
                  .toLowerCase()
                  .replace(/ /g, "_")}`}
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                {item}
              </label>
              <input
                type="number"
                id={`renal_profile_${item.toLowerCase().replace(/ /g, "_")}`}
                name={`renal_profile_${item.toLowerCase().replace(/ /g, "_")}`}
                min="0"
                step="0.01"
                className="w-full p-2 border rounded-md"
                onChange={handleChange}
                value={
                  formData?.[
                    `renal_profile_${item.toLowerCase().replace(/ /g, "_")}`
                  ] ?? ""
                }
              />
            </div>
          ))}
        </div>
      </div>

      <div className="mb-6">
        <h2 className="mb-4 text-xl font-semibold">Urine Analysis</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-5">
          <div>
            <label
              htmlFor="urine_analysis_ph"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              pH
            </label>
            <input
              type="number"
              id="urine_analysis_ph"
              name="urine_analysis_ph"
              min="0"
              max="14"
              step="0.1"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.urine_analysis_ph ?? ""}
            />
          </div>
          <div>
            <label
              htmlFor="urine_analysis_protein"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Protein
            </label>
            <Select
              name="urine_analysis_protein"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.urine_analysis_protein &&
                postiveNegativeOptions?.find(
                  (option) => option.value === formData?.urine_analysis_protein
                )
              }
              menuPosition={"fixed"}
            />
          </div>
          <div>
            <label
              htmlFor="urine_cs"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Urine CS
            </label>
            <input
              type="text"
              id="urine_cs"
              name="urine_cs"
              className="w-full p-2 border rounded-md"
              onChange={handleChange}
              value={formData?.urine_cs ?? ""}
            />
          </div>
          <div>
            <label
              htmlFor="renal_profile_residual_renal_function"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Residual renal function
            </label>
            <Select
              name="renal_profile_residual_renal_function"
              onChange={dropDownChange}
              options={postiveNegativeOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.renal_profile_residual_renal_function &&
                postiveNegativeOptions?.find(
                  (option) =>
                    option.value ===
                    formData?.renal_profile_residual_renal_function
                )
              }
              menuPosition={"fixed"}
            />
          </div>
          <div>
            <label
              htmlFor="renal_profile_urine_output_per_day"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Urine output/day
            </label>
            <Select
              name="renal_profile_urine_output_per_day"
              onChange={dropDownChange}
              options={urineOptionsPerDay}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select")}
              styles={style}
              value={
                formData?.renal_profile_urine_output_per_day &&
                urineOptionsPerDay?.find(
                  (option) =>
                    option.value ===
                    formData?.renal_profile_urine_output_per_day
                )
              }
              isDisabled={
                formData?.renal_profile_residual_renal_function !== "positive"
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenalProfile;
