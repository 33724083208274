import React from "react";

const DonorNameComponent = ({ currentRecord }) => {
  return (
    <div className="p-6 mb-8 shadow-xl bg-gradient-to-r from-green-50 to-teal-50 rounded-xl">
      <h2 className="mb-4 text-2xl font-bold text-teal-700">
        Donor Information
      </h2>
      <div className="flex flex-col items-start justify-between space-y-4 md:flex-row md:items-center md:space-y-0">
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-500">Name</span>
          <span className="text-xl font-semibold text-gray-800">
            {currentRecord?.name || "N/A"}
          </span>
        </div>
        <div className="flex flex-col items-start md:items-end">
          <span className="text-sm font-medium text-gray-500">Donor Code</span>
          <span className="px-4 py-2 font-mono text-sm font-bold text-teal-800 bg-teal-100 rounded-full shadow-inner">
            {currentRecord?.donor_code || "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DonorNameComponent;
