import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import ManageRecipientDonorImportsChild from "./ManageRecipientDonorImportsChild";

const ManageRecipientDonorImports = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <ManageRecipientDonorImportsChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default ManageRecipientDonorImports;
