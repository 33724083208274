import React, { useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import DonorNameComponent from "../DonorNameComponent";
import {
  postiveNegativeOptions,
  urineOptionsPerDay,
  HbsAgStatusOptions,
} from "../../../components/Data/dropdownoptions";
import { generateRandomFormData } from "../../../components/Data/fakedatagenerator";

import GeneralProfile from "./GeneralProfile";
import RenalProfile from "./RenalProfile";
import LiverProfile from "./LiverProfile";
import HLAProfile from "./HLAProfile";
import Compatibility from "./Compatibility";
import MedicalHistory from "./MedicalHistory";

const AddModal = ({
  currentRecord,
  setShowAddModal,
  setShowClinicalModal,
  getClinicalDataFromServer,
}) => {
  // Error Logging Service & Sanitize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [activeTab, setActiveTab] = useState("profile_general");

  const [formData, setFormData] = useState({
    donor_id: currentRecord?._id, //Object
    date_of_entry: "", //date
    weight: "", //Number
    height: "", //Number
    // Blood Pressure
    blood_pressure_systolic: "", //Number
    blood_pressure_diastolic: "", //Number
    // CBC
    cbc_wbc: "", //Number
    cbc_platelets: "", //Number
    cbc_hemoglobin: "", //Number
    cbc_esr: "", //Number
    // Renal Profile
    renal_profile_eGFR: "", //Number
    renal_profile_creatinine: "", //Number
    renal_profile_albumin: "", //Number
    renal_profile_calcium: "", //Number
    renal_profile_carbon_dioxide: "", //Number
    renal_profile_glucose: "", //Number
    renal_profile_potassium: "", //Number
    renal_profile_sodium: "", //Number
    renal_profile_phosphorus: "", //Number
    renal_profile_BUN: "", //Number
    renal_profile_HbA1c: "", //Number
    renal_profile_anion_gap_value: "", //Number
    renal_profile_residual_renal_function: "",
    renal_profile_urine_output_per_day: "",
    //Liver function Test
    sgot_patient: "", //Number
    sgpt_patient: "", //Number
    tsh_patient: "", //Number
    aptt_patient: "", //Number
    pt_patient: "", //Number
    inr_patient: "", //Number
    hbsag_patient: "", //Number
    cmv_igg_patient: "", //Number
    cmv_igm_patient: "", //Number
    ebv_igg_patient: "", //Number
    ebv_igm_patient: "", //Number
    urine_cs: "", //String
    // Urine Analysis
    urine_analysis_ph: "", //Number
    urine_analysis_protein: "", //Number
    recent_infections: "",
    //HL Typing
    hlatyping_a_allele_1: "",
    hlatyping_a_allele_2: "",
    hlatyping_b_allele_1: "",
    hlatyping_b_allele_2: "",
    hlatyping_c_allele_1: "",
    hlatyping_c_allele_2: "",
    hlatyping_drb1_allele_1: "",
    hlatyping_drb1_allele_2: "",
    hlatyping_dqb1_allele_1: "",
    hlatyping_dqb1_allele_2: "",
    hlatyping_dpb1_allele_1: "",
    hlatyping_dpb1_allele_2: "",
    dtpa_gfr_left_kidney: "",
    dtpa_left_kidney_volume: "",
    dtpa_gfr_left_kidney_findings: "",
    dtpa_gfr_right_kidney: "",
    dtpa_right_kidney_volume: "",
    dtpa_gfr_right_kidney_findings: "",
    //HLA Antibody profile
    hlaAntibodyProfile_antigen: "",
    hlaAntibodyProfile_mfi: "",
    hlaAntibodyProfile_specificity: "",
    hlaAntibodyProfile_antigen_1: "",
    hlaAntibodyProfile_mfi_1: "",
    hlaAntibodyProfile_specificity_1: "",
    //organSize
    organSize_donorOrganSize: "",
    organSize_sizeCompatibility: "",
    //preExistingConditions
    preExistingConditions_condition: "",
    preExistingConditions_status: "",
    preExistingConditions_notes: "",
    //crossmatchResults
    //virtualCrossmatch
    virtualCrossmatch_method: "",
    virtualCrossmatch_result: "",
    virtualCrossmatch_interpretation: "",
    virtualCrossmatch_dateOfTest: "",
    virtualCrossmatch_performedBy: "",
    virtualCrossmatch_notes: "",
    //physicalCrossmatch
    physicalCrossmatch_method: "",
    physicalCrossmatch_result: "",
    physicalCrossmatch_interpretation: "",
    physicalCrossmatch_dateOfTest: "",
    physicalCrossmatch_performedBy: "",
    physicalCrossmatch_notes: "",
    //serumSample
    serumSample_sampleId: "",
    serumSample_collectionDate: "",
    serumSample_condition: "",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const tabs = [
    { id: "profile_general", label: "General Profile" },
    { id: "profile_medhistory", label: "Medical History" },
    { id: "profile_renal", label: "Renal Profile" },
    { id: "profile_liver", label: "Liver Profile" },
    { id: "profile_hla", label: "HLA Profile" },
    { id: "profile_compatibility", label: "Compatibility" },
    // Add more tabs here as needed
  ];

  const TabButton = ({ id, label }) => (
    <button
      className={`px-3 py-2 text-sm font-medium rounded-t-lg transition duration-300 ${
        activeTab === id
          ? "bg-orange-500 text-white"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
      onClick={() => setActiveTab(id)}
    >
      {label}
    </button>
  );

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const handleDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      date_of_entry: dateString,
    });
  };
  const handleAddSubmit = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    if (!formData?.weight)
      return toast.error("Kindly input the weight of the patient");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/clinical/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Clinical Record Created Successfully.");
        //getDataFromServer();
        setShowAddModal(false);
        setShowClinicalModal(false);
        getClinicalDataFromServer();
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "AddModal", "/clinical/add");
      });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleRandomClinicalData = () => {
    const clinical_random = generateRandomFormData();
    setFormData({ ...formData, ...clinical_random });
  };

  return (
    <div className="p-5 bg-white rounded-lg shadow-md">
      <DonorNameComponent currentRecord={currentRecord} />
      <div className="w-full mb-2 text-end">
        <button
          onClick={handleAddSubmit}
          className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
        >
          Add Clinical Record
        </button>
      </div>{" "}
      <br />
      <div className="mb-4">
        {/* Desktop tabs */}
        <div className="hidden mb-4 overflow-x-auto md:block">
          <div className="flex flex-wrap gap-2">
            {tabs.map((tab) => (
              <TabButton key={tab.id} id={tab.id} label={tab.label} />
            ))}
          </div>
        </div>

        {/* Mobile dropdown */}
        <div className="md:hidden">
          <Menu as="div" className="relative w-full">
            <Menu.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-md hover:bg-orange-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {tabs.find((tab) => tab.id === activeTab)?.label || "Select Tab"}
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-white"
                aria-hidden="true"
              />
            </Menu.Button>
            <Menu.Items className="absolute left-0 z-50 w-full mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {tabs.map((tab) => (
                  <Menu.Item key={tab.id}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-orange-500 text-white" : "text-gray-900"
                        } block w-full px-4 py-2 text-left text-sm`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      {activeTab === "profile_general" && (
        <GeneralProfile
          handleDateChange={handleDateChange}
          formData={formData}
          handleChange={handleChange}
        />
      )}
      {activeTab === "profile_renal" && (
        <RenalProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          urineOptionsPerDay={urineOptionsPerDay}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      {activeTab === "profile_liver" && (
        <LiverProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          HbsAgStatusOptions={HbsAgStatusOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      {activeTab === "profile_hla" && (
        <HLAProfile
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      {activeTab === "profile_compatibility" && (
        <Compatibility
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          handleDateChange={handleDateChange}
          style={style}
          setFormData={setFormData}
        />
      )}
      {activeTab === "profile_medhistory" && (
        <MedicalHistory
          handleChange={handleChange}
          formData={formData}
          postiveNegativeOptions={postiveNegativeOptions}
          dropDownChange={dropDownChange}
          placeHolderText={placeHolderText}
          style={style}
        />
      )}
      <div className="">
        <button
          onClick={handleRandomClinicalData}
          className="px-4 py-2 text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
        >
          <i className="mdi mdi-atom"></i> Generate Random
        </button>
      </div>
    </div>
  );
};

export default AddModal;
